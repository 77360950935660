/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import FriendCard from "./FriendCard";
import SubHeaderFriendPage from "./SubHeaderFriendPage";
import { TFriendState } from "../utils/types";
import { friendActions } from "../redux/actions";
import { LIST_ACTION_CHECK_UPDATE_UI } from "../utils/constant";
import { TCommonState } from "../../common/utils/type";

export default function ListFriendView({ isFriend, listSearchFriend, isShowDefaultData }) {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const { listFriend, notify, searchKey } = useSelector((state: { friend: TFriendState }) => state.friend);
  const listFriendView =
    !isShowDefaultData && searchKey?.length > 0
      ? listSearchFriend
      : isFriend
      ? listFriend?.list_friend
      : listFriend?.list_following;

  const handleGetListFriend = () => {
    const params = {
      member_hid: userInfo?.member?.pub_id
    };
    dispatch(friendActions.handleGetListFriend(params));
  };
  useEffect(() => {
    handleGetListFriend();
    // return () => {
    //   dispatch(friendActions.handleCleanListFriend());
    // };
  }, []);
  useEffect(() => {
    if (LIST_ACTION_CHECK_UPDATE_UI.includes(notify?.type)) {
      handleGetListFriend();
    }
  }, [notify?.type]);

  useEffect(() => {
    return () => {
      dispatch(friendActions.handleSetSearchKeyFriend(""));
    };
  }, []);
  return (
    <div>
      <SubHeaderFriendPage
        title={<FormattedMessage id={isFriend ? "friend.friend" : "friend.follow"} />}
        subTitle={
          <FormattedMessage
            id={isFriend ? "friend.friend.subTitle" : "friend.follow.subTitle"}
            values={{ num: listFriendView.length }}
          />
        }
      />
      {listFriendView?.length > 0 ? (
        <div className="max-h-[90vh] overflow-y-auto">
          {listFriendView?.map((item) => (
            <FriendCard
              key={item.member_hid}
              cardInfo={item}
              isListFriend
              isFollowing={!isFriend || item?.is_following}
              isFriend={isFriend || item?.is_friend}
            />
          ))}
        </div>
      ) : (
        <span className="text-friend-no-data">
          <FormattedMessage id="friend.noData" />
        </span>
      )}
    </div>
  );
}
