import Box from "@mui/material/Box";
import React, { memo } from "react";
import CountUp from "react-countup";
import { FormattedMessage } from "react-intl";

const DURATION_COUNT_UP = 3;
const INCREASE_RATE_CORRECT = 90;
const ACHIEVED_GOALS = 85;
const EXAM_STUDY_MATERIAL = 100000;
const TEACHERS_AND_STUDENTS_USE = 200000;

const Introduce = () => {
  return (
    <Box className="bgIntroduceBox p-6 rounded-2xl bg-white h-[476px]">
      <h2 className="text-green3 text-lg leading-6 text-center uppercase font-extrabold mb-6 tracking-[0.01rem]">
        <FormattedMessage id="introduce.title" />
      </h2>
      <div className="flex flex-col">
        <div className="mb-6 text-[13px] leading-[16px] text-black1">
          <span className="font-black text-transparent text-[24px] bg-clip-text bg-gradient-to-b from-green to-green2">
            <CountUp start={0} end={INCREASE_RATE_CORRECT} duration={DURATION_COUNT_UP} suffix="%" />
          </span>
          <FormattedMessage id="introduce.increaseRateCorrect" />
        </div>
        <div className="mb-6 text-[13px] leading-[16px] text-black1">
          <span className="font-black text-transparent text-[24px] bg-clip-text bg-gradient-to-b from-green to-green2">
            <CountUp start={0} end={ACHIEVED_GOALS} duration={DURATION_COUNT_UP} suffix="%" />
          </span>
          <FormattedMessage id="introduce.achievedGoals" />
        </div>
        <div className="mb-6 text-[13px] leading-[16px] text-black1">
          <span className="font-black text-transparent text-[24px] bg-clip-text bg-gradient-to-b from-green to-green2">
            <CountUp start={0} end={EXAM_STUDY_MATERIAL} duration={DURATION_COUNT_UP} separator="." suffix="+" />
          </span>
          <FormattedMessage id="introduce.examStudyMaterial" />
        </div>
        <div className="mb-6 text-[13px] leading-[16px] text-black1">
          <span className="font-black text-transparent text-[24px] bg-clip-text bg-gradient-to-b from-green to-green2">
            <CountUp start={0} end={TEACHERS_AND_STUDENTS_USE} duration={DURATION_COUNT_UP} separator="." suffix="+" />
          </span>
          <FormattedMessage id="introduce.teachersAndStudentsUse" />
        </div>
      </div>
    </Box>
  );
};

export default memo(Introduce);
