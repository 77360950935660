/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import AllInclusiveOutlinedIcon from "@mui/icons-material/AllInclusiveOutlined";
import Box from "@mui/material/Box";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import moment from "moment";
import React from "react";
import ReactAudioPlayer from "react-audio-player";
import { FormattedMessage } from "react-intl";
import ModalImage from "react-modal-image";
import IconClock from "../../../../public/assets/icons/IconClock";
import { IconDiamond10 } from "../../../../public/assets/icons/IconDiamond";
import IconQuestion from "../../../../public/assets/icons/IconQuestion";
import IconSubject from "../../../../public/assets/icons/IconSubject";
import IconUnlimited from "../../../../public/assets/icons/IconUnlimited";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { TTheme } from "../../../utils/configs/setupTheme";
import { isEmpty } from "../../../utils/helpers/helpers";

export const ColorButton = styled(Button)(() => ({
  color: "white",
  background: "linear-gradient(180deg, #FEDF3F 0%, #F7871D 100%)",
  "&:hover": {
    background: "linear-gradient(180deg, #FEDF3F 0%, #F7871D 100%)"
  },
  "&:disabled": {
    background: "#9E9E9E",
    color: "#FFFFFF"
  },
  borderRadius: 32,
  padding: "4px 8px"
}));

export const ColorButtonOutlined = styled(Button)(() => ({
  color: "linear-gradient(180deg, #FEDF3F 0%, #F7871D 100%)",
  background: "white",
  border: "1px solid #FEDF3F",
  "&:disabled": {
    background: "#9E9E9E",
    color: "#FFFFFF"
  },
  borderRadius: 32,
  padding: "4px 8px"
}));

export const ColorButtonOutlinedGrade = styled(Button)(() => ({
  color: "#212121",
  background: "white",
  "&:hover": {
    background: "white"
  },
  border: "1px solid #E5E5E5",
  "&:disabled": {
    background: "#9E9E9E",
    color: "#FFFFFF"
  },
  borderRadius: 32,
  padding: "8px 12px"
}));

// eslint-disable-next-line
export const FacebookCircularProgress = (props: JSX.IntrinsicAttributes & CircularProgressProps & any) => {
  const { small, color } = props;
  const theme: TTheme = useTheme();
  return (
    <Box sx={{ position: "relative", height: small && 36 }}>
      <CircularProgress
        color="success"
        variant="determinate"
        sx={{
          color: (t) => t.palette.grey[t.palette.mode === "light" ? 200 : 800]
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: color || theme.palette.primary.main,
          position: "absolute",
          left: 0
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
};

// eslint-disable-next-line
export const ExamsCard = ({ ele }: any) => {
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const theme: TTheme = useTheme();
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mr={matches1100 ? 1 : 0.5}
        >
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <FacebookCircularProgress
              size={32}
              variant="determinate"
              value={Math.round((ele.correct_quiz / ele.total) * 100)}
              small="true"
              style={{
                color: "linear-gradient(180deg, #38B000 0%, #006000 100%)"
              }}
            />
            <Box
              sx={{
                top: -5,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary" className="mt-[2px]">
                {`${Math.round((ele.correct_quiz / ele.total) * 100)}`}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              // backgroundColor: "#9E9E9E",
              width: 42,
              height: 16,
              // borderRadius: 8,
              fontSize: 10
            }}
            className="text-gray400"
          >
            <span className="flex justify-center items-center mt-[1px] ml-[1px]">
              <FormattedMessage id="Times" values={{ num: ele.num_of_times }} />
            </span>
          </Box>
        </Box>
        <Box style={{ lineHeight: "18px" }} className="w-full">
          <span className="text-[15px] hover:underline">{ele.title}</span>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box className="flex gap-x-2">
              <Box
                display="flex"
                alignItems="center"
                style={{
                  color: theme.palette.primary.gray400,
                  fontSize: 12,
                  marginRight: 4,
                  maxWidth: 24,
                  height: 18
                }}
              >
                <IconDiamond10 />
                &nbsp;
                {ele.price || 0}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  color: theme.palette.primary.gray400,
                  fontSize: 12,
                  marginRight: 4
                }}
              >
                <IconClock /> &nbsp;
                {ele?.time > -1 ? `${ele?.time} phút` : <IconUnlimited />}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  color: theme.palette.primary.gray400,
                  fontSize: 12,
                  marginRight: 4
                }}
              >
                <IconQuestion />
                &nbsp; {ele.total} câu
              </Box>
            </Box>
            <Box display="flex" alignItems="center" style={{ color: theme.palette.primary.gray400, fontSize: 12 }}>
              {matches1100
                ? moment.unix(ele.last_modified).format("DD/MM/YYYY")
                : moment.unix(ele.last_modified).format("DD/MM")}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// eslint-disable-next-line
export const ExercisedCard = ({ ele }: any) => {
  const theme: TTheme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      mr={-2}
      style={{
        border: `1px solid ${theme.palette.primary.gray}`,
        borderRadius: "6px",
        padding: 12
      }}
    >
      <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
        <Box sx={{ position: "relative", display: "inline-flex", marginRight: "20px" }}>
          <FacebookCircularProgress
            size={48}
            variant="determinate"
            value={Math.round((ele?.correct_quiz / ele?.total) * 100)}
          />
          <Box
            sx={{
              top: -6,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 15
            }}
          >
            {`${Math.round((ele?.correct_quiz / ele?.total) * 100)}`}
          </Box>
        </Box>
        <span className="text-[12px] text-gray1">
          <FormattedMessage id="Times" values={{ num: ele?.num_of_times }} />
        </span>
        <Box display="flex" alignItems="center" style={{ color: theme.palette.primary.gray3, fontSize: 12 }}>
          {moment.unix(ele?.last_modified).format("DD/MM/YY")}
        </Box>
      </Box>
      <Box style={{ lineHeight: "18px" }} className="w-full">
        <Typography
          className="line-clamp-2"
          variant="body1"
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            lineHeight: "25.6px"
          }}
        >
          {ele?.title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          style={{
            color: theme.palette.primary.gray3,
            fontSize: 12,
            margin: "5px 0px 8px"
          }}
        >
          <Typography
            variant="body1"
            style={{
              padding: "4px 8px",
              borderRadius: "15px",
              backgroundColor: "#AEA6F0",
              color: "white",
              fontSize: "10px",
              fontWeight: "400"
            }}
          >
            {ele?.learning_program?.title}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" className="w-full">
          <Box
            display="flex"
            alignItems="center"
            style={{ fontSize: 12, marginRight: 16 }}
            className="w-[calc(100%-160px)]"
          >
            <IconSubject />
            &nbsp; &nbsp;
            <span className="line-clamp-1">{ele?.subject_title}</span>
          </Box>
          <Box display="flex" alignItems="center" style={{ fontSize: 12, marginRight: 8 }} className="w-[90px]">
            <IconQuestion width={20} height={20} />
            &nbsp; {ele?.total} câu
          </Box>
          <Box
            display="flex"
            alignItems="center"
            style={{
              color: "black",
              fontSize: 12,
              marginRight: 8
            }}
            className="w-[90px]"
          >
            <IconClock width={20} height={20} />
            &nbsp;{" "}
            {ele?.time < 0 ? (
              <AllInclusiveOutlinedIcon
                className="svgFillAll"
                stroke="black"
                style={{ width: "20px", height: "10px" }}
              />
            ) : (
              `${ele?.time} phút`
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// eslint-disable-next-line
export const ShowBasicDataContent = ({ ele, isArena = false }: any) => {
  if (isEmpty(ele)) return null;
  switch (ele?.content_type) {
    case "image":
      return (
        <Box display="flex" justifyContent="center">
          <ModalImage
            small={ele.content}
            large={ele.content}
            alt="thitot.vn"
            hideDownload
            hideZoom
            className="max-w-[496px] max-h-[200px] w-full h-full object-contain"
          />
        </Box>
      );
    case "audio":
      return (
        <Box my={1} display="flex" justifyContent="center" onClick={() => eventTracking("select_quiz_ic_ads_video")}>
          <ReactAudioPlayer
            src={ele?.content}
            controls
            onPlay={(event) => {
              event.preventDefault();
              eventTracking(isArena ? "select_are_ic_ads_video" : "select_ic_play_video");
            }}
          />
        </Box>
      );
    default:
      return (
        <Box display="flex" alignItems="baseline">
          {ele?.content && (
            // eslint-disable-next-line
            <div dangerouslySetInnerHTML={{ __html: ele?.content }} />
          )}
        </Box>
      );
  }
};
