/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import moment from "moment";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import UploadImageIcon from "../../../../../public/assets/icons/UploadImageIcon";
import { genSignature, getNonce } from "../../../../utils/helpers/helpers";
import { accountActions } from "../../../account/redux/actions";
import { TAccountState } from "../../../account/utils/types";
import CXModal from "../../../common/components/CXModal";
import { useDebounceEffect } from "../../../common/hooks/useDebounceEffect";
import { commonActions } from "../../../common/redux/actions";
import { getFormData } from "../../../common/utils/constant";
import { LIST_FORMAT_URL_IMAGE, canvasPreview } from "../constants";

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ModalChangeAvatar = ({ open, handleClose }) => {
  const router = useRouter();

  const [img, setImg] = useState<any>();
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [completedCrop, setCompletedCrop] = useState<PixelCrop>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    unit: "px"
  });
  const [crop, setCrop] = useState<Crop>(centerAspectCrop(completedCrop.width, completedCrop.height, 16 / 9));
  const [scale, setScale] = useState(1);
  // const [rotate, setRotate] = useState(0);
  // const [aspect, setAspect] = useState<number | undefined>(16 / 9);
  const rotate = 0;
  const aspect = 16 / 9;
  const [errorMessage, setErrorMessage] = useState<string>("");

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader: any = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      const arrNameFile = e.target.files[0].name?.split(".");
      if (LIST_FORMAT_URL_IMAGE.includes(arrNameFile[arrNameFile.length - 1]) === false) {
        setErrorMessage("Định dạng không hợp lệ, vui lòng chọn lại");
        setImg({});
        return setImgSrc("");
      }

      if (e.target.files[0].size > 1024000) {
        setErrorMessage("Kích cỡ ảnh quá lớn, vui lòng chọn lại");
        setImg({});
        return setImgSrc("");
      }
      reader.addEventListener("load", () => setImgSrc(reader.result.toString() || ""));
      setImg(e.target.files[0]);
      setErrorMessage("");
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const notify = useSelector((state: { account: TAccountState }) => state.account.notify);
  const dispatch = useDispatch();
  const handleUpdateAvatar = () => {
    const params = {
      timestamp: moment().unix(),
      nonce: getNonce()
    };
    const dataPost = {
      ...params,
      avatar: img,
      signature: genSignature(params)
    };
    dispatch(accountActions.handleUpdateInfoAccount(getFormData(dataPost)));
  };
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
    } else if (e.type === "dragleave") {
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const arrNameFile = e.dataTransfer.files[0].name.split(".");
    if (!LIST_FORMAT_URL_IMAGE.includes(arrNameFile[arrNameFile.length - 1])) {
      setErrorMessage("Định dạng không hợp lệ, vui lòng chọn lại");
      setImg({});
      return setImgSrc("");
    }
    if (e.dataTransfer.files[0].size > 102400) {
      setErrorMessage("Kích cỡ ảnh quá lớn, vui lòng chọn lại");
      setImg({});
      return setImgSrc("");
    }
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setImg(e.dataTransfer.files[0]);
      return setImgSrc(URL.createObjectURL(e.dataTransfer.files[0]));
    }
  };
  useEffect(() => {
    if (notify.type === "UPDATE_INFO_ACCOUNT_SUCCESS") {
      handleClose();
      dispatch(accountActions.handleClearNotify());
      dispatch(commonActions.handleGetUserInfo());
      router.replace(router.asPath);
    }
  }, [notify.type]);

  return (
    <CXModal
      open={open}
      title={<FormattedMessage id="account.change.avatar.title" />}
      handleClose={() => handleClose()}
    >
      <div className="w-full text-black">
        {errorMessage && (
          <div className="flex items-center justify-center font-semibold text-center text-redD8 mt-4 gap-2">
            <WarningAmberOutlined />
            {errorMessage}
          </div>
        )}
        {img && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-row flex-nowrap gap-4 justify-center">
              {Boolean(imgSrc.length) && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => {
                    setCrop(percentCrop);
                    setScale(1);
                  }}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  className="max-w-[480px] max-h-[480px]"
                >
                  {imgSrc && (
                    <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} className="aspect-[3/1] w-full" />
                  )}
                </ReactCrop>
              )}
            </div>
          </div>
        )}
        <div className="Crop-Controls mt-6">
          <div onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
            <form
              className="flex flex-col justify-center items-center"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
            >
              <label
                htmlFor="dropzone-file"
                className="flex flex-col justify-center items-center w-full h-30 bg-gray-50 rounded-lg cursor-pointer"
              >
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <UploadImageIcon />
                  <div className="text-[14px]">
                    <span className="text-green">
                      <FormattedMessage id="ask.createPost.uploadImageColor" />
                    </span>
                    <span>
                      <FormattedMessage id="ask.createPost.uploadImage" />
                    </span>
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG (Giới hạn. 480x480px)</p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={onSelectFile} />
              </label>
            </form>
          </div>
        </div>

        <div className="flex flex-row justify-center gap-12 static">
          <button type="button" className="text-green text-[13px] font-semibold" onClick={handleClose}>
            <FormattedMessage id="common.cancel" />
          </button>
          <button
            type="button"
            className={`px-8 py-2 rounded-3xl text-[13px] text-white font-semibold ${
              !imgSrc || !img ? "disabled:bg-gray" : "bg-accept"
            } `}
            onClick={handleUpdateAvatar}
            disabled={!imgSrc || !img}
          >
            <FormattedMessage id="common.save" />
          </button>
        </div>
      </div>
    </CXModal>
  );
};
export default ModalChangeAvatar;
