import React from "react";

export default function IconUnlimited() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.08719 4.66156C4.67719 4.09656 4.0222 3.72656 3.2672 3.72656C2.0122 3.72656 0.992188 4.74656 0.992188 6.00156C0.992188 7.25656 2.0122 8.27655 3.2672 8.27655C4.1122 8.27655 4.8972 7.83157 5.3322 7.10657L5.99719 6.00156L6.6572 4.89655C7.0922 4.17155 7.8772 3.72656 8.7222 3.72656C9.9772 3.72656 10.9972 4.74656 10.9972 6.00156C10.9972 7.25656 9.9772 8.27655 8.7222 8.27655C7.9722 8.27655 7.31719 7.90655 6.90219 7.34155"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
