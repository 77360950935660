/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const useStyles = makeStyles(() => ({
  wrapArrow: {
    "&:hover": {
      "& svg": {
        transition: "all ease .2s"
      }
    }
  }
}));

function SamplePrevArrow(props: {
  onClick?: any;
  isSuggested: boolean | undefined;
}) {
  const classes = useStyles();
  const { isSuggested, onClick } = props;
  return (
    <div
      className={`${classes.wrapArrow} slick-arrow ${
        isSuggested ? "left-6 top-[30%]" : "left-[14px]"
      }`}
      aria-hidden="true"
      onClick={onClick}
    >
      <button
        type="button"
        className="w-[28px] h-[48px] bg-[#61616145] text-white hover:bg-[#0000002e]"
      >
        <ArrowBackIosOutlinedIcon />
      </button>
    </div>
  );
}

function SampleNextArrow(props: {
  onClick?: any;
  isSuggested: boolean | undefined;
}) {
  const classes = useStyles();
  const { isSuggested, onClick } = props;
  return (
    <div
      className={`${classes.wrapArrow} slick-arrow ${
        isSuggested ? "right-[-5px] top-[30%]" : "right-[-14px]"
      }`}
      onClick={onClick}
      aria-hidden="true"
    >
      <button
        type="button"
        className="w-[28px] h-[48px] bg-[#61616145] text-white hover:bg-[#0000002e]"
      >
        <ArrowForwardIosOutlinedIcon />
      </button>
    </div>
  );
}

type TypeCustomSlider = {
  children: any | any[];
  slidesToShow?: number;
  slidesToScroll?: number;
  speed?: any;
  isDots: boolean;
  autoplay: boolean;
  className?: string;
  centerMode?: boolean;
  isSuggested?: boolean;
  isResponsive?:boolean;
};

export default function CustomSlider({
  children,
  slidesToShow,
  slidesToScroll,
  speed,
  isDots,
  autoplay,
  className,
  centerMode,
  isSuggested,
  isResponsive,
}: TypeCustomSlider) {
  const [idxActive, setActive] = useState(0);
  const settings = {
    dots: isDots,
    autoplay,
    autoplaySpeed: 3000,
    // infinite: true,
    swipeToSlide: true,
    centerMode,
    slidesToShow: slidesToShow || 1,
    slidesToScroll: slidesToScroll || 1,
    speed: speed || 500,
    nextArrow: <SampleNextArrow isSuggested={isSuggested} />,
    prevArrow: <SamplePrevArrow isSuggested={isSuggested} />,
    responsive: !isResponsive
      ? undefined
      : [
          {
            breakpoint: 1536,
            settings: {
              slidesToShow: 7
            }
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1
            }
          }
        ],
    beforeChange: (_current: any, next: React.SetStateAction<number>) =>
      setActive(next),
    appendDots: (dots: any[]) => {
      return (
        <div>
          <ul>
            {dots.map(
              (
                item: { props: { children: any } },
                index: React.Key | null | undefined
              ) => {
                return <li key={index}>{item.props.children}</li>;
              }
            )}
          </ul>
        </div>
      );
    },
    customPaging: (i: number) => {
      return (
        <div
          style={{
            width: idxActive === i ? 16 : 12,
            height: idxActive === i ? 16 : 12,
            borderRadius: "50%",
            marginTop: idxActive === i ? -20 : -18,
            backgroundColor: idxActive === i ? "#9E9E9E" : "#c4c0c0"
          }}
        />
      );
    }
  };
  return (
    <Slider {...settings} className={className}>
      {children}
    </Slider>
  );
}
