import React from "react";

export default function IcNickName() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.75 3C9.55109 3 9.36032 3.07902 9.21967 3.21967C9.07902 3.36032 9 3.55109 9 3.75C9 3.94891 9.07902 4.13968 9.21967 4.28033C9.36032 4.42098 9.55109 4.5 9.75 4.5H11.25V19.5H9.75C9.55109 19.5 9.36032 19.579 9.21967 19.7197C9.07902 19.8603 9 20.0511 9 20.25C9 20.4489 9.07902 20.6397 9.21967 20.7803C9.36032 20.921 9.55109 21 9.75 21H14.25C14.4489 21 14.6397 20.921 14.7803 20.7803C14.921 20.6397 15 20.4489 15 20.25C15 20.0511 14.921 19.8603 14.7803 19.7197C14.6397 19.579 14.4489 19.5 14.25 19.5H12.75V4.5H14.25C14.4489 4.5 14.6397 4.42098 14.7803 4.28033C14.921 4.13968 15 3.94891 15 3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H9.75Z"
				fill="#07A3FB"
			/>
			<path
				d="M6 6H9.75V7.5H6C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9V14.9955C4.5 15.3933 4.65804 15.7749 4.93934 16.0562C5.22064 16.3375 5.60218 16.4955 6 16.4955H9.75V17.9955H6C5.20435 17.9955 4.44129 17.6794 3.87868 17.1168C3.31607 16.5542 3 15.7911 3 14.9955V9C3 8.20435 3.31607 7.44129 3.87868 6.87868C4.44129 6.31607 5.20435 6 6 6Z"
				fill="#07A3FB"
			/>
			<path
				d="M18 16.4955H14.25V17.9955H18C18.7956 17.9955 19.5587 17.6794 20.1213 17.1168C20.6839 16.5542 21 15.7911 21 14.9955V9C21 8.20435 20.6839 7.44129 20.1213 6.87868C19.5587 6.31607 18.7956 6 18 6H14.25V7.5H18C18.3978 7.5 18.7794 7.65804 19.0607 7.93934C19.342 8.22064 19.5 8.60218 19.5 9V14.9955C19.5 15.3933 19.342 15.7749 19.0607 16.0562C18.7794 16.3375 18.3978 16.4955 18 16.4955Z"
				fill="#07A3FB"
			/>
		</svg>
	);
}
