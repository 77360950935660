import React from "react";

const IconDiamondRoomCard = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.48199L5.69143 11H6.30857L12 5.48199V4.88366L9.15429 2.12465L8.84571 2H3.15429L2.84571 2.12465L0 4.88366V5.48199ZM6 10.1025L0.925714 5.18283L3.33429 2.83102H8.66571L11.0743 5.18283L6 10.1025ZM6 3.67867H8.30571L9.85714 5.18283L6 8.83933V3.67867Z"
        fill="#7AC863"
      />
    </svg>
  );
};

export default IconDiamondRoomCard;
