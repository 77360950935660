/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import { Button, styled, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Image from "next/image";
import IcDiamond from "../../../../public/assets/icons/icDiamond";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 560,
    padding: "8px"
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));
interface TProps {
  data: any;
  handleCloseDialog: Function;
  triggerFunc: Function;
  openDialog?: boolean;
}
export default function CongratulationAlertDialog({
  data,
  handleCloseDialog,
  triggerFunc,
  openDialog = false
}: TProps) {
  const theme = useTheme();
  const handleClose = () => {
    handleCloseDialog();
    triggerFunc();
  };
  return (
    <CustomDialog open={openDialog} onClose={() => handleClose()}>
      <Box display="flex" justifyContent="center">
        <Image src="/assets/images/congratulationIcon.webp" width={267} height={113} />
      </Box>
      {data?.congratulation_message && (
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center", fontSize:"15px" }}>
          <Typography variant="subtitle1" style={{ fontSize:"15px" }}>
            {data?.congratulation_message}
          </Typography>
        </DialogTitle>
      )}
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" style={{ fontSize:"15px" }}>{data?.success_message}</Typography>
      </DialogTitle>
      {!data?.congratulation_message &&
        data?.reward_type === "diamond" &&
        !!data?.reward_amount && (
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ textAlign: "center", width: "100%" }}
            >
              <Typography variant="body1">
                <FormattedMessage id="Dialog_congratulation.message" /> &nbsp;
              </Typography>
              <Typography
                className="center-row"
                variant="h6"
                style={{
                  color: theme.palette.primary.main,
                  marginTop: 8,
                  display: "flex"
                }}
              >
                <FormattedMessage
                  id="Congratulations.diamond"
                  values={{ num: data?.reward_amount }}
                />
                <IcDiamond />
              </Typography>
            </DialogContentText>
          </DialogContent>
        )}
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => handleClose()}
          style={{ width: 166, height: 40 }}
          className="bg-green-gradient text-white rounded-[100px] text-[13px]"
        >
          <FormattedMessage id="quiz.timerCompleteMsgConfirm" />
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
