/* eslint-disable react/require-default-props */
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import IconComment from "../../../../public/assets/icons/IconComment";
import IconHeath, { IconHeathy } from "../../../../public/assets/icons/IconHeath";
import IconSave, { IconSaved } from "../../../../public/assets/icons/IconSave";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { debounceOnClick } from "../../common/utils/constant";
import { POST_STATUS } from "../utils/constants";
import { IQuestionAndAnswer, TPost } from "../utils/types";

type TProps = {
  postItem: TPost;
  // eslint-disable-next-line no-unused-vars
  handleVote: (id: number, type: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSavePost: (id: number) => void;
  isShowComment?: boolean;
  isDetail?: boolean;
};
const PostItemFooter = ({ postItem, handleVote, handleSavePost, isShowComment = true, isDetail = false }: TProps) => {
  const router = useRouter();

  const { postsCloneToHandleFavoriteAndSave } = useSelector(
    (state: { questionAndAnswer: IQuestionAndAnswer }) => state.questionAndAnswer
  );

  const postItemClone = postsCloneToHandleFavoriteAndSave?.find((post) => post.post_id === postItem.post_id);

  const eventName = () => {
    const category = router?.query?.category;
    if (!isDetail) {
      if (category === "all") {
        return {
          vote: postItemClone?.is_favorite ? "select_ask_list_unvote" : "select_ask_list_vote",
          save: postItemClone?.is_favorite ? "select_ask_list_unsave" : "select_ask_list_all_save",
          comment: "select_ask_list_comment"
        };
      }
      if (category === "save_post") {
        return {
          vote: postItemClone?.is_favorite ? "select_ask_save_unvote" : "select_ask_save_vote",
          save: "select_ask_save_unsave",
          comment: "select_ask_save_comment"
        };
      }
      return {
        vote: postItemClone?.is_favorite ? "select_ask_mine_unvote" : "select_ask_mine_vote",
        save: postItemClone?.is_favorite ? "select_ask_mine_unsave" : "select_ask_mine_save",
        comment: "select_ask_mine_comment"
      };
    }
    return {
      vote: postItemClone?.is_favorite ? "select_post_unvote" : "select_post_vote",
      save: postItemClone?.is_favorite ? "select_post_unsave" : "select_post_save",
      comment: ""
    };
  };
  return (
    <div
      className={`flex items-center ${
        isShowComment ? "md:justify-between" : "md:justify-start"
      } sm:justify-start sm:gap-4 sm:px-2 md:px-3  md:w-[98%] sm:w-screen overflow-hidden box-content`}
    >
      <div className="flex items-center gap-4 text-xs">
        <div>
          <button
            type="button"
            className={`flex items-center gap-2 ${
              postItem?.status === POST_STATUS.OPEN ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={() =>
              eventTracking(
                eventName()?.vote,
                {},
                () =>
                  postItem.status === POST_STATUS.OPEN && debounceOnClick(() => handleVote(postItem?.post_id, "post"))
              )
            }
          >
            {postItemClone?.is_favorite ? <IconHeathy /> : <IconHeath />}

            <span>
              <FormattedMessage id="button.like" />{" "}
              {postItemClone && postItemClone.num_of_vote > 0 ? `(${postItemClone?.num_of_vote})` : ""}
            </span>
          </button>
        </div>
        {isShowComment && (
          <div>
            <button
              type="button"
              className="flex items-center gap-2 text-xs"
              onClick={() =>
                eventTracking(eventName()?.comment, {}, () =>
                  router.push({
                    pathname: `/hoi-dap/${postItem?.post_id}`,
                    query: { postId: postItem?.post_id }
                  })
                )
              }
            >
              <IconComment />
              <span>
                <FormattedMessage id="button.comment" />{" "}
                {postItem?.num_of_comment > 0 ? `(${postItem?.num_of_comment})` : ""}
              </span>
            </button>
          </div>
        )}
      </div>
      <div>
        <button
          type="button"
          className="flex items-center gap-2 text-xs pr-4"
          onClick={() =>
            eventTracking(eventName()?.save, {}, () => debounceOnClick(() => handleSavePost(postItem?.post_id)))
          }
        >
          {postItemClone?.is_saved ? <IconSaved /> : <IconSave />}

          <FormattedMessage id="common.save" />
        </button>
      </div>
    </div>
  );
};
export default PostItemFooter;
