import React from "react";

export default function IconCopy({ width, height, fill }: any) {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="20" y="20" width="20" height="20" rx="5" transform="rotate(-180 20 20)" fill="#F3F3F3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1262 16.2109L14.9296 16.2105C15.3438 16.2105 15.6796 16.5462 15.6797 16.9604C15.6797 17.3746 15.344 17.7105 14.9298 17.7105L12.1264 17.7109L12.1262 17.7109L8.21903 17.7109C6.5193 17.7109 5.13453 17.3807 4.19231 16.4519C3.24742 15.5205 2.91061 14.1486 2.91061 12.4675L2.91061 8.61597L2.91061 5.69187C2.91061 5.27765 3.2464 4.94187 3.66061 4.94187C4.07483 4.94187 4.41061 5.27765 4.41061 5.69187L4.41061 8.61597L4.41061 12.4675C4.41061 13.996 4.72501 14.8708 5.24533 15.3837C5.76831 15.8992 6.66274 16.2109 8.21903 16.2109L12.1261 16.2109L12.1262 16.2109Z"
        fill="#666666"
      />
      <path
        d="M5.83073 11.2083L5.83073 7.95833C5.83073 5.25 6.91406 4.16667 9.6224 4.16667L12.8724 4.16667C15.5807 4.16667 16.6641 5.25 16.6641 7.95833L16.6641 11.2083C16.6641 13.9167 15.5807 15 12.8724 15L9.6224 15C6.91406 15 5.83073 13.9167 5.83073 11.2083Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const IconCopyAccountManagement = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6693 8.5987V11.3987C10.6693 13.732 9.73594 14.6654 7.4026 14.6654H4.6026C2.26927 14.6654 1.33594 13.732 1.33594 11.3987V8.5987C1.33594 6.26536 2.26927 5.33203 4.6026 5.33203H7.4026C9.73594 5.33203 10.6693 6.26536 10.6693 8.5987Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6693 4.5987V7.3987C14.6693 9.73203 13.7359 10.6654 11.4026 10.6654H10.6693V8.5987C10.6693 6.26536 9.73594 5.33203 7.4026 5.33203H5.33594V4.5987C5.33594 2.26536 6.26927 1.33203 8.6026 1.33203H11.4026C13.7359 1.33203 14.6693 2.26536 14.6693 4.5987Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
