import React from "react";

export default function SignOut2() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.89844 7.56219C9.20844 3.96219 11.0584 2.49219 15.1084 2.49219H15.2384C19.7084 2.49219 21.4984 4.28219 21.4984 8.75219V15.2722C21.4984 19.7422 19.7084 21.5322 15.2384 21.5322H15.1084C11.0884 21.5322 9.23844 20.0822 8.90844 16.5422"
        stroke="#F7871D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9972 12H3.61719"
        stroke="#F7871D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.85 8.64844L2.5 11.9984L5.85 15.3484"
        stroke="#F7871D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
