import React from "react";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { ROUTES } from "../../../../utils/configs/router";
import { eventTracking } from "../../../../firebase/firebaseConfig";

const listBtnDirectDiamondPage = [
  {
    title: "find.diamond.title",
    href: ROUTES.kimcuong.path
  },
  {
    title: "add.diamond.title",
    href: ROUTES.kimcuong.napkimcuong.path
  },
  {
    title: "upgrade.premium.title",
    href: ROUTES.kimcuong.nangcapvip.path
  },
  {
    title: "history.trade.title",
    href: ROUTES.kimcuong.lichsugiaodich.path
  }
];
const ListPageDiamond = () => {
  const router = useRouter();
  return (
    <div className="md:w-[250px] lg:w-[298px]">
      <div className="pb-4">
        <span className="text-[18px] font-semibold ">
          <FormattedMessage id="diamond" />
        </span>
      </div>
      <div className="flex flex-col gap-y-4 items-center justify-start">
        {listBtnDirectDiamondPage.map((item) => (
          <button
            key={item.href}
            type="button"
            className={`text-left w-full text-[15px] hover:underline ${
              router?.pathname === item?.href ? "text-green" : "text-blackQuiz"
            }`}
            onClick={() => {
              router?.push(item?.href);
              switch (item.href) {
                case ROUTES.kimcuong.path:
                  eventTracking("view_mission");
                  break;
                case ROUTES.kimcuong.lichsugiaodich.path:
                  eventTracking("view_dia_history");
                  break;
                case ROUTES.kimcuong.nangcapvip.path:
                  eventTracking("view_upgrade");
                  break;
                case ROUTES.kimcuong.napkimcuong.path:
                  eventTracking("view_premium");
                  break;
                default:
                  break;
              }
            }}
          >
            <FormattedMessage id={item?.title} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default React.memo(ListPageDiamond);
