/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { useRouter } from "next/router";
import ArrowRight from "../../../../public/assets/icons/ArrowRight";
import RadioIcon from "../../../../public/assets/icons/RadioIcon";
import RadioIconChecked from "../../../../public/assets/icons/RadioIconChecked";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import CXModal from "../../common/components/CXModal";
import CXTextArena from "../../common/components/CXTextArena";
import { questionAndAnswerAction } from "../redux/actions";
import {
  ACCEPT_IMAGE_TYPE,
  ASK_MAX_LENGTH_REPORT,
  ASK_MAX_LENGTH_REPORT_QUIZ,
  MAX_IMAGE,
  menuComplain
} from "../utils/constants";
import { IQuestionAndAnswer } from "../utils/types";
import { quizActions } from "../../topics/redux/actions";
import { REPORT_QUIZ, getFormData, removeNullAttributeFromObject } from "../../common/utils/constant";
import { TTheme } from "../../../utils/configs/setupTheme";
import { eventTracking } from "../../../firebase/firebaseConfig";

const ModalComplain = ({
  open,
  title,
  handleClose,
  postItem,
  type,
  commentId,
  subtitle,
  openTextInput,
  topic,
  questionId,
  setOpenModalReport,
  isArena = false,
  hasInputImage = false
}: any) => {
  const [value, setValue] = React.useState("");
  const router = useRouter();
  const theme: TTheme = useTheme();
  const intl = useIntl();
  const [complainText, setComplainText] = React.useState(value);
  const [imagesUpload, setImagesUpload] = useState<{
    images?: { file: any; previewUrl: string }[];
    error: string;
  }>({
    error: ""
  });
  const [openInput, setOpenInput] = React.useState(false);
  const dispatch = useDispatch();
  const notification = useSelector(
    (state: { questionAndAnswer: IQuestionAndAnswer }) => state.questionAndAnswer.notification
  );

  const handleCloseModal = () => {
    handleClose();
    setValue("");
    setComplainText("");
    setOpenInput(false);
  };
  const handleOpenInput = () => {
    setOpenInput(true);
    setValue("");
  };
  const handleBackInput = () => {
    setOpenInput(false);
    setComplainText("");
  };

  const handleChangeText = (e) => {
    const complainText = e.target.value;
    if (!complainText.startsWith(" ")) {
      setComplainText(complainText);
    }
  };

  const handleChangeImage = (e) => {
    const filesUploaded = e?.target?.files;
    if (!filesUploaded) return;
    if (Object.values(filesUploaded)?.every((e: any) => ACCEPT_IMAGE_TYPE?.includes(e?.type))) {
      if ((imagesUpload.images?.length || 0) + filesUploaded.length > MAX_IMAGE) {
        setImagesUpload({
          ...imagesUpload,
          error: intl.formatMessage({ id: "inputImage.maxFileError" })
        });
      } else {
        const newImages = Object.values(filesUploaded)?.map((e: any) => ({
          file: e,
          previewUrl: URL?.createObjectURL(e)
        }));
        setImagesUpload({
          ...imagesUpload,
          images: !!imagesUpload?.images ? imagesUpload?.images?.concat(newImages) : newImages,
          error: ""
        });
      }
    } else {
      setImagesUpload({
        ...imagesUpload,
        error: intl.formatMessage({ id: "inputImage.fileTypeError" })
      });
    }
  };

  const handleDeleteImage = (imgUrl) => {
    setImagesUpload({
      images: imagesUpload.images?.filter((img) => img?.previewUrl !== imgUrl),
      error: ""
    });
  };

  const handleComplainQuiz = () => {
    let dataReportQuiz: any = {
      timestamp: moment().unix(),
      nonce: getNonce()
    };
    if (topic) {
      dataReportQuiz = {
        ...dataReportQuiz,
        topic: topic?.id
      };
    }
    if (questionId) {
      dataReportQuiz = {
        ...dataReportQuiz,
        quiz_question_id: questionId
      };
    }
    let params: any = {
      ...dataReportQuiz,
      signature: genSignature(dataReportQuiz),
      room_hid: router?.query?.roomHid
    };
    if (complainText.length > 0) {
      params = {
        ...params,
        reason: complainText.trim()
      };
    }
    if ((imagesUpload?.images?.length || 0) > 0) {
      params = {
        ...params,
        images: imagesUpload?.images?.map((e) => e?.file)
      };
    }
    if (isArena) {
      eventTracking("select_are_quiz_error", {}, () =>
        dispatch(quizActions.handleReportQuiz(getFormData(removeNullAttributeFromObject(params))))
      );
    } else dispatch(quizActions.handleReportQuiz(getFormData(removeNullAttributeFromObject(params))));
    setOpenModalReport(false);
  };
  const handleComplain = () => {
    if (type === REPORT_QUIZ) {
      if (isArena) {
        eventTracking("select_are_error_button", {}, () => handleComplainQuiz());
      } else eventTracking("select_lesson_error_button", {}, () => handleComplainQuiz());
    } else {
      const dataPost = {
        timestamp: moment().unix(),
        nonce: getNonce(),
        report_type: type,
        violation_type: complainText?.length > 0 ? "other" : value,
        object_id: commentId || postItem?.post_id
      };
      let params: any = {
        ...dataPost,
        signature: genSignature(dataPost)
      };
      if (complainText.length > 0) {
        params = {
          ...params,
          content: complainText.trim()
        };
      }
      eventTracking("select_comment_report", {}, () => dispatch(questionAndAnswerAction.handleComplain(params)));
    }
  };

  useEffect(() => {
    if (notification?.type === "HANDLE_COMPLAIN_SUCCESS") {
      handleCloseModal();
    } else if (notification?.type === "HANDLE_COMPLAIN_FAILED") {
      setValue("");
      setComplainText("");
    }
  }, [notification]);

  return (
    <CXModal
      open={open}
      title={
        type === REPORT_QUIZ ? (
          <span className={type === REPORT_QUIZ ? "text-[19px] font-[500]" : "text-lg font-semibold"}>{title}</span>
        ) : (
          title
        )
      }
      handleClose={handleCloseModal}
      subtitle={
        <span className={`text-gray400 text-[15px] pb-2 ${type === REPORT_QUIZ ? "px-4" : "px-6"}`}>
          {subtitle || <FormattedMessage id="menu.complain.subtitle" />}
        </span>
      }
      showArrowLeft={openInput}
      handleBack={handleBackInput}
      minHeight="350px"
      className="text-[19px]"
    >
      <div className={`md:mt-[-10px] sm:mt-[5px] text-black ${type === REPORT_QUIZ && "text-[12px]"}`}>
        {openTextInput ? (
          <div className="flex flex-col">
            <CXTextArena
              maxLength={type === REPORT_QUIZ ? ASK_MAX_LENGTH_REPORT_QUIZ : ASK_MAX_LENGTH_REPORT}
              placeholder={intl.formatMessage({
                id: type === REPORT_QUIZ ? "report.complain.placeholderText" : "ask.complain.placeholderText"
              })}
              // onChange={(e) => setComplainText(e.target.value)}
              onChangeText={handleChangeText}
              content={complainText}
              height={130}
              resizeNone
              className="border-gray text-[15px]"
              hasInputImage={hasInputImage}
              imagesUpload={imagesUpload}
              onChangeImage={handleChangeImage}
              onDeleteImage={handleDeleteImage}
            />
          </div>
        ) : !openInput ? (
          <div className="flex flex-col">
            {menuComplain.map((item, index) => (
              <div
                key={index}
                onClick={() => setValue(item.value)}
                className={`flex justify-between w-full py-2 cursor-pointer hover:bg-green/10  duration-200 rounded-lg px-2 text-[15px] ${
                  type !== REPORT_QUIZ && value === item?.value ? "bg-greenBland1" : "bg-white"
                }`}
              >
                <span>
                  <FormattedMessage id={item.name} />
                </span>
                {type === REPORT_QUIZ ? value !== item.value ? <RadioIcon /> : <RadioIconChecked /> : null}
              </div>
            ))}
            <div
              className="flex justify-between w-full py-2 cursor-pointer px-2 hover:bg-green/10 duration-200 text-[15px] rounded-lg"
              onClick={handleOpenInput}
            >
              <span>
                <FormattedMessage id="menu.complain.other" />
              </span>
              <ArrowRight />
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <CXTextArena
              maxLength={ASK_MAX_LENGTH_REPORT}
              placeholder={intl.formatMessage({
                id: "ask.complain.placeholderText"
              })}
              onChangeText={(e) => setComplainText(e.target.value)}
              content={complainText}
              height={130}
            />
          </div>
        )}
        <div className={`w-full flex justify-center items-center px-2 ${openInput ? "py-[2px]" : "py-2"}`}>
          <button
            type="button"
            className={`px-8 py-2 rounded-3xl text-white disabled:bg-gray text-[13px] ${
              type === REPORT_QUIZ ? " font-[600] w-[343px]" : "font-medium"
            }`}
            onClick={() => {
              handleComplain();
            }}
            disabled={value.length === 0 && complainText.length === 0}
            style={{
              background:
                complainText.length > 0 || value.length > 0
                  ? theme.palette.primary.greenGradient
                  : theme.palette.primary.gray
              // fontSize: type === REPORT_QUIZ ? "13px" : "16px"
            }}
          >
            <FormattedMessage id={type === REPORT_QUIZ ? "title.report.complain.submit" : "menu.complain.submit"} />
          </button>
        </div>
      </div>
    </CXModal>
  );
};

export default ModalComplain;
