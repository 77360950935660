/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import moment from "moment";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import Image from "next/image";
import { ROUTES } from "../../../utils/configs/router";
import IconCopy from "../../../../public/assets/icons/IconCopy";
import { IconCrown, IconCrownGray } from "../../../../public/assets/icons/IconCrown";
import IconDiamond from "../../../../public/assets/icons/IconDiamond";

import { isEmpty } from "../../../utils/helpers/helpers";
import { TCommonState } from "../utils/type";
import CameraIconCircle from "../icons/CameraIconCircle";
import { commonActions } from "../redux/actions";
import Star from "../../../../public/assets/icons/Star";
import { abbreviateNumberFormatter } from "../utils/constant";
import AnonymousAvatar from "../../../../public/assets/icons/AnonymousAvatar";
import { TTheme } from "../../../utils/configs/setupTheme";
import { toastSuccess } from "./toastify";
import { eventTracking } from "../../../firebase/firebaseConfig";

function AccountInfo({ handleOpenModalImage }: { handleOpenModalImage: () => void }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme: TTheme = useTheme();
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const dataUserInfos: any = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const { grade } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const navigate = useRouter();
  const checkPremiumMember =
    !isEmpty(dataUserInfos?.member?.vip_to) && (dataUserInfos?.member?.vip_to as number) >= moment().unix();
  const handleOpenLoginDialog = () => {
    if (!dataUserInfos?.member) {
      dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
    }
  };
  return (
    <Box className="box-cover-left md:w-[250px] lg:w-[298px]">
      <Box className="relative">
        <Image
          src="/assets/images/account_background_small.webp"
          alt="account-background"
          width={!matches1100 ? 250 : 298}
          height={172}
        />
        {!isEmpty(dataUserInfos?.member) ? (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            badgeContent={
              <button type="button" className="w-[20px] h-[20px] rounded-full">
                {checkPremiumMember ? <IconCrown /> : <IconCrownGray />}
              </button>
            }
            className="absolute left-[13px] bottom-[-8px] border-[3px] rounded-[50px] border-white"
          >
            <Avatar
              alt={dataUserInfos?.member?.name}
              src={dataUserInfos?.member?.avatar}
              sx={{
                width: 72,
                height: 72,
                objectFit: "fill"
              }}
            />
            <button
              type="button"
              className="absolute right-0 bottom-0"
              id="account_change_avatar"
              onClick={handleOpenModalImage}
            >
              <CameraIconCircle />
            </button>
          </Badge>
        ) : (
          <button
            type="button"
            className="absolute left-[13px] bottom-[-6px]"
            onClick={() => handleOpenLoginDialog()}
            id="account_avatar_anonymous"
          >
            <AnonymousAvatar />
          </button>
        )}
      </Box>

      {!isEmpty(dataUserInfos?.member) ? (
        <div className="mt-1">
          <div className="flex flex-col  py-1">
            <div className="flex flex-row justify-between items-center">
              <div className="flex justify-start overflow-hidden line-clamp-1 w-[calc(100%-110px)]">
                <span className="text-[16px] line-clamp-1 font-semibold">
                  {dataUserInfos?.member?.use_nickname === 1 && dataUserInfos?.member?.nickname
                    ? dataUserInfos?.member?.nickname
                    : dataUserInfos?.member?.name}
                </span>
              </div>
              <Box>
                {!checkPremiumMember ? (
                  <Button
                    className="bg-green-gradient rounded-[100px] w-[105px] h-[32px]"
                    onClick={() => {
                      navigate.push(`${ROUTES.kimcuong.nangcapvip.path}`);
                    }}
                    id="account_btn_upgrade"
                  >
                    <span className="text-white text-[13px] font-semibold">
                      <FormattedMessage id="Upgrade" />
                    </span>
                  </Button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      navigate.push(`${ROUTES.kimcuong.nangcapvip.path}`);
                    }}
                    className="rounded-[100px] w-[105px] h-[32px] gradient-border-1 border-gradient-green"
                    id="account_btn_extension"
                  >
                    <span className="text-[13px] font-semibold text-green uppercase">
                      <FormattedMessage id="Account.extension" />
                    </span>
                  </button>
                )}
              </Box>
            </div>
            <div className="flex justify-start gap-2 mt-1">
              <span className="text-gray1 text-[10px]">
                <FormattedMessage id="Profile.yourReferencesCodeIs" values={{ code: dataUserInfos?.member?.pub_id }} />
              </span>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(dataUserInfos?.member?.pub_id);
                  toastSuccess(intl.formatMessage({ id: "coppie-success" }));
                }}
                id="account_coppy"
                style={{ padding: 0, marginTop: -2 }}
              >
                <IconCopy />
              </IconButton>
            </div>
          </div>
          <div className="flex justify-center pt-[10px] pb-3">
            <Divider sx={{ width: "89%" }} />
          </div>
          <div className="flex text-[12px] justify-between">
            <span className="flex justify-center items-center w-[70px] h-[24px] bg-gray rounded-[12px]">
              <FormattedMessage id="account.level" values={{ level: dataUserInfos?.member?.level }} />
            </span>
            <div className="flex justify-center items-center h-[24px] bg-gray rounded-[12px] py-1 px-2">
              <span className="flex gap-1 items-center">
                <span className="mb-[1px]">
                  <Star />
                </span>{" "}
                {dataUserInfos?.member?.star || 0}
              </span>
            </div>
            <div className="flex justify-center items-center h-[24px] bg-gray rounded-[12px] py-1 px-2">
              <IconDiamond fill={theme.palette.primary.main} />
              {matches1100 ? (
                <div>
                  <span className="px-1">{abbreviateNumberFormatter(dataUserInfos?.member?.diamond)}</span>
                  {dataUserInfos?.member?.diamond > 0 && (
                    <span className="text-[10px] text-gray400 px-[2px]">
                      (HSD:
                      {moment.unix(dataUserInfos?.member?.diamond_expiry).format("DD/MM/YY")})
                    </span>
                  )}
                </div>
              ) : dataUserInfos?.member?.diamond > 0 ? (
                <Tooltip
                  title={
                    <span>
                      HSD:
                      {moment.unix(dataUserInfos?.member?.diamond_expiry).format("DD/MM/YYYY")}
                    </span>
                  }
                  placement="top-start"
                  enterTouchDelay={0}
                  arrow
                >
                  <div>
                    <span className="pl-1">{abbreviateNumberFormatter(dataUserInfos?.member?.diamond)}</span>
                    <span className="text-red pr-1">*</span>
                  </div>
                </Tooltip>
              ) : (
                <span className="px-1">{abbreviateNumberFormatter(dataUserInfos?.member?.diamond)}</span>
              )}
              <button
                type="button"
                className="mr-[-2px] w-[16px] h-[16px] items-center relative"
                onClick={() => {
                  eventTracking("select_home_dia_icon", { id: grade }, () => {
                    navigate.push(`${ROUTES.kimcuong.napkimcuong.path}`);
                  });
                }}
                id="account_diamond_recharge"
              >
                <Image src="/assets/images/AddCircleIcon.webp" alt="" layout="fill" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-[6px]">
          <div className="flex justify-between items-center">
            <span className="text-[16px] font-semibold" id="account_anonymous">
              <FormattedMessage id="account.anonymous" />
            </span>
            <Button
              className="bg-green-gradient rounded-[100px] w-[105px] h-[32px]"
              onClick={() => {
                handleOpenLoginDialog();
              }}
              id="account_btn_login"
            >
              <span className="text-white text-[13px] font-semibold">
                <FormattedMessage id="Dialog.SignInConfirmButtonText" />
              </span>
            </Button>
          </div>
        </div>
      )}
    </Box>
  );
}

export default React.memo(AccountInfo);
