/* eslint-disable react/require-default-props */
import React from "react";

type ArrowDownProps = {
  width?: number;
  height?: number;
  stroke?: string;
};

const ArrowDown = React.memo(({ width = 24, height = 25, stroke = "#666666" }: ArrowDownProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 9.5L12 17L4.5 9.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
export default ArrowDown;
