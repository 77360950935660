/* eslint-disable no-unsafe-optional-chaining */
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useRef } from "react";
import { useWindowScroll } from "react-use";
import { additionalThresholdScroll } from "../utils/constant";
import GoogleAd from "./GoogleAd";

export default function GoogleAdsScroll({ mainContentHeight, frontBoxHeight, googleAdsHeight, className }) {
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const googleAdsRef = useRef<any>(null);
  const windowScroll = useWindowScroll();
  const fixedGoogleAds =
    windowScroll?.y - frontBoxHeight - 45 > 0 && // 45 la padding voi headers
    mainContentHeight - googleAdsHeight - frontBoxHeight - additionalThresholdScroll > 0;

  const maxHeightScrollGoogleAds = mainContentHeight - googleAdsHeight - windowScroll?.y;

  return (
    <Box
      className={className}
      id="googleAds"
      ref={googleAdsRef}
      sx={{
        position: fixedGoogleAds ? "fixed" : "unset",
        top: fixedGoogleAds && maxHeightScrollGoogleAds > 0 ? "56px" : `${maxHeightScrollGoogleAds + 45}px`,
        transition: "height 150ms"
      }}
    >
      <GoogleAd
        style={{
          display: "block",
          height: googleAdsHeight,
          width: !matches1100 ? 250 : 298
        }}
        slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_BANNER}
        adTypeTracking="ads_native_ads_view"
      />
    </Box>
  );
}
