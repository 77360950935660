import React from "react";

const TeacherAccountIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.27101 2.84141L2.94475 5.36705C1.23717 6.17679 1.23717 7.98908 2.94475 8.79882L8.27101 11.3245C9.22655 11.7807 10.8014 11.7807 11.757 11.3245L17.0567 8.79882C18.7554 7.98908 18.7554 6.18322 17.0567 5.37347L11.757 2.84784C10.8014 2.38513 9.22655 2.38513 8.27101 2.84141Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.17321 10L4.16406 13.9456C4.16406 15.014 5.06009 16.1582 6.15727 16.4947L9.07394 17.3864C9.57681 17.5379 10.4088 17.5379 10.9209 17.3864L13.8375 16.4947C14.9347 16.1582 15.8307 15.014 15.8307 13.9456V10.0421"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3359 12.5013V6.66797"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TeacherAccountIcon;
