import React from "react";

export default function IconGoogle() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0034 3.86654C11.8811 3.86654 13.1477 4.67763 13.8699 5.35538L16.692 2.59992C14.9588 0.988857 12.7033 0 10.0034 0C6.09237 0 2.7147 2.24437 1.07031 5.51093L4.30354 8.02197C5.11463 5.61093 7.359 3.86654 10.0034 3.86654Z"
        fill="#EA4335"
      />
      <path
        d="M19.5997 10.2241C19.5997 9.40187 19.533 8.80189 19.3886 8.17969H10V11.8907H15.5109C15.3998 12.8129 14.7998 14.2017 13.4666 15.135L16.622 17.5794C18.5108 15.835 19.5997 13.2684 19.5997 10.2241Z"
        fill="#4285F4"
      />
      <path
        d="M4.31097 11.9743C4.09987 11.3521 3.97765 10.6854 3.97765 9.99656C3.97765 9.30769 4.09987 8.64105 4.29986 8.01884L1.06663 5.50781C0.388877 6.86332 0 8.3855 0 9.99656C0 11.6076 0.388877 13.1298 1.06663 14.4853L4.31097 11.9743Z"
        fill="#FBBC05"
      />
      <path
        d="M10.0001 19.9985C12.7 19.9985 14.9666 19.1097 16.6221 17.5764L13.4666 15.132C12.6222 15.7209 11.4889 16.132 10.0001 16.132C7.3557 16.132 5.11133 14.3876 4.31136 11.9766L1.07812 14.4876C2.72252 17.7542 6.08908 19.9985 10.0001 19.9985Z"
        fill="#34A853"
      />
    </svg>
  );
}
