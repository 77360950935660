/* eslint-disable react/require-default-props */
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import React from "react";
import { TTheme } from "../../../utils/configs/setupTheme";

export function CXCircularProgress({ height = "30vh" }: { height?: string | undefined } = {}) {
  const theme: TTheme = useTheme();
  return (
    <Box className="circular-progress sm:w-screen md:w-full" height={height}>
      <CircularProgress sx={{ color: theme.palette.primary.main }} disableShrink />
    </Box>
  );
}
