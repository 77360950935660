import React from "react";

export default function IcSex({ className, fill }: any) {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.264 4.61905H12.4V3H18V8.66667H16.4V5.76048L13.336 8.86095C13.6976 9.41339 13.9196 10.0472 13.9825 10.7067C14.0454 11.3663 13.9474 12.0314 13.697 12.6436C13.4466 13.2559 13.0515 13.7965 12.5464 14.2182C12.0413 14.6398 11.4416 14.9296 10.8 15.0619V16.7619H12.4V18.381H10.8V20H9.2V18.381H7.6V16.7619H9.2V15.0619C8.29651 14.8753 7.48448 14.3785 6.90136 13.6557C6.31823 12.9328 5.99981 12.0283 6 11.0952C6 10.0217 6.42143 8.99222 7.17157 8.23314C7.92172 7.47406 8.93913 7.04762 10 7.04762C10.8 7.04762 11.568 7.29048 12.2 7.71952L15.264 4.61905ZM10 8.66667C9.36348 8.66667 8.75303 8.92253 8.30294 9.37798C7.85286 9.83342 7.6 10.4511 7.6 11.0952C7.6 11.7393 7.85286 12.3571 8.30294 12.8125C8.75303 13.2679 9.36348 13.5238 10 13.5238C10.6365 13.5238 11.247 13.2679 11.6971 12.8125C12.1471 12.3571 12.4 11.7393 12.4 11.0952C12.4 10.4511 12.1471 9.83342 11.6971 9.37798C11.247 8.92253 10.6365 8.66667 10 8.66667Z"
				fill={fill || "#666666"}
			/>
		</svg>
	);
}
