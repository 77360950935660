/* eslint-disable no-nested-ternary */
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from "lodash";
import Link from "next/link";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { TTheme } from "../../../utils/configs/setupTheme";
import PostItem from "../../questionAndAnswer/components/PostItem";
import { TPackages, TPost } from "../../questionAndAnswer/utils/types";
import CustomSlider from "./CustomSlider";

const TAB = {
  QUESTIONS_CREATED: "created_by_me",
  QUESTIONS_ANSWERED: "created_by_other"
};

type PostsInfoBlockProps = {
  posts: TPost[];
  subjects: TPackages[];
};

const PostsInfoBlock = ({ posts, subjects }: PostsInfoBlockProps) => {
  const theme: TTheme = useTheme();
  const intl = useIntl();
  const matches768 = useMediaQuery("(min-width:768px)");

  const [tab, setTab] = useState<string>(TAB.QUESTIONS_CREATED);

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  const questionsCreated = useMemo(() => {
    return posts.filter((postItem) => postItem.data_filter === TAB.QUESTIONS_CREATED);
  }, [posts]);

  const questionsAnswered = useMemo(() => {
    return posts.filter((postItem) => postItem.data_filter === TAB.QUESTIONS_ANSWERED);
  }, [posts]);

  const questionsRender = useMemo(() => {
    switch (tab) {
      case TAB.QUESTIONS_CREATED:
        return questionsCreated;
      case TAB.QUESTIONS_ANSWERED:
        return questionsAnswered;
      default:
        return [];
    }
  }, [tab, questionsCreated, questionsAnswered]);

  useEffect(() => {
    if (!questionsCreated.length) setTab(TAB.QUESTIONS_ANSWERED);
  }, [questionsCreated]);

  return (
    <Box className="flex flex-col gap-4">
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        sx={{
          height: 36,
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.main
          },
          "& .MuiTab-root": {
            color: theme.palette.primary.gray400
          },
          "& .MuiTab-root.Mui-selected": {
            color: theme.palette.primary.main
          }
        }}
      >
        <Tab
          label={intl.formatMessage({ id: "Account.question.questionsCreated" })}
          className={`normal-case text-[15px] font-semibold ${!questionsCreated.length && "hidden"}`}
          value={TAB.QUESTIONS_CREATED}
        />
        <Tab
          label={intl.formatMessage({ id: "Account.question.questionsAnswered" })}
          className={`normal-case text-[15px] font-semibold ${!questionsAnswered.length && "hidden"}`}
          value={TAB.QUESTIONS_ANSWERED}
        />
      </Tabs>
      <Box>
        <CustomSlider
          isDots={false}
          autoplay={false}
          className="w-full"
          centerMode={matches768 && questionsRender.length > 1}
        >
          {questionsRender.slice(0, 10).map((postItem) => (
            <Link key={postItem?.post_id} href={`/hoi-dap/${postItem?.post_id}`}>
              <a
                href={`/hoi-dap/${postItem?.post_id}`}
                rel="noreferrer"
                target="_blank"
                className="inline-block py-2 md:mx-1.5 h-[200px] w-full max-w-[360px] border-gray border rounded-[16px]"
              >
                <PostItem
                  postItem={postItem}
                  packages={subjects}
                  handleVote={() => {}}
                  handleSavePost={() => {}}
                  page="homepage"
                />
              </a>
            </Link>
          ))}
        </CustomSlider>
      </Box>
    </Box>
  );
};

const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.posts, nextProps?.posts) && isEqual(prevProps?.subjects, nextProps?.subjects);
};

export default memo(PostsInfoBlock, checkEqualProps);
