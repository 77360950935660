import React from "react";

export default function TransHistory() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.91406 11.4575C7.91406 12.2659 8.53907 12.9159 9.30574 12.9159H10.8724C11.5391 12.9159 12.0807 12.3492 12.0807 11.6409C12.0807 10.8825 11.7474 10.6076 11.2557 10.4326L8.7474 9.55755C8.25573 9.38255 7.9224 9.11588 7.9224 8.34922C7.9224 7.64922 8.46406 7.07422 9.13072 7.07422H10.6974C11.4641 7.07422 12.0891 7.72422 12.0891 8.53255"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 6.25V13.75" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.3307 10.0013C18.3307 14.6013 14.5974 18.3346 9.9974 18.3346C5.3974 18.3346 1.66406 14.6013 1.66406 10.0013C1.66406 5.4013 5.3974 1.66797 9.9974 1.66797"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1641 2.5V5.83333H17.4974"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3307 1.66797L14.1641 5.83464"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
