/* eslint-disable consistent-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  RadioGroup,
  TextField,
  useMediaQuery
} from "@mui/material";
import { isEmpty, orderBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { clientKeyAdsInHouse, secretKeyAdsInHouse, uidAdsInHouse } from "../../../utils/constants/env";
import { TCommonState } from "../utils/type";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import { commonActions } from "../../common/redux/actions";
import { CustomCheckbox } from "./Elements/CustomCheckBox";
import { CustomRadio } from "./Elements/CustomRadio";
import {
  GET_DAILY_REPORT_DATE_LOCAL_STORAGE_KEY,
  LOCATION_PERMISSION_UNDEFINE,
  SURVEY_CONTENT_TYPE,
  SURVEY_DIALOG_TYPE,
  SURVEY_SCREEN,
  getSurveyScreen
} from "../utils/constant";
// import { DebuggerMessage } from "../../../utils/constants/constants";
import { isToday } from "../../../utils/helpers";

export const SurveyContent = ({ content, surveyData, handleSetSurveyData, disableAutoFocus }: any) => {
  const matches768 = useMediaQuery("(min-width:768px)");
  const multiChoiceValues = surveyData[SURVEY_CONTENT_TYPE.MULTIPLE_CHOICE];
  const handleFillSurveyCheckBox = (val) => {
    if (multiChoiceValues?.includes(val)) {
      handleSetSurveyData({
        [SURVEY_CONTENT_TYPE.MULTIPLE_CHOICE]: multiChoiceValues.filter((e) => e !== val)
      });
    } else {
      handleSetSurveyData({
        [SURVEY_CONTENT_TYPE.MULTIPLE_CHOICE]: multiChoiceValues.concat([val])
      });
    }
  };
  return (
    <div className="text-[16px]">
      {content?.question_type === SURVEY_CONTENT_TYPE.TEXT ? (
        <TextField
          value={surveyData[SURVEY_CONTENT_TYPE.TEXT]}
          autoFocus={disableAutoFocus ? false : matches768}
          multiline
          fullWidth
          variant="filled"
          onChange={(e) => handleSetSurveyData({ [SURVEY_CONTENT_TYPE.TEXT]: e.target.value })}
          InputProps={{
            disableUnderline: true
          }}
          sx={{
            "& .MuiFilledInput-root": {
              borderRadius: "8px"
            },
            "& .MuiFilledInput-input": {
              marginTop: -2,
              paddingY: "6px"
            }
          }}
        />
      ) : content?.question_type === SURVEY_CONTENT_TYPE.ONCE_CHOICE ? (
        <RadioGroup value={surveyData[SURVEY_CONTENT_TYPE.ONCE_CHOICE]}>
          {content?.options?.map((ops) => (
            <FormControlLabel
              key={ops}
              value={ops}
              onChange={() => handleSetSurveyData({ [SURVEY_CONTENT_TYPE.ONCE_CHOICE]: ops })}
              control={<CustomRadio />}
              label={ops}
            />
          ))}
        </RadioGroup>
      ) : (
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {content?.options?.map((ops) => (
              <FormControlLabel
                key={ops}
                control={
                  <CustomCheckbox
                    checked={multiChoiceValues?.includes(ops)}
                    onChange={() => handleFillSurveyCheckBox(ops)}
                    name={ops}
                  />
                }
                label={ops}
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
    </div>
  );
};

const getTimeShowDialogKey = (dialogSurvey) => {
  return `${dialogSurvey?.screen}_${dialogSurvey?.location_survey?.dialog}_${dialogSurvey?.survey_id}`;
};

type TSurveyComponents = {
  routerPath: string;
  teacherDialogStatus: boolean[];
};
const initialStateSurveyData = {
  [SURVEY_CONTENT_TYPE.ONCE_CHOICE]: "",
  [SURVEY_CONTENT_TYPE.MULTIPLE_CHOICE]: [],
  [SURVEY_CONTENT_TYPE.TEXT]: ""
};

function SurveyComponents({ routerPath, teacherDialogStatus }: TSurveyComponents) {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    congratulation,
    locationPermission,
    isNewMember,
    isEmptyName,
    userInfos,
    survey,
    routerHistory,
    notify,
    hideDialogSurvey,
    timeShowSurvey,
    openDialogAdsInHouse,
    isShowAdsDialogFirstTime,
    dailyReport
  } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);

  const [ordinalContentShow, setOrdinalContentShow] = useState(1); // default first
  const [currentDialog, setCurrentDialog] = useState<any>(undefined);
  const [isCheckShowDialogAfter, setIsCheckShowDialogAfter] = useState(false);
  const [surveyDataSubmit, setSurveyDataSubmit] = useState(initialStateSurveyData);
  const dialogIndex = useRef(1);
  // DebuggerMessage(`TIME SHOW SURVEY: ${JSON.stringify(timeShowSurvey)}`);

  const handleGetSurveys = () => {
    const paramsGenSignature = {
      nonce: getNonce(),
      timestamp: moment().unix(),
      uid: uidAdsInHouse
      // screen: SURVEY_SCREEN[routerPath]
    };
    const params = {
      ...paramsGenSignature,
      signature: genSignature(paramsGenSignature, clientKeyAdsInHouse, secretKeyAdsInHouse),
      device_cd: userInfos?.device?.device_cd || Math.random().toString(32),
      pub_id: userInfos?.member?.pub_id,
      platform: "web"
    };
    dispatch(commonActions.handleGetSurvey(params));
  };

  const handleSubmitSurvey = () => {
    const contentSurveysSubmit = currentDialog?.content_surveys
      ?.map((ele) => {
        return {
          content_survey_id: ele.id,
          answer:
            ele.question_type === SURVEY_CONTENT_TYPE.MULTIPLE_CHOICE
              ? (surveyDataSubmit[ele.question_type] as string[]).join("#")
              : surveyDataSubmit[ele.question_type]
        };
      })
      ?.filter((e) => !isEmpty(e?.answer));

    const paramsGenSignature = {
      nonce: getNonce(),
      timestamp: moment().unix(),
      uid: uidAdsInHouse,
      survey_id: currentDialog?.survey_id
    };
    const params = {
      ...paramsGenSignature,
      signature: genSignature(paramsGenSignature, clientKeyAdsInHouse, secretKeyAdsInHouse),
      content_surveys: contentSurveysSubmit,
      device_cd: userInfos?.device?.device_cd || Math.random().toString(32),
      pub_id: userInfos?.member?.pub_id
    };
    dispatch(commonActions.handleSubmitSurvey(params));
  };

  let dialogSurveyAfter: any;
  if (routerHistory?.length >= 2) {
    const previousScreen = getSurveyScreen(routerHistory[routerHistory?.length - 2], router);
    if (previousScreen) {
      dialogSurveyAfter = survey?.items?.find(
        (ele) => ele?.location_survey?.dialog === SURVEY_DIALOG_TYPE.AFTER && ele?.screen === previousScreen
      );
    }
  }

  const getDialogInSide = (screen) => {
    return survey?.items?.find(
      (ele) =>
        ele?.location_survey?.dialog === SURVEY_DIALOG_TYPE.INSIDE &&
        ele?.screen === screen &&
        ele?.index === dialogIndex.current
    );
  };

  // gan index cho tung cau hoi trong content
  const surveyContents = orderBy(currentDialog?.content_surveys, ["ordinal"])?.map((e, index) => {
    return { ...e, index: index + 1 };
  });
  const currentContent = surveyContents.find((e) => e.index === ordinalContentShow);
  const lengthContent = currentDialog?.content_surveys?.length;

  const handleSetSurveyDataSubmit = (data) => {
    setSurveyDataSubmit({ ...surveyDataSubmit, ...data });
  };

  const handleCloseDialog = () => {
    const timesShowDialogCurrent =
      (!isEmpty(timeShowSurvey) && timeShowSurvey[getTimeShowDialogKey(currentDialog)]) || 0;

    if (currentDialog?.location_survey?.dialog === SURVEY_DIALOG_TYPE.AFTER) {
      // nếu là dialog after thì lưu thêm 1 key theo screen để check mỗi 1 screen chỉ hiển thị 1 dialog after
      dispatch(
        commonActions.handleSaveTimeShowSurvey({
          [getTimeShowDialogKey(currentDialog)]: timesShowDialogCurrent + 1,
          [`${getTimeShowDialogKey(currentDialog)}_${getSurveyScreen(router?.pathname, router)}`]: 1
        })
      );
    } else {
      dispatch(
        commonActions.handleSaveTimeShowSurvey({ [getTimeShowDialogKey(currentDialog)]: timesShowDialogCurrent + 1 })
      );
    }

    setCurrentDialog(undefined);
    setSurveyDataSubmit(initialStateSurveyData);
    setOrdinalContentShow(1); // set to default
  };
  const checkDisableButtonSubmit = currentDialog?.content_surveys?.some(
    (e) => e?.is_require && isEmpty(surveyDataSubmit[e?.question_type])
  );

  useEffect(() => {
    if (!isCheckShowDialogAfter || isEmpty(survey)) return;
    // tam thoi xu ly case 1 dialog inside
    const currentScreen = getSurveyScreen(router?.pathname, router);
    const dialogInSide: any = getDialogInSide(currentScreen);
    let timer: any;
    if (!isEmpty(dialogInSide) && !currentDialog) {
      const timesShowDialogInside =
        (!isEmpty(timeShowSurvey) && timeShowSurvey[getTimeShowDialogKey(dialogInSide)]) || 0;
      if (timesShowDialogInside === 0) {
        // set show first dialog inside
        timer = setTimeout(() => {
          // check như này để fix case khi đang debounce thì chuyển page => sang page mới vẫn nhảy vào đây => set sai thông tin survey
          if (dialogInSide?.screen === currentScreen) {
            setCurrentDialog(dialogInSide);
          }
        }, Number(dialogInSide?.location_survey?.after_show) * 1000);
      } else if (timesShowDialogInside < dialogInSide?.num_of_show) {
        timer = setTimeout(() => {
          if (dialogInSide?.screen === currentScreen) {
            setCurrentDialog(dialogInSide);
          }
        }, Number(dialogInSide?.interval_between_times) * 1000);
      } else {
        // set hien thi dialog tiep theo
        dialogIndex.current += 1;
        // timesShowDialog.current = 0;
      }
    }
    return () => clearTimeout(timer);
  }, [currentDialog, isCheckShowDialogAfter, survey]);

  useEffect(() => {
    if (isEmpty(survey)) return;
    setIsCheckShowDialogAfter(true);
    let timer2: any;
    if (dialogSurveyAfter) {
      const timesShowDialogAfter =
        (!isEmpty(timeShowSurvey) && timeShowSurvey[getTimeShowDialogKey(dialogSurveyAfter)]) || 0;
      // check xem đã hiển thị dialog after ở screen hiện tại hay chưa
      const isShowDialogAfterInCurrentScreen =
        !isEmpty(timeShowSurvey) &&
        !timeShowSurvey[`${getTimeShowDialogKey(dialogSurveyAfter)}_${getSurveyScreen(router?.pathname, router)}`];

      if (!isShowDialogAfterInCurrentScreen) return;
      if (timesShowDialogAfter < dialogSurveyAfter?.num_of_show) {
        // set show dialog after truoc, timeout 1.5s
        timer2 = setTimeout(() => {
          setCurrentDialog(dialogSurveyAfter);
        }, 1500);
      }
    }
    return () => clearTimeout(timer2);
  }, [dialogSurveyAfter, survey]);

  useEffect(() => {
    if (notify.type === "SUBMIT_SURVEY_SUCCESS") {
      handleCloseDialog();
    }
  }, [notify]);

  useEffect(() => {
    if (routerPath in SURVEY_SCREEN) {
      handleGetSurveys();
      if (currentDialog?.location_survey?.dialog !== SURVEY_DIALOG_TYPE.AFTER) {
        setCurrentDialog(undefined);
      }
    }
  }, []);
  // if (isEmpty(currentDialog)) return null;

  const getDailyReportDate = localStorage.getItem(GET_DAILY_REPORT_DATE_LOCAL_STORAGE_KEY);
  const stateDialogBeforeSurveys = [
    isEmptyName,
    ...teacherDialogStatus,
    isNewMember,
    congratulation?.isOpenCongratulation,
    locationPermission === LOCATION_PERMISSION_UNDEFINE,
    openDialogAdsInHouse && isShowAdsDialogFirstTime && router.asPath === "/",
    (!getDailyReportDate || !isToday(new Date(getDailyReportDate))) &&
      !dailyReport?.is_displayed &&
      !!dailyReport?.data_report?.title_report
  ];

  return (
    <Dialog
      open={
        !isEmpty(currentDialog) &&
        !hideDialogSurvey &&
        !notify?.isOpenDialog &&
        stateDialogBeforeSurveys.every((e) => !e)
      }
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleCloseDialog();
        }
      }}
      // scroll="paper"
      sx={{
        "& .MuiPaper-root": {
          width: 560,
          maxHeight: "80vh",
          minHeight: 460,
          padding: "8px",
          borderRadius: "8px"
        }
      }}
    >
      <IconButton className="absolute right-0 top-0" aria-label="close" onClick={handleCloseDialog}>
        <CloseIcon />
      </IconButton>
      <div
        className={`max-h-[250px] ${
          currentContent?.content?.length > 30 ? "overflow-y-auto" : "h-max"
        } mt-6 px-6 text-[22px] mb-2 font-[600]`}
      >
        {currentContent?.content} {currentContent?.is_require && <span className="text-red">*</span>}
      </div>
      {[SURVEY_CONTENT_TYPE.MULTIPLE_CHOICE, SURVEY_CONTENT_TYPE.ONCE_CHOICE].includes(
        currentContent?.question_type
      ) && <Divider />}
      <DialogContent>
        <SurveyContent
          content={currentContent}
          surveyData={surveyDataSubmit}
          handleSetSurveyData={handleSetSurveyDataSubmit}
        />
      </DialogContent>
      <DialogActions
        sx={{
          "& .MuiDialogActions-root": {
            height: "80px",
            marginTop: "20px"
          }
        }}
        className="flex justify-between items-center"
      >
        <Button
          sx={{ textTransform: "none", visibility: ordinalContentShow > 1 ? "visible" : "hidden" }}
          className="px-[40px] h-[44px] text-green"
          onClick={() => ordinalContentShow > 1 && setOrdinalContentShow(ordinalContentShow - 1)}
        >
          <FormattedMessage id="survey.button.back" />
        </Button>
        {lengthContent > 1 && <span className="font-[600]">{`${ordinalContentShow}/${lengthContent}`}</span>}
        {ordinalContentShow < lengthContent ? (
          <Button
            sx={{
              textTransform: "none"
            }}
            className="bg-green w-[120px] h-[44px] text-white rounded-[16px]"
            onClick={() => ordinalContentShow <= lengthContent - 1 && setOrdinalContentShow(ordinalContentShow + 1)}
          >
            <FormattedMessage id="survey.button.next" />
          </Button>
        ) : (
          <Button
            disabled={checkDisableButtonSubmit}
            sx={{
              textTransform: "none"
            }}
            className={`${
              checkDisableButtonSubmit ? "bg-gray3" : "bg-green"
            } w-[120px] h-[44px] text-white rounded-[16px]`}
            onClick={() => {
              handleSubmitSurvey();
              // handleCloseDialog();
            }}
          >
            <FormattedMessage id="survey.button.send" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default SurveyComponents;
