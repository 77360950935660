import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { MISSION_VIEW_ADS_EXAM_SKU } from "../../../utils/constants/constants";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
// import MissionsDialog from "../../account/components/MissionsDiamond";
import AlertDialog from "../dialog/AlertDialog";
import GoogleAdDialog from "../dialog/GoogleAdDialog";
import { commonActions } from "../redux/actions";
// import { getUserInfo } from "../utils/constant";

const ModalEarnDiamond = ({
  openAlertDialogViewAds,
  setOpenAlertDialogViewAds,
  triggerFunc
}) => {
  const dispatch = useDispatch();
  const { congratulation } = useSelector(
    (state: { commonNew }) => state.commonNew
  );
  const [openGgAd, setOpenGgAd] = React.useState(false);
  const handleViewAds = () => {
    const dataCompleteMissionPre = {
      sku: MISSION_VIEW_ADS_EXAM_SKU,
      timestamp: moment().unix(),
      nonce: getNonce()
    };
    const dataCompleteMission = {
      ...dataCompleteMissionPre,
      signature: genSignature(dataCompleteMissionPre)
    };
    dispatch(commonActions.handleCompleteMission(dataCompleteMission));
  };
  React.useEffect(() => {
    if (congratulation?.type === "COMPLETE_MISSION_SUCCESS") {
      triggerFunc();
      dispatch(commonActions.deleteCongratulation());
    }
  });
  return (
    <>
      <AlertDialog
        open={openAlertDialogViewAds}
        setOpen={setOpenAlertDialogViewAds}
        title={<FormattedMessage id="IDS_View_Ads" />}
        content={<FormattedMessage id="common.viewsAds" />}
        triggerFunc={() => {
          setOpenGgAd(true);
        }}
        buttonOkKey="common.ok"
      />
      <GoogleAdDialog
        open={openGgAd}
        setOpen={setOpenGgAd}
        triggerFunc={() => {
          handleViewAds();
        }}
      />
      {/* <MissionsDialog
        open={openDialogGiftDiamond}
        setOpen={handleChangeOpenModalGiftDiamond}
        fetchUserInfos={getUserInfo}
      /> */}
    </>
  );
};
export default ModalEarnDiamond;
