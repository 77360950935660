import React from "react";

export default function AppleIcon() {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.857 12.6832C16.8455 10.7159 17.7368 9.2333 19.5367 8.14007C18.53 6.69782 17.007 5.90458 14.9994 5.7517C13.0985 5.60171 11.0188 6.85935 10.2573 6.85935C9.45252 6.85935 7.6122 5.80362 6.16418 5.80362C3.17584 5.84977 0 8.18622 0 12.9399C0 14.3446 0.256721 15.7955 0.770162 17.2897C1.45667 19.2569 3.93158 24.0769 6.51321 23.9991C7.86316 23.9673 8.81793 23.0414 10.5746 23.0414C12.2793 23.0414 13.162 23.9991 14.6677 23.9991C17.2724 23.9616 19.5108 19.58 20.1627 17.607C16.6695 15.96 16.857 12.7841 16.857 12.6832ZM13.8254 3.88543C15.2879 2.14895 15.1552 0.568247 15.1119 0C13.8197 0.0749971 12.3255 0.879774 11.4746 1.86916C10.5371 2.93066 9.98615 4.2431 10.1044 5.72285C11.5005 5.82958 12.7755 5.11134 13.8254 3.88543Z"
        fill="white"
      />
    </svg>
  );
}
