import { ACTION_TYPES } from "./actionTypes";

const handleGetListTopic = (params: any) => {
  return {
    type: ACTION_TYPES.GET_LIST_TOPIC,
    params
  };
};
const handleGetListSubject = (params: any) => {
  return {
    type: ACTION_TYPES.GET_LIST_SUBJECTS,
    params
  };
};
const handleCleanNotify = () => {
  return {
    type: ACTION_TYPES.CLEAN_NOTIFY
  };
};
const handleOpenModalEarnDiamond = (params: boolean) => {
  return {
    type: ACTION_TYPES.HANDLE_CHANGE_OPEN_MODAL_EARN_DIAMOND,
    params
  };
};
export const subjectActions = {
  handleGetListTopic,
  handleGetListSubject,
  handleCleanNotify,
  handleOpenModalEarnDiamond,
};
