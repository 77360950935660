import { Checkbox, CheckboxProps, useTheme } from "@mui/material";
import React from "react";
import { CheckBoxChecked, CheckBoxNone } from "../../../../../public/assets/icons/CheckBox";

export const CustomCheckbox = (props: CheckboxProps) => {
  const theme = useTheme();
  return (
    <Checkbox
      sx={{
        "&.Mui-checked": {
          color: theme.palette.primary.main
        }
      }}
      icon={<CheckBoxNone />}
      checkedIcon={<CheckBoxChecked />}
      {...props}
    />
  );
};
