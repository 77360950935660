/* eslint-disable no-unused-expressions */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { OAuthCredential } from "firebase/auth";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import Image from "next/image";
import AppleIcon from "../../../../public/assets/icons/AppleIcon";
import IconFacebook from "../../../../public/assets/icons/IconFacebook";
import IconGoogle from "../../../../public/assets/icons/icGoogle";
import { auth, providerApple, providerFacebook, providerGoogle } from "../../../firebase/firebaseConfig";
import { commonActions } from "../../common/redux/actions";

function LoginSocial({ onSuccess = () => {} }) {
  const dispatch = useDispatch();
  const matches768 = useMediaQuery("(min-width:768px)");
  return (
    <>
      {!matches768 && (
        <Box className="sm:flex md:hidden items-center justify-center z-10">
          <Image src="/assets/images/logo_chinh_xac_19.webp" width={112} height={112} alt="image dang nhap" />
        </Box>
      )}
      <Box display="flex" justifyContent="center" className="">
        {matches768 && (
          <div className="sm:hidden md:block">
            <Image src="/assets/images/loginIcon.webp" width={217} height={217} alt="image dang nhap" />
          </div>
        )}

        <Box
          display="flex"
          flexDirection="column"
          marginTop="38px"
          className="md:ml-[28px] sm:ml-0 sm:text-center md:text-left"
        >
          <span style={{ fontWeight: 400 }} className="font-semibold uppercase text-[20px]">
            <FormattedMessage id="hello" />
          </span>
          <span style={{ fontWeight: 400 }} className="text-[16px font-semibold]">
            <FormattedMessage id="Login_to_continue" />
          </span>
          <Button
            variant="outlined"
            onClick={() => {
              auth.signInWithPopup(providerGoogle).then((result) => {
                const credential = result.credential as OAuthCredential;
                auth.onAuthStateChanged((user) => {
                  user?.getIdToken()?.then((ele) => {
                    dispatch(
                      commonActions.addFireBaseInfos({
                        accessToken: credential?.accessToken,
                        idToken: ele,
                        // pendingToken: credential?.pendingToken,
                        providerId: credential?.providerId,
                        signInMethod: credential?.signInMethod
                      })
                    );
                  });
                });
                onSuccess();
              });
            }}
            style={{
              marginBottom: 12,
              borderRadius: 26,
              width: 276,
              height: 48,
              marginTop: 32,
              display: "flex",
              alignItems: "center",
              border: "1px solid #006000",
              color: "black"
            }}
            className="justify-start px-5"
          >
            <Box className="w-[24px]">
              <IconGoogle />
            </Box>
            <span className="ml-2 normal-case line-clamp-1 font-normal text-start">
              <FormattedMessage id="Login_via_email" />
            </span>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{
              marginBottom: 12,
              borderRadius: 26,
              width: 276,
              height: 48,
              display: "flex",
              alignItems: "center",
              background: "#2F80ED",
              color: "white",
              border: "1px solid #2F80ED"
            }}
            onClick={() => {
              auth.signInWithPopup(providerFacebook).then((result) => {
                auth.onAuthStateChanged((user) => {
                  user?.getIdToken()?.then((ele) => {
                    const credential = result.credential as OAuthCredential;
                    onSuccess && onSuccess();
                    dispatch(
                      commonActions.addFireBaseInfos({
                        accessToken: credential?.accessToken,
                        idToken: ele,
                        // pendingToken: credential?.pendingToken,
                        providerId: credential?.providerId,
                        signInMethod: credential?.signInMethod
                      })
                    );
                  });
                });
              });
            }}
            className="justify-start px-5"
          >
            <Box className="w-[24px]">
              <IconFacebook />
            </Box>
            <span className="ml-2 normal-case line-clamp-1 font-normal text-start" style={{ marginLeft: 6 }}>
              <FormattedMessage id="Login_via_facebook" />
            </span>
          </Button>
          <Button
            variant="outlined"
            style={{
              marginBottom: 12,
              borderRadius: 26,
              width: 276,
              height: 48,
              display: "flex",
              alignItems: "center",
              background: "#3C3C3C",
              color: "white",
              border: "1px solid #3C3C3C"
            }}
            onClick={() => {
              auth.signInWithPopup(providerApple).then((result) => {
                auth.onAuthStateChanged((user) => {
                  user?.getIdToken()?.then((ele) => {
                    const credential = result.credential as OAuthCredential;
                    onSuccess && onSuccess();
                    dispatch(
                      commonActions.addFireBaseInfos({
                        accessToken: credential?.accessToken,
                        idToken: ele,
                        // pendingToken: credential?.pendingToken,
                        providerId: credential?.providerId,
                        signInMethod: credential?.signInMethod
                      })
                    );
                  });
                });
              });
            }}
            className="justify-start px-5"
          >
            <Box className="w-[24px]">
              <AppleIcon />
            </Box>
            <span className="ml-2 normal-case line-clamp-1 font-normal text-start pt-1" style={{ marginLeft: 6 }}>
              <FormattedMessage id="Login_to_apple" />
            </span>
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default React.memo(LoginSocial);
