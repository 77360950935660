/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import styled from "@mui/styles/styled";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { FormattedMessage } from "react-intl";

const CustomDialog = styled(Dialog)(({ theme }: any) => ({
  "& .MuiPaper-root": {
    width: 560,
    padding: "8px",
    borderRadius: "6px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));
type TProps = {
  setOpen: any;
  open: any;
  title: any;
  triggerFunc: Function;
  contentKey?: string;
  content?: any;
  isHideCancel?: boolean;
  isDisabledHandleClose?: boolean;
  styleContent?: {} | undefined;
  buttonOkKey?: any;
  cancelFunc?: Function;
  handleCloseModal?: Function;
  type?: "retry" | "";
  customButton?: any;
  notifyError?: boolean;
};
function AlertDialog({
  setOpen,
  open,
  title,
  triggerFunc,
  contentKey = "",
  content,
  isHideCancel,
  isDisabledHandleClose,
  styleContent = {},
  buttonOkKey,
  cancelFunc = () => {},
  handleCloseModal = () => {},
  type,
  customButton,
  notifyError = false,
}: TProps) {
  const handleClose = () => {
    setOpen(false);
    handleCloseModal();
  };
  const handleCancel = () => {
    setOpen(false);
    cancelFunc();
  }
  const handleOk = () => {
    setOpen(false);
    triggerFunc();
  };
  return (
    <CustomDialog open={open} onClose={() => !isDisabledHandleClose && handleClose}>
      <DialogTitle className="center-row">
        <span className="text-lg font-semibold">{title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="text-[15px]" style={styleContent}>
          {content ? (
            <span className="text-[15px]">{content}</span>
          ) : contentKey ? (
            <span>
              <FormattedMessage id={contentKey} />
            </span>
          ) : (
            <span>
              <FormattedMessage id="common.somethingWrong" />
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="center-row" style={{ width: "100%" }}>
        {!isHideCancel && (
          <Button onClick={handleCancel} sx={{ textTransform: "none" }}>
            <span className="text-green-gradient text-[13px]">
              <FormattedMessage id="common.cancel" />
            </span>
          </Button>
        )}
        {type === "retry" ? (
          <div className="flex w-[60%] mx-auto items-center justify-between">
            <Button
              type="button"
              className="border-none text-green-gradient bg-white px-2 py-1 hover:cursor-pointer normal-case text-[13px]"
              onClick={handleClose}
              sx={{ textTransform: "none" }}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              type="button"
              className="px-5 py-1.5 text-white bg-accept hover:cursor-pointer rounded-[100px] normal-case text-[13px]"
              onClick={handleOk}
              sx={{ textTransform: "none" }}
            >
              <FormattedMessage id="common.retry" />
            </Button>
          </div>
        ) : (
          <Button
            onClick={handleOk}
            style={{ width: 166, height: 40 }}
            className={`text-white rounded-[100px] normal-case text-[13px] ${
              notifyError ? "bg-red" : customButton || "bg-green-gradient"
            }`}
          >
            {buttonOkKey ? <FormattedMessage id={buttonOkKey} /> : <FormattedMessage id="common.ok" />}
          </Button>
        )}
      </DialogActions>
    </CustomDialog>
  );
}

export default React.memo(AlertDialog);
