import React from "react";

const DoubleRightIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83594 14.1654L9.66358 10.7352C10.1156 10.3301 10.1156 9.66726 9.66358 9.26217L5.83594 5.83203"
        stroke="#38B000"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8359 14.1654L14.6636 10.7352C15.1156 10.3301 15.1156 9.66726 14.6636 9.26217L10.8359 5.83203"
        stroke="#38B000"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleRightIcon;
