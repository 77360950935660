import React from "react";

export default function MobileAppStoreIcon() {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="7" fill="black" />
        <path
          d="M19.8144 16.2408C19.8317 14.896 20.554 13.6236 21.6997 12.9192C20.9769 11.8869 19.7663 11.2325 18.5067 11.1931C17.1633 11.052 15.861 11.9969 15.1764 11.9969C14.4786 11.9969 13.4246 11.2071 12.2896 11.2304C10.8102 11.2782 9.43104 12.1193 8.71128 13.4127C7.16408 16.0914 8.31815 20.0283 9.80024 22.1936C10.5418 23.2538 11.4084 24.4382 12.5423 24.3962C13.652 24.3502 14.0664 23.6886 15.4059 23.6886C16.7329 23.6886 17.1217 24.3962 18.2787 24.3695C19.4694 24.3502 20.2196 23.3045 20.9351 22.2342C21.4678 21.4787 21.8778 20.6437 22.1498 19.7602C20.7505 19.1684 19.816 17.7602 19.8144 16.2408Z"
          fill="white"
        />
        <path
          d="M17.6291 9.76906C18.2783 8.98969 18.5981 7.98795 18.5207 6.97656C17.5288 7.08074 16.6126 7.55479 15.9546 8.30425C15.3112 9.03652 14.9764 10.0206 15.0397 10.9934C16.0319 11.0036 17.0073 10.5424 17.6291 9.76906Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
