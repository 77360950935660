/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { memo, useEffect, useRef, useState } from "react";
import LazyLoad from "react-lazyload";
import Image from "next/image";
// import { Logo1 } from "../../../../assets";
import Logo1 from "../../../../public/assets/icons/Logo1";

const useStyles = makeStyles(() => ({
  imageWrapper: {
    height: "100%",
    position: "relative",
    "& .lazyload-wrapper": {
      height: "100%"
    }
  },
  placeholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    // backgroundColor: theme.palette.gray.grayLight22,
    opacity: 1,
    transition: "opacity 500ms",
    zIndex: 1
  },
  lazyLoadImage: {
    height: "100%"
  },
  imgContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    objectFit: "cover"
  },
  removePlaceholder: {
    opacity: 0,
    transition: "opacity 500ms"
  }
}));
interface IProps {
  className?: string;
  srcImage: string;
  styleSvg: any;
  styleImg: any;
}

function ImageComponent(props: IProps) {
  const { className, srcImage, styleSvg, styleImg } = props;
  const refPlaceholder: any = useRef();
  const [imgSrc, setImgSrc] = useState(srcImage);
  const [loadImgError, setLoadImgError] = useState(false);
  const classes = useStyles();
  const removePlaceholder = () => {
    refPlaceholder?.current?.classList?.add(classes.removePlaceholder);
  };
  const showErrorImage = () => {
    setLoadImgError(true);
  };
  useEffect(() => {
    setImgSrc(srcImage);
  }, [srcImage]);
  return (
    <>
      {loadImgError ||
        (!srcImage && (
          <div
            style={{
              width: styleSvg?.width || 110,
              height: styleSvg?.height || 75
            }}
          >
            <Logo1 width={styleSvg?.width} height={styleSvg?.height} />
          </div>
        ))}
      {imgSrc && (
        <div className={clsx(classes.imageWrapper, className)}>
          <LazyLoad>
            <Image
              style={{ ...styleImg, maxWidth: 134 }}
              src={imgSrc}
              // className={clsx(classes.imgContainer, className)}
              onLoad={removePlaceholder}
              onError={showErrorImage}
              // {...other}
              alt=""
            />
          </LazyLoad>
        </div>
      )}
    </>
  );
}

export default memo(ImageComponent);
