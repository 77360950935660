/* eslint-disable react/require-default-props */
import React from "react";

type TInfoIconProps = {
  size?: number;
  stroke?: string;
};

const InfoIcon = ({ size = 24, stroke = "#38B000" }: TInfoIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 11.25H12.0001L12 16.5H12.75"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.8125C12.5178 8.8125 12.9375 8.39277 12.9375 7.875C12.9375 7.35723 12.5178 6.9375 12 6.9375C11.4822 6.9375 11.0625 7.35723 11.0625 7.875C11.0625 8.39277 11.4822 8.8125 12 8.8125Z"
        fill={stroke}
      />
    </svg>
  );
};

export default InfoIcon;
