import ACTION_TYPES_SEARCH from "./actionTypes";

const handleGetTopicByTextSearch = (params) => {
  return {
    type: ACTION_TYPES_SEARCH.GET_TOPIC_BY_SEARCH,
    params
  };
};
const handleChooseTab = (params) => {
  return {
    type: ACTION_TYPES_SEARCH.CHOOSE_TAB,
    params
  };
};
const handleOpenModalConfirmJoinQuiz = (params) => {
  return {
    type: ACTION_TYPES_SEARCH.OPEN_MODAL_JOIN_QUIZ,
    params
  };
};

export const searchAction = {
  handleGetTopicByTextSearch,
  handleChooseTab,
  handleOpenModalConfirmJoinQuiz
};
