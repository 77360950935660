/* eslint-disable no-nested-ternary */
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from "lodash";
import Link from "next/link";
import React, { memo, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { TTheme } from "../../../utils/configs/setupTheme";
import { TCommonState } from "../utils/type";
import CustomSlider from "./CustomSlider";
import RoomCard from "./RoomCard";

const TAB = {
  ROOMS_JOINED: "join",
  EXAM_ROOMS: "exam"
};

type RoomsInfoBlockProps = {
  rooms: any[];
  subjects: any[];
};

const RoomsInfoBlock = ({ rooms, subjects }: RoomsInfoBlockProps) => {
  const theme: TTheme = useTheme();
  const intl = useIntl();
  const matches768 = useMediaQuery("(min-width:768px)");

  const { grade } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const [tab, setTab] = useState<string>(TAB.ROOMS_JOINED);

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  const examRooms = useMemo(() => {
    return rooms.filter((room) => room.data_filter === TAB.EXAM_ROOMS);
  }, [rooms]);

  const roomsRender = useMemo(() => {
    switch (tab) {
      case TAB.ROOMS_JOINED:
        return rooms;
      case TAB.EXAM_ROOMS:
        return examRooms;
      default:
        return [];
    }
  }, [tab, rooms, examRooms]);

  return (
    <Box className="flex flex-col gap-4">
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        sx={{
          height: 36,
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.main
          },
          "& .MuiTab-root": {
            color: theme.palette.primary.gray400
          },
          "& .MuiTab-root.Mui-selected": {
            color: theme.palette.primary.main
          }
        }}
      >
        <Tab
          label={intl.formatMessage({ id: "Account.myRoom.roomsJoined" })}
          className="normal-case text-[15px] font-semibold"
          value={TAB.ROOMS_JOINED}
        />
        <Tab
          label={intl.formatMessage({ id: "Account.myRoom.examRooms" })}
          className={`normal-case text-[15px] font-semibold ${!examRooms.length && "hidden"}`}
          value={TAB.EXAM_ROOMS}
        />
      </Tabs>
      <Box>
        <CustomSlider
          isDots={false}
          autoplay={false}
          className="w-full"
          centerMode={matches768 && roomsRender.length > 1}
        >
          {roomsRender
            ?.sort((a, b) => (a?.is_pinned === b?.is_pinned ? 0 : a?.is_pinned ? -1 : 1))
            .slice(0, 10)
            .map((room, index) => (
              <Link key={room?.room_hid} href={`/${grade}/thi-online/phong-thi/${room.room_hid}`}>
                <a
                  href={`/${grade}/thi-online/phong-thi/${room.room_hid}`}
                  className="inline-block w-full max-w-[360px] md:mx-1.5"
                >
                  <RoomCard
                    room={room}
                    roomsData={{ roomsRender, subjects }}
                    handleDirectDetail={() => {}}
                    index={index}
                    type="listRoom"
                  />
                </a>
              </Link>
            ))}
        </CustomSlider>
      </Box>
    </Box>
  );
};

const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.rooms, nextProps?.rooms) && isEqual(prevProps?.subjects, nextProps?.subjects);
};

export default memo(RoomsInfoBlock, checkEqualProps);
