import React from "react";

export default function IconSubject({ width, height, className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "14"}
      height={height || "16"}
      viewBox="0 0 14 16"
      fill="none"
      className={className}
    >
      <path
        d="M1 4.51724L7 1.03821L13 4.51724V11.4828L7 14.9618L1 11.4828V4.51724Z"
        stroke="#38B000"
        strokeWidth="2"
      />
    </svg>
  );
}
