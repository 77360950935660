import React from "react";

const ArrowRight = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.42188 16.5984L12.8552 11.1651C13.4969 10.5234 13.4969 9.47344 12.8552 8.83177L7.42188 3.39844"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
