import React from "react";

export default function IcMobilePhone({ className, stroke }: any) {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.4156 7.18056L8.8784 4.25199C8.5859 3.91451 8.04966 3.91601 7.70991 4.25649L5.62342 6.34661C5.00242 6.96833 4.82468 7.89152 5.18392 8.63173C7.33013 13.0752 10.9139 16.6638 15.3546 18.8161C16.0941 19.1753 17.0166 18.9976 17.6376 18.3759L19.7436 16.2663C20.0848 15.925 20.0856 15.3858 19.7451 15.0933L16.8051 12.5697C16.4976 12.3058 16.0199 12.3403 15.7116 12.6492L14.6886 13.6737C14.6362 13.7286 14.5673 13.7647 14.4924 13.7767C14.4175 13.7886 14.3407 13.7756 14.2739 13.7397C12.6017 12.7768 11.2146 11.388 10.2539 9.71466C10.2179 9.64773 10.2049 9.57084 10.2168 9.49579C10.2287 9.42074 10.2649 9.35168 10.3199 9.29919L11.3399 8.2785C11.6489 7.96802 11.6826 7.48805 11.4156 7.17981V7.18056Z"
				stroke={stroke || "#666666"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
