import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Image from "next/image";
import { firebaseRemoteShowBannerDirect } from "../../../firebase/Firebase";
import { commonActions } from "../redux/actions";
import { TCommonState } from "../utils/type";
import MobileAppStoreIcon from "../../../../public/assets/icons/MobileAppStoreIcon";
import GooglePlayIcon from "../../../../public/assets/icons/GooglePlayIcon";
import { LIST_ROUTER_DIRECT_APP } from "../utils/constant";

const DirectAppResponsive = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const showDirect = useSelector((state: { commonNew: TCommonState }) => state.commonNew.showDirect);
  const handleClose = () => {
    dispatch(commonActions.handleShowDirectApp(false));
  };
  useEffect(() => {
    firebaseRemoteShowBannerDirect();
  }, []);
  const handleDirectAppStore = () => {
    router.push("https://apps.apple.com/vn/app/id1579917080");
  };
  const handleDirectCHPlay = () => {
    router.push("https://play.google.com/store/apps/details?id=vn.qsoft.chinhxac");
  };
  return showDirect && LIST_ROUTER_DIRECT_APP.includes(router.pathname) ? (
    <div className="flex items-center gap-4 justify-between w-full">
      <div className="flex items-center flex-row gap-4">
        <div className="pl-1">
          <button type="button" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="flex flex-row items-center gap-4">
          <Image
            src="http://feature-api.chinhxac.vn/static/images/favicon.ico"
            alt="Logo Thi Tốt"
            width={30}
            height={30}
          />
          <div className="flex flex-col text-sm py-1">
            <span className="font-semibold">
              <FormattedMessage id="banner.responsive" />
            </span>
            <span>
              <FormattedMessage id="banner.responsive.content" />
            </span>
          </div>
        </div>
      </div>
      <div className="pr-4 flex items-center gap-2">
        <button type="button" onClick={handleDirectCHPlay}>
          <GooglePlayIcon />
        </button>
        <button type="button" onClick={handleDirectAppStore}>
          <MobileAppStoreIcon />
        </button>
      </div>
    </div>
  ) : null;
};
export default DirectAppResponsive;
