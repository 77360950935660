/* eslint-disable react/require-default-props */
import { Box, Typography, useTheme } from "@mui/material";
import { isEqual } from "lodash";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import IconDiamond from "../../../../public/assets/icons/IconDiamond";
import Star from "../../../../public/assets/icons/Star";
import { TTheme } from "../../../utils/configs/setupTheme";
import { DownArrow16 } from "../../statistic/assets/icons/DownArrow";
import { UpArrow16 } from "../../statistic/assets/icons/UpArrow";

type DailyInfoBlockProps = {
  title: string;
  expPoint?: number;
  diamondHistory?: { total_spent: number; total_received: number };
  starHistory?: { total_spent: number; total_received: number };
};

const DailyInfoBlock = ({ title, expPoint, diamondHistory, starHistory }: DailyInfoBlockProps) => {
  const theme: TTheme = useTheme();

  return (
    <Box className="w-full">
      <Typography className="text-[18px] font-semibold text-black mb-[24px]">{title}</Typography>
      <Typography className="flex items-center text-[15px] gap-2 mb-[12px]">
        <FormattedMessage id="Account.exp_point_no_value" />:
        <Box className="flex items-center gap-1">
          <UpArrow16 stroke={theme.palette.primary.green1} />
          {expPoint || 0}
        </Box>
      </Typography>
      <Typography className="flex items-center text-[15px] gap-2 mb-[12px]">
        <FormattedMessage id="Account.diamond" />:
        <Box className="flex items-center gap-1">
          <DownArrow16 stroke={theme.palette.primary.red} />
          {diamondHistory?.total_spent || 0}
          <IconDiamond width={16} height={16} className="svgFillAll" fill={theme.palette.primary.main} />
        </Box>
        <Box className="flex items-center gap-1">
          <UpArrow16 stroke={theme.palette.primary.green1} />
          {diamondHistory?.total_received || 0}
          <IconDiamond width={16} height={16} className="svgFillAll" fill={theme.palette.primary.main} />
        </Box>
      </Typography>
      <Typography className="flex items-center text-[15px] gap-2">
        <FormattedMessage id="Account.star" />:
        <Box className="flex items-center gap-1">
          <DownArrow16 stroke={theme.palette.primary.red} />
          {starHistory?.total_spent || 0}
          <Star />
        </Box>
        <Box className="flex items-center gap-1">
          <UpArrow16 stroke={theme.palette.primary.green1} />
          {starHistory?.total_received || 0}
          <Star />
        </Box>
      </Typography>
    </Box>
  );
};

const checkEqualProps = (prevProps, nextProps) => {
  return (
    isEqual(prevProps?.expPoint, nextProps?.expPoint) &&
    isEqual(prevProps?.diamondHistory, nextProps?.diamondHistory) &&
    isEqual(prevProps?.starHistory, nextProps?.starHistory)
  );
};

export default memo(DailyInfoBlock, checkEqualProps);
