import React from "react";

const RadioIconChecked = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="10" cy="10" r="9.5" stroke="#0075FF" />
			<circle cx="10" cy="10" r="6" fill="#0075FF" />
		</svg>
	);
};
export default RadioIconChecked;
