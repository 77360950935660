/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import BoderDiamond from "../../../../public/assets/icons/BoderDiamond";
import CXImage from "./Image";
import useDynamicSVGImport from "./useDynamicSVGImport";

interface IProps {
  fileName?: string;
  netPhoto: any;
  onCompleted?: Function;
  onError?: Function;
  styleSvg?: any;
  [x: string]: any;
  id?: string;
}
function LoadingSvg({
  fileName = "",
  netPhoto,
  onCompleted,
  onError,
  styleSvg,
  styleImg,
  id,
  widthImage = 42,
  heightImage = 42,
  borderDiamondClassName = ""
}: IProps) {
  const { error, loading, SvgIcon } = useDynamicSVGImport(
    fileName
      ?.split("/")[3]
      ?.split(fileName?.includes("_lop_") ? "_lop_" : "_on_thi_")[0]
      .split(".png")[0]
      ?.replace("_global_success", ""),
    {
      onCompleted,
      onError
    }
  );

  if (error || !(netPhoto || fileName)) {
    return <CXImage srcImage={netPhoto} styleSvg={styleSvg} styleImg={styleImg} />;
  }
  if (loading) {
    return <Typography>...</Typography>;
  }
  
  if (SvgIcon) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" id={id}>
        <img
          alt=""
          src={netPhoto || `/assets/images/${SvgIcon}.webp`}
          width={widthImage}
          height={heightImage}
          style={{
            objectFit: "contain",
            position: "absolute"
          }}
          onError={(ev: any) => {            
            ev.target.src = "/assets/images/notification_logo.webp";
            ev.onerror = null;
          }}
          // layout="fill"
        />
        <BoderDiamond className={borderDiamondClassName} />
      </Box>
    );
  }
  return null;
}
export default LoadingSvg;
