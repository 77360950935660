/* eslint-disable react/jsx-filename-extension */
import { Box, Divider } from "@mui/material";
import { useRouter } from "next/router";
// import { Link } from 'react-router-dom';
import Link from "next/link";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { SHOW_MAXIMUM_EXAMS } from "../utils/constant";
import { ExamsCard } from "./Elements";

function Exams({ examsList }) {
  const router = useRouter();

  const examsListRender = useMemo(() => {
    return examsList?.slice(0, SHOW_MAXIMUM_EXAMS);
  }, [examsList]);

  return (
    <Box className="box-cover-left md:w-[250px] lg:w-[298px] mb-3">
      <Box
        style={{
          display: "flex"
        }}
        className="title-sub"
        id="left_side_exams_title"
      >
        <FormattedMessage id="Profile.exerciseDone" />
      </Box>
      <Box style={{ marginTop: 12, width: "100%" }} display="flex" flexDirection="column">
        {examsListRender.map((ele, index) => (
          <Link
            key={`${index}`}
            style={{ textDecoration: "unset", color: "black" }}
            href={ele.direct_link.replace("/lop/", "/")}
            onClick={() =>
              eventTracking(
                "select_home_done_lesson",
                { id: ele?.subject_id, title: ele?.title, grade_slug: ele?.grade_slug },
                () => router.push(ele?.direct_link?.replace("/lop/", "/"))
              )
            }
            id="left_side_exam_item"
          >
            <a href={ele?.direct_link?.replace("/lop/", "/")} target="_blank" rel="noreferrer">
              <ExamsCard ele={ele} />
              {examsListRender.length - 1 !== index && <Divider className="my-3" />}
            </a>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default React.memo(Exams);
