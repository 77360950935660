/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React from "react";
import Box from "@mui/system/Box";
import { useRouter } from "next/router";
import { domainUrl, facebookLink, youtubeLink } from "../../../../utils/constants/env";
import { appName } from "../../../../utils/constants/constants";

const FacebookElement = React.memo(
  ({ width = "298", height = "168", className }: { width?: string; height?: string; className?: string }) => {
    return (
      <Box className={`${className} rounded-[12px]`} id="right_side_block_facebook">
        <iframe
          title={appName}
          src={facebookLink}
          width={width || "298"}
          height={height || "166"}
          style={{
            border: "none",
            overflow: "hidden",
            borderRadius: "6px",
            backgroundColor: "white"
          }}
          allowFullScreen
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      </Box>
    );
  }
);

const YoutubeElement = React.memo(() => {
  return (
    <Box className="mt-4" id="right_side_block_youtube">
      <iframe
        src={youtubeLink}
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={appName}
        style={{
          width: "298px",
          height: "166px",
          borderRadius: 6,
          background: "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))"
        }}
      />
    </Box>
  );
});

const FacebookShareLike = React.memo(() => {
  const router = useRouter();
  return (
    <iframe
      src={`https://www.facebook.com/plugins/like.php?href=${domainUrl}/${router?.asPath}&width=130&layout&action&size&share=true&height=35&appId=376426464244409`}
      width="400"
      height="35"
      data-layout="button_count"
      style={{
        border: "none",
        overflow: "hidden"
      }}
      allowFullScreen
      data-kid-directed-site
      data-size="large"
      title="Facebook like share"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />
  );
});

export { FacebookElement, YoutubeElement, FacebookShareLike };
