import React from "react";

export default function UserCircleGear() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5526 11.4411C10.8459 11.4411 11.8943 10.3927 11.8943 9.09948C11.8943 7.80621 10.8459 6.75781 9.5526 6.75781C8.25934 6.75781 7.21094 7.80621 7.21094 9.09948C7.21094 10.3927 8.25934 11.4411 9.5526 11.4411Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8766 16.8336C13.8766 14.892 11.9432 13.3086 9.55156 13.3086C7.15989 13.3086 5.22656 14.8836 5.22656 16.8336"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4974 10.4167C17.4974 14.7917 13.9557 18.3333 9.58073 18.3333C5.20573 18.3333 1.66406 14.7917 1.66406 10.4167C1.66406 6.04167 5.20573 2.5 9.58073 2.5C10.6724 2.5 11.7141 2.71666 12.6641 3.11666C12.5557 3.44999 12.4974 3.8 12.4974 4.16667C12.4974 4.79167 12.6724 5.38333 12.9807 5.88333C13.1474 6.16666 13.3641 6.42498 13.6141 6.64164C14.1974 7.17498 14.9724 7.5 15.8307 7.5C16.1974 7.5 16.5474 7.44165 16.8724 7.32498C17.2724 8.27498 17.4974 9.325 17.4974 10.4167Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1667 4.16536C19.1667 4.43203 19.1333 4.69036 19.0667 4.94036C18.9917 5.27369 18.8583 5.5987 18.6833 5.88203C18.2833 6.55703 17.6417 7.08201 16.875 7.32368C16.55 7.44035 16.2 7.4987 15.8333 7.4987C14.975 7.4987 14.2 7.17367 13.6167 6.64034C13.3667 6.42367 13.15 6.16536 12.9833 5.88203C12.675 5.38203 12.5 4.79036 12.5 4.16536C12.5 3.7987 12.5583 3.44869 12.6667 3.11536C12.825 2.63202 13.0917 2.19872 13.4417 1.84038C14.05 1.21538 14.9 0.832031 15.8333 0.832031C16.8167 0.832031 17.7083 1.25705 18.3083 1.94038C18.8417 2.53205 19.1667 3.31536 19.1667 4.16536Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.974 5.58073C16.6931 5.58073 17.276 4.99777 17.276 4.27865C17.276 3.55953 16.6931 2.97656 15.974 2.97656C15.2548 2.97656 14.6719 3.55953 14.6719 4.27865C14.6719 4.99777 15.2548 5.58073 15.974 5.58073Z"
        stroke="#666666"
        strokeWidth="1.5625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.9688 2.97656V2" stroke="#666666" strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.8457 3.62891L14 3.14062"
        stroke="#666666"
        strokeWidth="1.5625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8457 4.92969L14 5.41797"
        stroke="#666666"
        strokeWidth="1.5625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9688 5.58203V6.55859"
        stroke="#666666"
        strokeWidth="1.5625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1016 4.92969L17.9473 5.41797"
        stroke="#666666"
        strokeWidth="1.5625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1016 3.62891L17.9473 3.14062"
        stroke="#666666"
        strokeWidth="1.5625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
