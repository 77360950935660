import React from "react";

function IconAnonymous({ width, height, className, id }: any) {
  return (
    <svg
      width={width || "40"}
      height={height || "40"}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <circle cx="36" cy="36" r="34.5" fill="#E9E9E9" stroke="white" strokeWidth="3" />
      <path
        d="M36 18C40.9725 18 45 22.0275 45 27C45 31.9725 40.9725 36 36 36C31.0275 36 27 31.9725 27 27C27 22.0275 31.0275 18 36 18ZM36 54C36 54 54 54 54 49.5C54 44.1 45.225 38.25 36 38.25C26.775 38.25 18 44.1 18 49.5C18 54 36 54 36 54Z"
        fill="#9D9D9D"
      />
    </svg>
  );
}
export default React.memo(IconAnonymous);
