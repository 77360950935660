import React from "react";

export default function PencilIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0379 3.02123L8.15793 10.9012C7.85793 11.2012 7.55793 11.7912 7.49793 12.2212L7.06793 15.2312C6.90793 16.3212 7.67793 17.0812 8.76793 16.9312L11.7779 16.5012C12.1979 16.4412 12.7879 16.1412 13.0979 15.8412L20.9779 7.96123C22.3379 6.60123 22.9779 5.02123 20.9779 3.02123C18.9779 1.02123 17.3979 1.66123 16.0379 3.02123Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9062 4.14844C15.5763 6.53844 17.4463 8.40844 19.8463 9.08844"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
