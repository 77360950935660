/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/styles/useTheme";
import moment from "moment";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Logo1 from "../../../../../public/assets/icons/Logo1";
import { TTheme } from "../../../../utils/configs/setupTheme";
import { genSignature, getNonce } from "../../../../utils/helpers/helpers";
import { accountActions } from "../../../account/redux/actions";
import { commonActions } from "../../../common/redux/actions";
import { LIST_CLASSES, NUMBER_OF_CLASS_AVAILABLE } from "../constants";
import { TCommonState } from "../../../common/utils/type";
import { appName } from "../../../../utils/constants/constants";

const linkStyle = {
  margin: 10,
  textDecoration: "none",
  color: "white",
  fontWeight: 600
};
const AppLink = ({ xs, matches770 }: any) => {
  const theme: TTheme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();

  const classList = LIST_CLASSES.slice(0, NUMBER_OF_CLASS_AVAILABLE).reverse();

  const { userInfos } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);

  const handleUpdateGrade = (gradeVal) => {
    const dataUpdateProfilePre: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade_slug: gradeVal
    };
    const dataUpdateProfile = {
      ...dataUpdateProfilePre,
      signature: genSignature(dataUpdateProfilePre)
    };
    dispatch(
      accountActions.handleUpdateInfoAccount(dataUpdateProfile, () => {
        dispatch(accountActions.handleClearNotify());
      })
    );
  };

  return (
    <Grid
      item
      xs={xs}
      style={{
        paddingLeft: matches770 ? 6 : 0,
        marginTop: matches770 ? 0 : 10
      }}
      className="sm:mb-[25px] lg:mb-[0] sm:max-w-full"
    >
      <Box className="flex sm:flex-col md:flex-col sm:justify-between lg:justify-start gap-2">
        <Box display="flex" alignItems="center" marginBottom="15px">
          <Logo1 />
          <span
            style={{
              marginLeft: 8,
              fontSize: 33,
              fontWeight: 700,
              lineHeight: "43.65px",
              color: theme.palette.primary.main
            }}
          >
            {appName}
          </span>
        </Box>
      </Box>
      <Box className="flex flex-wrap gap-x-8 gap-y-2 mt-4">
        {classList.map((classItem) => (
          <Box key={classItem.slug}>
            <Button
              className="normal-case hover:underline decoration-gray9"
              onClick={() => {
                dispatch(commonActions.handleChooseGrade(classItem.slug));
                if (userInfos?.member) {
                  handleUpdateGrade(classItem.slug);
                }
                router.push("/");
              }}
            >
              <Typography className="font-semibold text-gray9 text-[15px]">{classItem.name}</Typography>
            </Button>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};
const ConnectWithUs = ({ xs, matches1100, matches770 }: any) => {
  const intl = useIntl();
  return (
    <Grid item xs={xs} className="sm:mb-[25px] lg:mb-[0] text-[15px] sm:max-w-full">
      <Box display="flex" flexDirection="column">
        <span style={{ fontWeight: "bold", marginBottom: "15px" }} className="sm:mb-[10px]">
          <FormattedMessage id="Contact_with_us" />
        </span>
        <span
          style={{ marginBottom: "15px" }}
          className="sm:mb-[10px]"
          id={intl.formatMessage({ id: "id_footer_hot_line" })}
        >
          <FormattedMessage id="Hot_line" />
        </span>
        <span
          style={{ marginBottom: "15px" }}
          className="sm:mb-[10px]"
          id={intl.formatMessage({ id: "id_footer_contact_note" })}
        >
          <FormattedMessage id="Contact_note_1" />
        </span>
        <span id={intl.formatMessage({ id: "id_footer_email" })}>
          <FormattedMessage id="Contact_note_2" />
        </span>
      </Box>
      <Box className="mt-4">
        <Typography variant="h6" style={{ marginBottom: 5, fontWeight: "600", fontSize: "18px" }}>
          <FormattedMessage id="Download_app" />
        </Typography>
        <Box className="flex sm:flex-row md:flex-row lg:flex-row gap-2">
          <Link href="https://play.google.com/store/apps/details?id=vn.qsoft.chinhxac">
            <a target="_blank" id={intl.formatMessage({ id: "id_footer_app_google_play" })}>
              <Image
                src="/assets/images/GooglePlay.webp"
                alt="google play"
                width={matches1100 ? 135 : matches770 ? 125 : 100}
                height={matches1100 ? 45 : matches770 ? 42 : 34}
              />
            </a>
          </Link>
          <Link
            href="https://apps.apple.com/vn/app/id1579917080"
            style={{
              marginLeft: matches1100 ? 16 : 0,
              marginTop: !matches1100 ? (matches770 ? 8 : 0) : 0
            }}
          >
            <a target="_blank" id={intl.formatMessage({ id: "id_footer_app_store" })}>
              <Image
                src="/assets/images/MobileAppStore.webp"
                alt="apple store"
                width={matches1100 ? 135 : matches770 ? 125 : 100}
                height={matches1100 ? 45 : matches770 ? 42 : 34}
              />
            </a>
          </Link>
        </Box>
      </Box>
    </Grid>
  );
};
const CompanyInfo = ({ xs }: any) => {
  const intl = useIntl();
  return (
    <Grid item xs={xs} className="md:mt-[15px] lg:mt-[0] lg:ml-[0] text-[15px] md:ml-[6px]">
      <Box display="flex" flexDirection="column">
        <span
          style={{ marginBottom: "15px" }}
          className="sm:mb-[10px]"
          id={intl.formatMessage({ id: "id_footer_compay_name" })}
        >
          <FormattedMessage id="Compay_name" />
        </span>
        <span
          style={{ marginBottom: "15px" }}
          className="sm:mb-[10px]"
          id={intl.formatMessage({ id: "id_footer_business_license" })}
        >
          <FormattedMessage id="GPKD" />
        </span>
        <span
          style={{ marginBottom: "15px" }}
          className="sm:mb-[10px]"
          id={intl.formatMessage({ id: "id_footer_address" })}
        >
          <FormattedMessage id="Address" />
        </span>
      </Box>
      <a href="http://online.gov.vn/Home/WebDetails/97400" id={intl.formatMessage({ id: "id_footer_img_BCT" })}>
        <Image src="/assets/images/logoSaleNoti.webp" width={124} height={50} alt="bộ công thương" />
      </a>
    </Grid>
  );
};
const Policy = ({ xs }) => {
  const intl = useIntl();
  return (
    <Grid item xs={xs} className="md:flex md:justify-around lg:block sm:mb-[25px] lg:mb-[0] text-[15px] sm:max-w-full">
      <div className="flex flex-col text-gray9">
        <span style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <Link href="/hinh-thuc-thanh-toan">
            <a className="cursor-pointe hover:underline" id={intl.formatMessage({ id: "id_footer_policy_payments" })}>
              <FormattedMessage id="footer.policy.payments" />
            </a>
          </Link>
        </span>
        <div style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <Link href="/chinh-sach-doi-tra">
            <a className="cursor-pointe hover:underline" id={intl.formatMessage({ id: "id_footer_policy_refund" })}>
              <FormattedMessage id="footer.policy.refund" />
            </a>
          </Link>
        </div>
        <div style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <Link href="/dieu-khoan-su-dung" style={linkStyle}>
            <a className="cursor-pointe hover:underline" id={intl.formatMessage({ id: "id_footer_policy_termsofuse" })}>
              <FormattedMessage id="footer.policy.termsofuse" />
            </a>
          </Link>
        </div>
        <div style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <Link
            href="/chinh-sach-bao-mat"
            style={linkStyle}
            // onClick={() => window.scrollTo(0, 0)}
          >
            <a className="cursor-pointe hover:underline" id={intl.formatMessage({ id: "id_footer_policy_privacy" })}>
              <FormattedMessage id="footer.policy.privacy" />
            </a>
          </Link>
        </div>
        <div>
          <Link
            href="/phuong-thuc-giao-nhan"
            style={linkStyle}
            // onClick={() => window.scrollTo(0, 0)}
          >
            <a className="cursor-pointer hover:underline" id={intl.formatMessage({ id: "id_footer_delivery_method" })}>
              <FormattedMessage id="footer.delivery.method" />
            </a>
          </Link>
        </div>
      </div>
    </Grid>
  );
};

function DefaultFooter() {
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const matches770 = useMediaQuery("(min-width:770px)");
  const matches412 = useMediaQuery("(max-width:412px)");
  return (
    <footer className="z-[49] font-footer">
      <Box
        style={{
          height: "max-content",
          background: "#FFFFFF",
          color: "#273148",
          padding: matches1100 ? "30px 27px 15px" : matches412 ? "20px 16px" : "30px 16px"
        }}
      >
        {matches1100 ? (
          <Grid container>
            <AppLink xs={3.5} matches770={matches770} />
            <ConnectWithUs xs={2.5} matches1100={matches1100} matches770={matches770} />
            <Policy xs={2} />
            <CompanyInfo xs={4} />
          </Grid>
        ) : matches412 ? (
          <Grid container>
            <AppLink xs={12} matches1100={matches1100} matches770={matches770} />
            <ConnectWithUs xs={12} />
            <Policy xs={12} />
            <CompanyInfo xs={12} />
          </Grid>
        ) : (
          <Grid container>
            <div className="flex w-full md:flex-row sm:flex-col">
              <AppLink xs={5} matches1100={matches1100} matches770={matches770} />
              <ConnectWithUs xs={3.5} />
              <Policy xs={3.5} />
            </div>
            <CompanyInfo xs={12} />
          </Grid>
        )}
      </Box>
      <span className="lg:px-[33px] md:px-[23px] sm:px-[16px] block bg-[white]">
        <Divider className="bg-GRAY1 h-[1px]" />
      </span>

      <div className="text-center bg-white text-[12px] text-black py-[15px] lg:px-[33px] md:px-[23px] sm:px-[16px] flex justify-between sm:flex-col sm:text-left md:flex-row">
        <span className="sm:mb-[10px] lg:mb-[0] text-gray9">
          <FormattedMessage id="Foot_title" />
        </span>
        <span className="flex justify-center text-gray9 sm:justify-start">
          <FormattedMessage id="Coppy_right" />
        </span>
      </div>
    </footer>
  );
}

export default React.memo(DefaultFooter);
