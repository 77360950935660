/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-filename-extension */
import { Button, Typography, useTheme } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import IcCalendar from "../../../../public/assets/icons/IcCalendar";
import IcEmail from "../../../../public/assets/icons/IcEmail";
import IcMobilePhone from "../../../../public/assets/icons/IcMobilePhone";
import IcNickName from "../../../../public/assets/icons/IcNickName";
import IcSex from "../../../../public/assets/icons/IcSex";
import UserCircle2 from "../../../../public/assets/icons/UserCircle2";
import { accountServices } from "../../account/redux/services";
import { isEmpty } from "../../../utils/constants/constants";
import { getNonce, genSignature } from "../../../utils/helpers/helpers";
import { TCommonState, TDataUserResponse, TMember } from "../utils/type";
import AlertDialog from "./AlertDialog";
import { commonActions } from "../redux/actions";
import { TTheme } from "../../../utils/configs/setupTheme";

// eslint-disable-next-line
const Alert = React.forwardRef(function Alert(props: AlertProps, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(() => ({
  userInfoCard: {
    display: "flex",
    alignItems: "center",
    width: "180px"
  },
  userInfoCardRow: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& .MuiTextField-root": { width: "100%" },
    marginBottom: 24
  }
}));
interface IProps {
  onClose: Function;
  open: boolean;
  getDataUser: () => void;
  setError: Function;
}
function UserInfoDialog(props: IProps) {
  const { onClose, open, getDataUser } = props;
  const dataUserInfos: TDataUserResponse = useSelector(
    (state: { commonNew: TCommonState }) => state.commonNew.userInfos
  );
  const classes = useStyles();
  const theme: TTheme = useTheme();
  const [dataUser, setDataUser] = React.useState<TMember>(dataUserInfos?.member);
  const [openModalError, setOpenModalError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>({});
  const [errorText, setErrorText] = React.useState<any>({});
  const dispatch = useDispatch();
  const handleChange = (event: any) => {
    setDataUser({
      ...dataUser,
      gender: event.target.value
    });
  };
  const handleSubmit = async () => {
    try {
      const paramsPre: any = {
        gender: dataUserInfos?.member?.gender !== dataUser?.gender ? dataUser?.gender : dataUserInfos?.member?.gender,
        birthday:
          dataUser?.birthday && dataUserInfos?.member?.birthday !== dataUser?.birthday
            ? dataUser?.birthday
            : dataUserInfos?.member?.birthday,
        phone: dataUserInfos?.member?.phone !== dataUser?.phone ? dataUser?.phone?.trim() : undefined,
        name: dataUserInfos?.member?.name !== dataUser?.name ? dataUser?.name?.trim() : undefined,
        nickname: dataUserInfos?.member?.nickname !== dataUser?.nickname ? dataUser?.nickname?.trim() : undefined,
        email: dataUserInfos?.member?.email !== dataUser?.email ? dataUser?.email?.trim() : undefined,
        timestamp: moment().unix(),
        nonce: getNonce()
      };
      const tempParams: any = {};
      Object.keys(paramsPre).forEach((key) => {
        if (paramsPre[key] !== undefined) {
          tempParams[key] = paramsPre[key];
        }
      });
      if (isEmpty(tempParams)) return;
      const dataPut = {
        ...tempParams,
        signature: genSignature(tempParams)
      };
      const res = await accountServices.getProfile({}, dataPut);
      if (res?.data?.data) {
        dispatch(
          commonActions.handleSaveNotify({
            isOpenNotify: true,
            message: res?.data?.data?.success_message
          })
        );
        onClose(false);
        getDataUser();
      }
    } catch (err: any) {
      setOpenModalError(true);
      setError(err?.data?.error);
    }
  };
  useEffect(() => {
    if (dataUserInfos) {
      setDataUser(dataUserInfos?.member);
      setErrorText("");
    }
  }, [dataUserInfos]);

  return (
    <Dialog open={open} maxWidth="xl">
      <Box className="box_cover sm:px-1 md:w-[580px] sm:w-[90vw] md:px-[92px] md:py-[32px]">
        <span className="md:text-[24px] sm:text-[20px] text-center">
          <FormattedMessage id="IDS_Edit_User_info" />
        </span>
        <Box className="center-col sm:w-[85vw] md:w-full sm:mt-4 md:mt-8">
          <Box className={classes.userInfoCardRow}>
            <Box className={classes.userInfoCard}>
              <UserCircle2 />
              <Typography variant="body1" style={{ marginLeft: 8 }}>
                <FormattedMessage id="Account.name" /> *
              </Typography>
            </Box>
            <TextField
              size="small"
              value={dataUser?.name || ""}
              onChange={(event) => {
                if (event.target.value.length < 60) {
                  setDataUser({
                    ...dataUser,
                    name: event.target.value
                  });
                  setErrorText({
                    ...errorText,
                    name: undefined
                  });
                } else {
                  setErrorText({
                    ...errorText,
                    name: "Tên là trường bắt buộc và có giới hạn nhỏ hơn 64 ký tự"
                  });
                }
              }}
              error={errorText?.name && errorText?.name?.length !== 0}
              helperText={errorText?.name?.length === 0 ? undefined : errorText?.name}
            />
          </Box>

          <Box className={classes.userInfoCardRow}>
            <Box className={classes.userInfoCard}>
              <IcNickName />
              <Typography variant="body1" style={{ marginLeft: 8 }}>
                <FormattedMessage id="Account.nickname" />
              </Typography>
            </Box>
            <TextField
              size="small"
              value={dataUser?.nickname || ""}
              onChange={(event) => {
                if (event.target.value.length < 60) {
                  setDataUser({
                    ...dataUser,
                    nickname: event.target.value
                  });
                }
              }}
              defaultValue={dataUser?.nickname}
              error={errorText?.nickname && errorText?.nickname?.length !== 0}
              helperText={errorText?.nickname?.length === 0 ? undefined : errorText?.nickname}
            />
          </Box>

          <Box className={classes.userInfoCardRow}>
            <Box className={classes.userInfoCard}>
              <IcCalendar />
              <Typography variant="body1" style={{ marginLeft: 8 }}>
                <FormattedMessage id="Account.dateOfBirth" />
              </Typography>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disableFuture
                renderInput={(prop) => <TextField size="small" {...prop} />}
                value={
                  dataUser?.birthday !== undefined
                    ? new Date(moment(dataUser.birthday, "YYYYMMDD").format("yy-MM-DD"))
                    : null
                }
                onChange={(newValue) => {
                  setDataUser({
                    ...dataUser,
                    birthday: moment(newValue).format("YYYYMMDD")
                  });
                }}
                inputFormat="dd/MM/yyyy"
                className="w-full"
              />
            </LocalizationProvider>
          </Box>
          <Box className={classes.userInfoCardRow}>
            <Box display="flex" alignItems="center" width="180px">
              <IcSex />
              <Typography variant="body1" style={{ marginLeft: 8 }}>
                <FormattedMessage id="Account.gender" />
              </Typography>
            </Box>
            <FormControl sx={{ minWidth: 120, width: "100%" }} size="small">
              <Select
                value={dataUser?.gender}
                onChange={handleChange}
                MenuProps={{
                  sx: {
                    "&& .Mui-selected": {
                      backgroundColor: theme.palette.primary.light
                    },
                    "&& .Mui-selected[aria-selected=true]": {
                      backgroundColor: theme.palette.primary.light
                    }
                  }
                }}
              >
                <MenuItem value={1} className={dataUser?.gender === 1 ? "" : "hover:bg-green/10 duration-200"}>
                  <FormattedMessage id="Genger_option.male" />
                </MenuItem>
                <MenuItem value={0} className={dataUser?.gender === 0 ? "" : "hover:bg-green/10 duration-200"}>
                  <FormattedMessage id="Genger_option.female" />
                </MenuItem>
                <MenuItem value={-1} className={dataUser?.gender === -1 ? "" : "hover:bg-green/10 duration-200"}>
                  <FormattedMessage id="Genger_option.undisclosed" />
                </MenuItem>
                <MenuItem value={2} className={dataUser?.gender === 2 ? "" : "hover:bg-green/10 duration-200"}>
                  <FormattedMessage id="Genger_option.other" />
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box className={classes.userInfoCardRow}>
            <Box className={classes.userInfoCard}>
              <IcMobilePhone />
              <Typography variant="body1" style={{ marginLeft: 8 }}>
                <FormattedMessage id="Account.phone" />
              </Typography>
            </Box>
            <TextField
              size="small"
              value={dataUser?.phone}
              onChange={(event) => {
                if (event.target.value.length < 13) {
                  setDataUser({
                    ...dataUser,
                    phone: event.target.value
                  });
                }
              }}
              error={errorText?.phone && errorText?.phone?.length !== 0}
              helperText={errorText?.phone?.length === 0 ? undefined : errorText?.phone}
            />
          </Box>

          <Box className={classes.userInfoCardRow}>
            <Box className={classes.userInfoCard}>
              <IcEmail />
              <Typography variant="body1" style={{ marginLeft: 8 }}>
                Email
              </Typography>
            </Box>
            <TextField
              size="small"
              value={dataUser?.email}
              onChange={(event) => {
                if (event.target.value.match("/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/")) {
                  setDataUser({
                    ...dataUser,
                    email: event.target.value
                  });
                }
              }}
              error={errorText?.email && errorText?.email?.length !== 0}
              helperText={errorText?.email?.length === 0 ? undefined : errorText?.email}
            />
          </Box>
        </Box>
        <Typography variant="caption" style={{ color: "#B00020" }}>
          (*) <FormattedMessage id="IDS_Mandatory" />
        </Typography>
        <Box display="flex" justifyContent="center" mt={1}>
          <Button
            disableFocusRipple
            onClick={() => {
              if (isEmpty(dataUser?.name)) {
                setErrorText({
                  ...errorText,
                  name: "Tên là trường bắt buộc và có giới hạn nhỏ hơn 64 ký tự"
                });
                return;
              }
              handleSubmit();
            }}
            autoFocus
            style={{ width: 166, height: 44 }}
            className="rounded-[32px] px-[8px] py-[4px] w-[166px] h-[44px] normal-case bg-green-gradient text-white"
          >
            <FormattedMessage id="IDS_Update" />
          </Button>
        </Box>
      </Box>
      {openModalError && (
        <AlertDialog
          open={openModalError}
          setOpen={setOpenModalError}
          title="Thông báo"
          content={`${error?.message}(${error?.code})`}
          triggerFunc={() => {
            setOpenModalError(false);
            setErrorText("");
            setError({});
          }}
          contentKey="common.note"
          isHideCancel
          buttonOkKey=""
        />
      )}
    </Dialog>
  );
}

export default UserInfoDialog;
