import { Box, Typography } from "@mui/material";
import { isEqual } from "lodash";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { TDailyReport } from "../utils/type";

type LearningInfoBlockProps = {
  statisticLearning: TDailyReport["statistic_learning"];
};

const LearningInfoBlock = ({ statisticLearning }: LearningInfoBlockProps) => {
  return (
    <Box className="flex flex-col gap-3 text-black2">
      <Typography className="text-[19px] font-semibold">
        <FormattedMessage id="Account.study" />
      </Typography>
      <Box className="flex justify-around py-3 bg-yellow5">
        <Box className="flex flex-col items-center gap-2">
          <Typography className="font-semibold text-[19px]">+{statisticLearning?.num_of_exam || 0}</Typography>
          <Typography className="text-[15px]">
            <FormattedMessage id="Account.statistic.exam" />
          </Typography>
        </Box>
        <Box className="flex flex-col items-center gap-2">
          <Typography className="font-semibold text-[19px]">+{statisticLearning?.num_of_practice || 0}</Typography>
          <Typography className="text-[15px]">
            <FormattedMessage id="Account.statistic.exercise" />
          </Typography>
        </Box>
        <Box className="flex flex-col items-center gap-2">
          <Typography className="font-semibold text-[19px]">+{statisticLearning?.num_of_room || 0}</Typography>
          <Typography className="text-[15px]">
            <FormattedMessage id="Account.statistic.examinationRoom" />
          </Typography>
        </Box>
      </Box>
      <Box className="flex">
        <Typography className="sm:w-2/5 md:w-1/3 border-r border-gray7 mr-3 text-[15px]">
          <FormattedMessage
            id="Account.timeLearning"
            values={{ hours: ((statisticLearning?.done_time as number) / 3600).toFixed(2) }}
          />
        </Typography>
        <Typography className="text-[15px]">
          <FormattedMessage
            id="Account.correctRate"
            values={{ rate: Math.round(100 * (statisticLearning?.done_correct_quiz_rate || 0)) }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.statisticLearning, nextProps?.statisticLearning);
};

export default memo(LearningInfoBlock, checkEqualProps);
