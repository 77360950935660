/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import useMediaQuery from "@mui/material/useMediaQuery"
import Typography from "@mui/material/Typography"
import React, { useEffect } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import IconNoData from "../../../../public/assets/icons/IconNoData";
import { GREY_300, GREY_600 } from "../../../utils/configs/theme/colors";
import NotificationCard from "./NotificationCard";
import { commonActions } from "../redux/actions";
import { TCommonState } from "../utils/type";
import { isEmpty } from "../../../utils/helpers/helpers";

interface IProp {
  isFocus: boolean;
  setFocus: Function;
}
function NotificationDropdown({ isFocus, setFocus }: IProp) {
  const userNotifications = useSelector(
    (state: { commonNew: TCommonState }) => state.commonNew.userNotifications
  );
  const dispatch = useDispatch();
  const matches1280 = useMediaQuery("(min-width:1280px)");
  useEffect(() => {
    if (isEmpty(userNotifications)) {
      dispatch(commonActions.handleGetNotification());
    }
  }, []);
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setFocus(false);
      }}
    >
      <div
        tabIndex={-1}
        style={{
          outline: "none",
          // margin: "0px 16px 0px 20px"
        }}
      >
        <Collapse
          in={isFocus}
          unmountOnExit
          style={{
            position: "absolute",
            zIndex: 1400,
            top: 40,
            right: 12,
            left: matches1280 ? -180 : undefined,
            borderRadius: 8
          }}
          className="sm:w-[250px] md:w-[326px] lg:w-[400px] sm:right-[0px] md:r-[4px]"
        >
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              height: 605,
              borderRadius: 8,
              border: `1px solid ${GREY_300}`
            }}
            className="w-full md:max-h-[605px] sm:max-h-[500px]"
          >
            <div style={{ overflowY: "auto", height: "100%" }}>
              {userNotifications && userNotifications?.items?.length > 0 ? (
                <div className="sm:py-2 sm:px-2 md:px-4">
                  {userNotifications?.items
                    ?.sort((a: any, b: any) =>
                      moment.utc(b.created_at).diff(moment.utc(a.created_at))
                    )
                    .map((v: any, index: number) => (
                      <div key={index} className="text-black" id="notification_item">
                        {" "}
                        <NotificationCard data={v} />
                        {userNotifications?.items?.length !== index + 1 && (
                          <Divider />
                        )}
                      </div>
                    ))}
                </div>
              ) : (
                <Box
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <IconNoData />
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    component="p"
                    style={{ color: GREY_600 }}
                  >
                    <FormattedMessage id="common.dataEmpty" />
                  </Typography>
                </Box>
              )}
            </div>
          </Paper>
        </Collapse>
      </div>
    </OutsideClickHandler>
  );
}

export default NotificationDropdown;
