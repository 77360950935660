import { Radio, RadioProps, useTheme } from "@mui/material";
import React from "react";

export const CustomRadio = (props: RadioProps) => {
  const theme = useTheme();
  return (
    <Radio
      sx={{
        "&.Mui-checked": {
          color: theme.palette.primary.main
        }
      }}
      {...props}
    />
  );
};
