/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React from "react";

const Alert = React.forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type TNotify = {
  open: boolean | undefined;
  onClose: (u, v) => void;
  message: string;
  duration?: number;
};

export default function CXNotify({ open, onClose, message, duration }: TNotify) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={duration || 1500}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity="success" sx={{ width: "100%" }}>
        {open ? message : ""}
      </Alert>
    </Snackbar>
  );
}
