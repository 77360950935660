import React from "react";

const CameraIconCircle = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" fill="#F3F3F3" stroke="white" />
      <path
        d="M7.81999 14.1693H12.1867C13.3367 14.1693 13.795 13.4651 13.8492 12.6068L14.0658 9.1651C14.1242 8.2651 13.4075 7.5026 12.5033 7.5026C12.2492 7.5026 12.0158 7.35677 11.8992 7.13177L11.5992 6.5276C11.4075 6.14844 10.9075 5.83594 10.4825 5.83594H9.52832C9.09916 5.83594 8.59916 6.14844 8.40749 6.5276L8.10749 7.13177C7.99082 7.35677 7.75749 7.5026 7.50333 7.5026C6.59916 7.5026 5.88249 8.2651 5.94083 9.1651L6.15749 12.6068C6.20749 13.4651 6.66999 14.1693 7.81999 14.1693Z"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.375 8.33594H10.625"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0026 12.4974C10.7484 12.4974 11.3568 11.8891 11.3568 11.1432C11.3568 10.3974 10.7484 9.78906 10.0026 9.78906C9.25677 9.78906 8.64844 10.3974 8.64844 11.1432C8.64844 11.8891 9.25677 12.4974 10.0026 12.4974Z"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CameraIconCircle;
