import { Box, Tooltip } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ToolTipStar({ children, star, top }: any) {
	return (
		<Tooltip
			title={
				<Box style={{ display: "flex", flexDirection: "column", fontSize: 12 }}>
					<span><FormattedMessage id="common.condition"/></span>
					<FormattedMessage
						id="Arena.starTooltip.numOfRecipients"
						values={{
							num: star,
						}}
					/>
					{top && (
						<span>
							{" "}
							<FormattedMessage id="Arena.starTooltip.top" values={{ top }} />
						</span>
					)}
				</Box>
			}
			placement="bottom-start"
			enterTouchDelay={0}
			arrow
		>
			<Box id="star" style={{ marginLeft: 5 }}>
				{children}
			</Box>
		</Tooltip>
	);
}
