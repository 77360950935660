import React from "react";

export default function KeyLock() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" fill="white" stroke="#B00020" />
      <g clipPath="url(#clip0_4423_96688)">
        <path
          d="M10.7205 6.61194V6.05816C10.7205 5.33618 10.4337 4.64377 9.92317 4.13326C9.41265 3.62274 8.72024 3.33594 7.99826 3.33594C7.27629 3.33594 6.58388 3.62274 6.07336 4.13326C5.56285 4.64377 5.27604 5.33618 5.27604 6.05816V6.61194C4.92968 6.7631 4.63488 7.01192 4.42768 7.32796C4.22048 7.64401 4.10987 8.01358 4.10938 8.39149V10.7248C4.10999 11.2403 4.31505 11.7346 4.67957 12.0991C5.04409 12.4636 5.53831 12.6687 6.05382 12.6693H9.94271C10.4582 12.6687 10.9524 12.4636 11.317 12.0991C11.6815 11.7346 11.8865 11.2403 11.8872 10.7248V8.39149C11.8867 8.01358 11.776 7.64401 11.5688 7.32796C11.3617 7.01192 11.0668 6.7631 10.7205 6.61194ZM6.05382 6.05816C6.05382 5.54246 6.25868 5.04788 6.62333 4.68323C6.98799 4.31858 7.48257 4.11372 7.99826 4.11372C8.51396 4.11372 9.00854 4.31858 9.37319 4.68323C9.73785 5.04788 9.94271 5.54246 9.94271 6.05816V6.44705H6.05382V6.05816ZM11.1094 10.7248C11.1094 11.0342 10.9865 11.331 10.7677 11.5498C10.5489 11.7686 10.2521 11.8915 9.94271 11.8915H6.05382C5.7444 11.8915 5.44765 11.7686 5.22886 11.5498C5.01007 11.331 4.88715 11.0342 4.88715 10.7248V8.39149C4.88715 8.08207 5.01007 7.78533 5.22886 7.56653C5.44765 7.34774 5.7444 7.22483 6.05382 7.22483H9.94271C10.2521 7.22483 10.5489 7.34774 10.7677 7.56653C10.9865 7.78533 11.1094 8.08207 11.1094 8.39149V10.7248Z"
          fill="#B00020"
        />
        <path
          d="M7.99827 8.78125C7.89513 8.78125 7.79621 8.82222 7.72328 8.89515C7.65035 8.96808 7.60938 9.067 7.60938 9.17014V9.94792C7.60938 10.0511 7.65035 10.15 7.72328 10.2229C7.79621 10.2958 7.89513 10.3368 7.99827 10.3368C8.10141 10.3368 8.20032 10.2958 8.27326 10.2229C8.34619 10.15 8.38716 10.0511 8.38716 9.94792V9.17014C8.38716 9.067 8.34619 8.96808 8.27326 8.89515C8.20032 8.82222 8.10141 8.78125 7.99827 8.78125Z"
          fill="#B00020"
        />
      </g>
      <defs>
        <clipPath id="clip0_4423_96688">
          <rect width="9.33333" height="9.33333" fill="white" transform="translate(3.33594 3.33594)" />
        </clipPath>
      </defs>
    </svg>
  );
}
