import { Button } from "@mui/material";
import Image from "next/image";
import React from "react";
import TeacherRegisterImage from "../../../../../public/assets/images/TeacherRegister.png";

type TTeacherRegisterProps = {
  onClick: () => void;
};

const TeacherRegister = ({ onClick }: TTeacherRegisterProps) => {
  return (
    <Button className="p-0" onClick={onClick}>
      <Image src={TeacherRegisterImage} height={277} width={298} />
    </Button>
  );
};

export default TeacherRegister;
