import React from "react";

export default function IcEmail({ className, stroke }: any) {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.1667 6H5.83333C5.3731 6 5 6.3731 5 6.83333V16.8333C5 17.2936 5.3731 17.6667 5.83333 17.6667H19.1667C19.6269 17.6667 20 17.2936 20 16.8333V6.83333C20 6.3731 19.6269 6 19.1667 6Z"
				stroke={stroke || "#666666"}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M5 7.25L12.5 11.8333L20 7.25"
				stroke={stroke || "#666666"}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
}
