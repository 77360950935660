/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CXCircularProgress } from "../../common/components/CircularProgress";
import { TCommonState } from "../../common/utils/type";
import { friendActions } from "../redux/actions";
import { TFriendState } from "../utils/types";
import FriendCard from "./FriendCard";
import SubHeaderFriendPage from "./SubHeaderFriendPage";

export default function SearchFriendView({
  isShowDefaultData,
  handleSearchLocation
}) {
  const dispatch = useDispatch();
  const { suggestedFriends } = useSelector(
    (state: { commonNew: TCommonState }) => state.commonNew
  );
  const { listFriendSearch, isLoading } = useSelector(
    (state: { friend: TFriendState }) => state.friend
  );
  const listFriendView = isShowDefaultData
    ? suggestedFriends
    : listFriendSearch;
  useEffect(() => {
    return () => {
      dispatch(friendActions.handleSetSearchKeyFriend(""));
    };
  }, []);
  return (
    <>
      <SubHeaderFriendPage
        title={<FormattedMessage id="friend.suggestedFriend" />}
        subTitle=""
        isShowButtonSearchLocation
        handleSearchLocation={handleSearchLocation}
      />
      {isLoading ? (
        <CXCircularProgress />
      ) : listFriendView?.length > 0 ? (
        <div className="max-h-[90vh] overflow-auto">
          {listFriendView?.map((item) => (
            <FriendCard key={item.member_hid} cardInfo={item} isSearchFriend />
          ))}
        </div>
      ) : (
        <span className="text-friend-no-data">
          <FormattedMessage id="friend.noData" />
        </span>
      )}
    </>
  );
}
