import React from "react";

const IconAccepted = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 10.5C17.5 12.4891 16.7098 14.3968 15.3033 15.8033C13.8968 17.2098 11.9891 18 10 18C8.01088 18 6.10322 17.2098 4.6967 15.8033C3.29018 14.3968 2.5 12.4891 2.5 10.5C2.5 8.51088 3.29018 6.60322 4.6967 5.1967C6.10322 3.79018 8.01088 3 10 3C11.9891 3 13.8968 3.79018 15.3033 5.1967C16.7098 6.60322 17.5 8.51088 17.5 10.5ZM13.7781 7.65937C13.7112 7.59264 13.6314 7.5401 13.5437 7.50489C13.4559 7.46968 13.362 7.45252 13.2675 7.45445C13.173 7.45637 13.0798 7.47734 12.9936 7.5161C12.9073 7.55485 12.8298 7.6106 12.7656 7.68L9.50969 11.8284L7.5475 9.86531C7.41421 9.74111 7.23792 9.6735 7.05576 9.67671C6.8736 9.67992 6.6998 9.75372 6.57098 9.88254C6.44215 10.0114 6.36836 10.1852 6.36515 10.3673C6.36193 10.5495 6.42955 10.7258 6.55375 10.8591L9.03437 13.3406C9.1012 13.4073 9.18078 13.4599 9.26836 13.4952C9.35594 13.5305 9.44973 13.5477 9.54414 13.546C9.63854 13.5442 9.73163 13.5235 9.81784 13.485C9.90405 13.4465 9.98163 13.391 10.0459 13.3219L13.7884 8.64375C13.916 8.51109 13.9865 8.3337 13.9848 8.14965C13.983 7.9656 13.9092 7.78958 13.7791 7.65937H13.7781Z"
        fill="#12B76A"
      />
    </svg>
  );
};
export default IconAccepted;
