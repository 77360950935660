import React from "react";

export default function AnonymousAvatar() {
  return (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="36.4219"
        cy="36"
        r="34.5"
        fill="#E9E9E9"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M36.4219 18C41.3944 18 45.4219 22.0275 45.4219 27C45.4219 31.9725 41.3944 36 36.4219 36C31.4494 36 27.4219 31.9725 27.4219 27C27.4219 22.0275 31.4494 18 36.4219 18ZM36.4219 54C36.4219 54 54.4219 54 54.4219 49.5C54.4219 44.1 45.6469 38.25 36.4219 38.25C27.1969 38.25 18.4219 44.1 18.4219 49.5C18.4219 54 36.4219 54 36.4219 54Z"
        fill="#9D9D9D"
      />
    </svg>
  );
}
