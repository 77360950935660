/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import React, { useRef } from "react";
// import GoogleAd from "../../../common/components/GoogleAd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../../utils/configs/router";
import { TAccountState } from "../../../account/utils/types";
import GoogleAdsScroll from "../../../common/components/GoogleAdsScroll";
import Ranking from "../../../common/components/Ranking";
import { commonActions } from "../../../common/redux/actions";
import { TCommonState } from "../../../common/utils/type";
import Introduce from "../components/Introduce";
import { FacebookElement, YoutubeElement } from "../components/Provider";
import TeacherRegister from "../components/TeacherRegister";

const LIST_PAGE_SHOW_YOUTUBE_FACEBOOK_BLOCK = [
  ROUTES.taikhoan.diamondhistory.path,
  ROUTES.taikhoan.baidalam.path,
  ROUTES.taikhoan.edit.path,
  ROUTES.taikhoan.path,
  ROUTES.taikhoan.chedoxem.path,
  ROUTES.taikhoan.badgestotal.path,
  ROUTES.taikhoan.myGroups.path,
  ROUTES.taikhoan.myGroups.groupDetail.path,
  ROUTES.thongke.path,
  ROUTES.chagrepoint.path,
  ROUTES.friend.path,
  ROUTES.giftItem.path,
  ROUTES.giftItem.detail.path,
  ROUTES.giftItem.quacuatoi.path,
  ROUTES.timkiem.path,
  ROUTES.course.path
];

// const LIST_PAGE_KEEP_ADS_WHEN_SCROLL = [
//   ROUTES.thionline.path,
//   ROUTES.hoidap.path
// ]

// declare const window: any;
function DefaultRightSide({ mainContentHeight }) {
  const location = useRouter();
  const dispatch = useDispatch();
  const providerRef = useRef<any>(null);
  const rightSideRef = useRef<any>(null);
  const intl = useIntl();

  const { teacherInfo, userInfos, isLoadingGetTeacherInfo } = useSelector(
    (state: { account: TAccountState; commonNew: TCommonState }) => ({
      teacherInfo: state.account.teacherInfo,
      isLoadingGetTeacherInfo: state.account.isLoadingGetTeacherInfo,
      userInfos: state.commonNew.userInfos
    })
  );

  const isArena = location?.pathname.includes("/thi-online");
  // const matches1100 = useMediaQuery("(min-width:1100px)");
  const pageShowAds =
    LIST_PAGE_SHOW_YOUTUBE_FACEBOOK_BLOCK.includes(location?.pathname) ||
    isArena ||
    location?.pathname.includes("/hoi-dap") ||
    location?.pathname?.includes("[subjectsId]");

  const doNotShowFacebook =
    (isArena && location?.pathname.includes("/de-thi")) || location?.pathname === ROUTES.subjects.path;
  const isHomePage = location?.pathname === "/";
  const isShowRanking = location?.query?.grade && location?.query?.subjectsId;
  const lessonPage = location?.pathname?.includes("/ly-thuyet/");
  const searchScorePage =
    location.pathname?.includes("/tra-cuu-diem-thi-vao-10") ||
    location.pathname?.includes("/tra-cuu-diem-thi-thpt-quoc-gia");
  // const isShowScrollKeep = LIST_PAGE_KEEP_ADS_WHEN_SCROLL?.includes(location?.pathname) || isHomePage;

  const isApprovedTeacher = teacherInfo && teacherInfo.status === "approve";

  const handleClickTeacherRegister = () => {
    if (!userInfos?.member?.pub_id) {
      dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
    } else if (!teacherInfo) {
      dispatch(commonActions.setIsOpenTeacherAccountFormDialog(true));
    } else {
      dispatch(
        commonActions.handleSaveNotify({
          message: intl.formatMessage({ id: "notification.RegisteredTeacherAccount" }),
          isOpenDialog: true
        })
      );
    }
  };

  // useEffect(() => {
  //   if (window.FB) {
  //     window.FB.XFBML.parse();
  //   }
  // }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: 298
      }}
      className="text-black gap-y-4"
      ref={rightSideRef}
    >
      <Box ref={providerRef} className="flex flex-col gap-y-4">
        {isShowRanking && (
          <Box>
            <Ranking />
          </Box>
        )}
        {/* <Box className="w-[298px] h-[180px]" id="googleAds">
          <GoogleAd
            slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_ARTICLE}
            style={{ display: "", width: 298, height: 180 }}
          />
        </Box> */}
        {isHomePage && !isApprovedTeacher && !isLoadingGetTeacherInfo && (
          <TeacherRegister onClick={handleClickTeacherRegister} />
        )}
        {isHomePage && <Introduce />}
        {!doNotShowFacebook && pageShowAds && (
          <Box justifyContent="center" className="sm:hidden sm2:block">
            <FacebookElement />
            <YoutubeElement />
          </Box>
        )}
      </Box>
      {(isHomePage || pageShowAds || lessonPage || searchScorePage) && (
        <GoogleAdsScroll
          mainContentHeight={mainContentHeight}
          frontBoxHeight={providerRef?.current?.offsetHeight}
          googleAdsHeight={720}
          className="max-h-[720px]"
        />
      )}
      {/* {(isHomePage || pageShowAds || lessonPage) &&
        (isHomePage ? (
          <GoogleAdsScroll
            mainContentHeight={mainContentHeight}
            frontBoxHeight={providerRef?.current?.offsetHeight}
            googleAdsHeight={720}
            className="max-h-[720px]"
          />
        ) : (
          <Box className="max-h-[720px]" id="googleAds">
            <GoogleAd
              style={{
                display: "block",
                height: 720,
                width: !matches1100 ? 250 : 298
              }}
              slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_BANNER}
            />
          </Box>
        ))} */}
    </Box>
  );
}

export default React.memo(DefaultRightSide);
