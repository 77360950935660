import React from "react";

export default function IconDevice() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7V17C19 21 18 22 14 22H8C4 22 3 21 3 17V7C3 3 4 2 8 2H14C18 2 19 3 19 7Z"
        stroke="#7AC863"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 5.5H9"
        stroke="#7AC863"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0031 19.1C11.8592 19.1 12.5531 18.406 12.5531 17.55C12.5531 16.694 11.8592 16 11.0031 16C10.1471 16 9.45312 16.694 9.45312 17.55C9.45312 18.406 10.1471 19.1 11.0031 19.1Z"
        stroke="#7AC863"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
