/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { friendActions } from "../redux/actions";
import { TFriendState } from "../utils/types";
import FriendCard from "./FriendCard";
import SubHeaderFriendPage from "./SubHeaderFriendPage";

export default function InvitationView({
  listSearchFriend,
  isShowDefaultData
}) {
  const dispatch = useDispatch();
  const { listInvitation, notify, searchKey } = useSelector(
    (state: { friend: TFriendState }) => state.friend
  );
  const handleGetInvitationFriend = () => {
    dispatch(friendActions.handleGetInvitationFriend());
  };
  const listFriendView = !isShowDefaultData && searchKey?.length > 0 ? listSearchFriend : listInvitation;
  useEffect(() => {
    if (listInvitation?.length === 0) {
      handleGetInvitationFriend();
    }
    return () => {
      dispatch(friendActions.handleSetSearchKeyFriend(""));
    };
  }, []);
  useEffect(() => {
    if (
      notify?.type === "ACCEPT_FRIEND_SUCCESS" ||
      notify?.type === "DECLINE_FRIEND_REQUEST_SUCCESS"
    ) {
      handleGetInvitationFriend();
    }
  }, [notify?.type]);
  return (
    <>
      <SubHeaderFriendPage
        title={<FormattedMessage id="friend.invitation" />}
        subTitle={
          <FormattedMessage
            id="friend.invitation.subTitle"
            values={{ num: listFriendView?.length }}
          />
        }
      />
      {listFriendView?.length > 0 ? (
        <div className="max-h-[90vh] overflow-y-auto">
          {listFriendView?.map((item) => (
            <FriendCard key={item.member_hid} cardInfo={item} isInvitation />
          ))}
        </div>
      ) : (
        <span className="text-friend-no-data">
          <FormattedMessage id="friend.noData" />
        </span>
      )}
    </>
  );
}
