import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from "lodash";
import Link from "next/link";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { TTheme } from "../../../utils/configs/setupTheme";
import CustomSlider from "./CustomSlider";
import { ExamsCard } from "./Elements";
import { SHOW_MAXIMUM_EXERCISE_DONE_IN_DAILY_REPORT_DIALOG } from "../utils/constant";

const TAB = {
  EXERCISE_DONE: "EXERCISE_DONE",
  LESSON_LEARNED: "LESSON_LEARNED"
};

type SubjectsInfoBlockProps = {
  examsList: any[];
  lessonsList: any[];
  practicesList: any[];
};

const SubjectsInfoBlock = ({ examsList, lessonsList, practicesList }: SubjectsInfoBlockProps) => {
  const theme: TTheme = useTheme();
  const intl = useIntl();
  const matches768 = useMediaQuery("(min-width:768px)");

  const [tab, setTab] = useState<string>(TAB.EXERCISE_DONE);

  const exerciseDoneList = useMemo(() => {
    return [...examsList, ...practicesList];
  }, [examsList, practicesList]);

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  useEffect(() => {
    if (!exerciseDoneList.length) setTab(TAB.LESSON_LEARNED);
  }, [exerciseDoneList]);

  return (
    <Box className="flex flex-col gap-4">
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        sx={{
          height: 36,
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.main
          },
          "& .MuiTab-root": {
            color: theme.palette.primary.gray400
          },
          "& .MuiTab-root.Mui-selected": {
            color: theme.palette.primary.main
          }
        }}
      >
        <Tab
          label={intl.formatMessage({ id: "Profile.exerciseDone" })}
          className={`normal-case text-[15px] font-semibold ${!exerciseDoneList?.length && "hidden"}`}
          value={TAB.EXERCISE_DONE}
        />
        <Tab
          label={intl.formatMessage({ id: "Lesson_learned" })}
          className={`normal-case text-[15px] font-semibold ${!lessonsList?.length && "hidden"}`}
          value={TAB.LESSON_LEARNED}
        />
      </Tabs>
      <Box>
        <CustomSlider
          isDots={false}
          autoplay={false}
          className="w-full"
          centerMode={matches768 && (tab === TAB.EXERCISE_DONE ? exerciseDoneList.length > 1 : lessonsList.length > 1)}
        >
          {(() => {
            switch (tab) {
              case TAB.EXERCISE_DONE:
                return exerciseDoneList
                  ?.slice(0, SHOW_MAXIMUM_EXERCISE_DONE_IN_DAILY_REPORT_DIALOG)
                  ?.map((ele: any, index: number) => (
                    <Link
                      key={index}
                      style={{ textDecoration: "unset", color: "black" }}
                      href={ele.direct_link.replace("/lop/", "/")}
                    >
                      <a
                        href={ele?.direct_link?.replace("/lop/", "/")}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-block w-full max-w-[360px] border border-gray rounded-2xl p-2 md:mx-1.5"
                        onClick={() =>
                          eventTracking("select_home_done_lesson", {
                            id: ele?.subject_id,
                            title: ele?.title,
                            grade_slug: ele?.grade_slug
                          })
                        }
                      >
                        <ExamsCard ele={ele} />
                      </a>
                    </Link>
                  ));
              case TAB.LESSON_LEARNED:
                return lessonsList
                  ?.slice(0, SHOW_MAXIMUM_EXERCISE_DONE_IN_DAILY_REPORT_DIALOG)
                  ?.map((ele: any, index: number) => (
                    <Link
                      key={index}
                      type="button"
                      style={{ textDecoration: "unset", color: "black" }}
                      href={ele.direct_link}
                      passHref
                    >
                      <a
                        href={ele.direct_link}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-block w-full max-w-[360px] border border-gray rounded-2xl p-3 md:mx-1.5"
                        onClick={() =>
                          eventTracking("select_home_last_lesson", {
                            id: ele?.subject_id,
                            title: ele?.title,
                            grade_slug: ele?.grade_slug
                          })
                        }
                      >
                        <Typography className="hover:underline cursor-pointer text-[15px] text-black">
                          <Typography className="text-green inline">{ele.subject_title}: </Typography>
                          {ele.title}
                        </Typography>
                      </a>
                    </Link>
                  ));
              default:
                return "";
            }
          })()}
        </CustomSlider>
      </Box>
    </Box>
  );
};

const checkEqualProps = (prevProps, nextProps) => {
  return (
    isEqual(prevProps?.examsList, nextProps?.examsList) &&
    isEqual(prevProps?.lessonsList, nextProps?.lessonsList) &&
    isEqual(prevProps?.practicesList, nextProps?.practicesList)
  );
};

export default memo(SubjectsInfoBlock, checkEqualProps);
