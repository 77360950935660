/* eslint-disable react/jsx-filename-extension */
import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import IconButtonScrollTo from "../../../../public/assets/icons/IconButtonScrollTo";

function ScrollButton() {
  const [visible, setVisible] = useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", toggleVisible);
  }

  if (visible) {
    return (
      <Box
        style={{
          position: "fixed",
          bottom: "25%",
          right: isMobile ? "10px" : "40px",
          height: "20px",
          zIndex: 99,
          cursor: "pointer"
        }}
        onClick={scrollToTop}
      >
        <IconButtonScrollTo />
      </Box>
    );
  }
  return null;
}

export default React.memo(ScrollButton);
