/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import uniqBy from "lodash/uniqBy";
import { useRouter } from "next/router";
import React, { useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import IconSearch from "../../../../../public/assets/icons/IconSearch";
import { IconX } from "../../../../../public/assets/icons/XCircle";
import { eventTracking } from "../../../../firebase/firebaseConfig";
import { isEmpty } from "../../../../utils/constants/constants";
import { commonActions } from "../../../common/redux/actions";
import { TCommonState } from "../../../common/utils/type";
import { searchAction } from "../../../search/redux/action";

const MenuSuggest = ({ handleClose = (_e) => {} }) => {
  const intl = useIntl();
  const router = useRouter();
  const [textSearch, setTextSearch] = React.useState<string>("");
  const { listKeywordSearched } = useSelector((store: { commonNew: TCommonState }) => store.commonNew);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleClick = (e) => {
    handleClose(e);
    if (!isEmpty(textSearch.trim())) {
      dispatch(searchAction.handleChooseTab("ly-thuyet"));
      dispatch(commonActions.handleSearch(textSearch));
      router
        .push({
          pathname: "/tim-kiem",
          query: {
            search: textSearch
          }
        })
        .then(() => dispatch(commonActions.handleAddKeywordsFormListSearched(textSearch)));
    }
  };

  return (
    <Popup
      trigger={
        <div
          className="md:w-[160px] lg:w-[228px] lg:h-[32px] sm:w-full bg-white border border-gray"
          style={{
            padding: "4px 12px",
            borderRadius: "20px",
            display: "flex"
          }}
        >
          <input
            type="text"
            placeholder={intl.formatMessage({ id: "search.placeholder" })}
            style={{
              outline: "none",
              fontSize: "12px",
              width: "100%"
            }}
            className="placeholder:text-xs text-black select-all"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                eventTracking("select_search_button", {}, () => handleClick(e));
              }
            }}
            ref={inputRef}
            id="search_input"
          />
          <button
            type="button"
            className="ml-[5px]"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              eventTracking("select_search_button", {}, () => handleClick(e));
            }}
            id="search_btn"
          >
            <IconSearch />
          </button>
        </div>
      }
      position="bottom center"
      on="focus"
      closeOnDocumentClick
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{
        padding: listKeywordSearched.length ? 20 : 0,
        border: "none",
        top: 56,
        zIndex: "1000",
        width: "265px",
        position: "fixed"
      }}
      arrow={false}
      className="search"
    >
      {(close: () => void) =>
        !!listKeywordSearched.length && (
          <>
            <div className="pb-4">
              <span className="text-[15px] font-semibold">
                <FormattedMessage id="search.suggest.title" />
              </span>
            </div>
            {uniqBy(listKeywordSearched, (e: any) => e)
              ?.slice(0, 5)
              ?.filter((item) => item !== "")
              ?.map((item, index) => (
                <div
                  key={index}
                  tabIndex="-1"
                  className="flex justify-between items-center mb-3"
                  onFocus={(e) => e.stopPropagation()}
                >
                  <div
                    role="button"
                    onClick={() => {
                      eventTracking("select_history_search", { key_word: item }, () => {
                        close();
                        setTextSearch(item);
                      });
                    }}
                    className="w-[80%] text-left"
                    onFocus={(e) => e.stopPropagation()}
                    tabIndex="-2"
                  >
                    <span className="w-full line-clamp-2 text-[15px] outline-none">{item}</span>
                  </div>
                  <div
                    role="button"
                    tabIndex="-3"
                    onClick={() => {
                      dispatch(commonActions.handleDeleteKeywordsFromList(item));
                      inputRef.current.focus();
                    }}
                    onFocus={(e) => e.stopPropagation()}
                  >
                    <IconX />
                  </div>
                </div>
              ))}
          </>
        )
      }
    </Popup>
  );
};

export default MenuSuggest;
