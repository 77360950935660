import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { CloseIcon } from "../../../../public/assets/icons/CloseIcon";
import GreenTickCircleIcon from "../../../../public/assets/icons/GreenTickCircleIcon";
import InfoIcon from "../../../../public/assets/icons/InfoIcon";
import { TTheme } from "../../../utils/configs/setupTheme";
import { TAccountState, TTeacherAccountStatus } from "../../account/utils/types";
import TeacherBenefits from "../components/TeacherBenefits";
import { instructionForEnteringExamFileLocation } from "../../../utils/constants/constants";

type TeacherAccountInfoDialogProps = {
  open: boolean;
  onClose: () => void;
};

const TeacherAccountInfoDialog = ({ open, onClose }: TeacherAccountInfoDialogProps) => {
  const theme: TTheme = useTheme();

  const { teacherInfo } = useSelector((state: { account: TAccountState }) => state.account);

  const getMessageStatus = (status: TTeacherAccountStatus) => {
    return `Dialog.TeacherAccountInfo.accountStatus.${status}.message`;
  };

  return (
    <Dialog
      open={open}
      scroll="paper"
      sx={{
        "& .MuiPaper-root": {
          width: { sm: 560, xs: 350 },
          borderRadius: 4
        }
      }}
    >
      <DialogTitle className="p-4 pt-6">
        <Typography className="text-[19px] font-semibold text-black1 text-center">
          <FormattedMessage id="Dialog.TeacherAccountInfo.title" />
        </Typography>
        <IconButton className="absolute right-1 top-1" onClick={onClose}>
          <CloseIcon stroke={theme.palette.primary.gray4} />
        </IconButton>
      </DialogTitle>
      <DialogContent className="px-4 pb-6">
        <Box className="flex flex-col gap-y-4">
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.nameField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">{teacherInfo?.name}</Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.phoneField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">{teacherInfo?.phone}</Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.emailField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">{teacherInfo?.email}</Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.schoolField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">{teacherInfo?.school}</Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.cityField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">{teacherInfo?.city}</Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.experienceField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">{teacherInfo?.experience}</Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.certificateField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">{teacherInfo?.certificate}</Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.achievedAchievementsField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">{teacherInfo?.achieved_achievements}</Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.gradesField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">
              {teacherInfo?.grade_register.map((grade) => grade.title).join(", ")}
            </Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.subjectField.label" />
            </Typography>
            <Typography className="flex-1 text-[14px] text-black1">{teacherInfo?.subject_register.title}</Typography>
          </Box>
          <Box className="flex gap-x-4">
            <Typography className="w-[100px] text-gray400 text-[14px]">
              <FormattedMessage id="Dialog.TeacherAccountInfo.accountTypeField.label" />
            </Typography>
            {teacherInfo?.status === "block" || teacherInfo?.status === "reject" ? (
              <Typography>-</Typography>
            ) : (
              <Typography className="flex-1 text-[14px] text-secondary">
                {teacherInfo?.is_vip ? (
                  <FormattedMessage id="Dialog.TeacherAccountInfo.accountTypeField.value.vip" />
                ) : (
                  <FormattedMessage id="Dialog.TeacherAccountInfo.accountTypeField.value.normal" />
                )}
              </Typography>
            )}
          </Box>
        </Box>

        <hr className="my-4 text-gray" />

        <Typography className="font-semibold text-black1 text-center mb-4">
          <FormattedMessage id="Dialog.TeacherAccountInfo.teacherBenefits" />
        </Typography>
        <TeacherBenefits />
        <Box className="flex items-center justify-between mt-4">
          <a href={instructionForEnteringExamFileLocation} download>
            <Box className="text-green text-[13px] flex items-center gap-x-1">
              <InfoIcon />
              <FormattedMessage id="common.instructionsForEnteringExam" />
            </Box>
          </a>
          <Box
            className={`flex justify-center items-center rounded-[100px] h-[44px] w-[337px] ${
              teacherInfo?.status !== "approve" ? "bg-gray" : "border-gradient-green"
            }`}
          >
            {teacherInfo?.status !== "approve" ? (
              <Typography className="normal-case text-white text-[14px]">
                <FormattedMessage id={getMessageStatus(teacherInfo?.status as TTeacherAccountStatus)} />
              </Typography>
            ) : (
              <Box className="flex items-center gap-x-3">
                <GreenTickCircleIcon />
                <Typography className="normal-case text-green text-[14px]">
                  <FormattedMessage id={getMessageStatus(teacherInfo?.status as TTeacherAccountStatus)} />
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TeacherAccountInfoDialog;
