/* eslint-disable react/require-default-props */
import React from "react";

export const IconHeathy = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5141 17.3443C10.2307 17.4443 9.76406 17.4443 9.48073 17.3443C7.06406 16.5193 1.66406 13.0776 1.66406 7.24427C1.66406 4.66927 3.73906 2.58594 6.2974 2.58594C7.81406 2.58594 9.15573 3.31927 9.9974 4.4526C10.8391 3.31927 12.1891 2.58594 13.6974 2.58594C16.2557 2.58594 18.3307 4.66927 18.3307 7.24427C18.3307 13.0776 12.9307 16.5193 10.5141 17.3443Z"
        fill="#FF007A"
      />
    </svg>
  );
};
const IconHeath = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5141 17.3443C10.2307 17.4443 9.76406 17.4443 9.48073 17.3443C7.06406 16.5193 1.66406 13.0776 1.66406 7.24427C1.66406 4.66927 3.73906 2.58594 6.2974 2.58594C7.81406 2.58594 9.15573 3.31927 9.9974 4.4526C10.8391 3.31927 12.1891 2.58594 13.6974 2.58594C16.2557 2.58594 18.3307 4.66927 18.3307 7.24427C18.3307 13.0776 12.9307 16.5193 10.5141 17.3443Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconHeath;

export const IconHeath16 = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2246 2.98517C14.989 2.43961 14.6493 1.94523 14.2244 1.5297C13.7993 1.11293 13.298 0.781734 12.7479 0.554114C12.1774 0.317149 11.5656 0.195856 10.9479 0.197278C10.0813 0.197278 9.23574 0.434583 8.50098 0.882825C8.3252 0.990051 8.1582 1.10782 8 1.23615C7.8418 1.10782 7.6748 0.990051 7.49902 0.882825C6.76426 0.434583 5.91875 0.197278 5.05215 0.197278C4.42812 0.197278 3.82344 0.316809 3.25215 0.554114C2.7002 0.78263 2.20273 1.11134 1.77559 1.5297C1.35019 1.94476 1.01037 2.43926 0.775391 2.98517C0.531055 3.55294 0.40625 4.15587 0.40625 4.77638C0.40625 5.36173 0.525781 5.97169 0.763086 6.5922C0.961719 7.11075 1.24648 7.64864 1.61035 8.19181C2.18691 9.05138 2.97969 9.94786 3.96406 10.8567C5.59531 12.3631 7.21074 13.4037 7.2793 13.4459L7.6959 13.7131C7.88047 13.8309 8.11777 13.8309 8.30234 13.7131L8.71895 13.4459C8.7875 13.402 10.4012 12.3631 12.0342 10.8567C13.0186 9.94786 13.8113 9.05138 14.3879 8.19181C14.7518 7.64864 15.0383 7.11075 15.2352 6.5922C15.4725 5.97169 15.592 5.36173 15.592 4.77638C15.5938 4.15587 15.4689 3.55294 15.2246 2.98517Z"
        fill="#FF007A"
      />
    </svg>
  );
};
