import React from "react";

export default function GooglePlayIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="5" fill="black" />
      <g filter="url(#filter0_ii_2625_63837)">
        <path
          d="M8.34696 6.02715C8.11416 6.27355 7.97656 6.65595 7.97656 7.15115V24.844C7.97656 25.34 8.11416 25.7216 8.34696 25.968L8.40616 26.0256L18.3174 16.1144V15.9976V15.8808L8.40616 5.96875L8.34696 6.02715Z"
          fill="url(#paint0_linear_2625_63837)"
        />
        <path
          d="M21.6196 19.4205L18.3164 16.1157V15.9989V15.8821L21.6204 12.5781L21.6948 12.6205L25.6092 14.8445C26.7268 15.4797 26.7268 16.5189 25.6092 17.1549L21.6948 19.3789L21.6196 19.4205Z"
          fill="url(#paint1_linear_2625_63837)"
        />
        <g filter="url(#filter1_i_2625_63837)">
          <path
            d="M21.6965 19.3792L18.3173 16L8.34766 25.9704C8.71566 26.3608 9.32446 26.4088 10.0101 26.02L21.6965 19.3792Z"
            fill="url(#paint2_linear_2625_63837)"
          />
        </g>
        <path
          d="M21.6965 12.6198L10.0101 5.9798C9.32446 5.5902 8.71566 5.639 8.34766 6.0294L18.3181 15.9998L21.6965 12.6198Z"
          fill="url(#paint3_linear_2625_63837)"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_2625_63837"
          x="7.97656"
          y="5.71094"
          width="18.4727"
          height="20.5781"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.15" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2625_63837"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.15" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_2625_63837"
            result="effect2_innerShadow_2625_63837"
          />
        </filter>
        <filter
          id="filter1_i_2625_63837"
          x="8.34766"
          y="16"
          width="13.3477"
          height="10.2891"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.15" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2625_63837"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2625_63837"
          x1="17.4388"
          y1="6.96395"
          x2="4.0132"
          y2="20.3895"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0.0066" stopColor="#00A1FF" />
          <stop offset="0.2601" stopColor="#00BEFF" />
          <stop offset="0.5122" stopColor="#00D2FF" />
          <stop offset="0.7604" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2625_63837"
          x1="27.064"
          y1="15.9989"
          x2="7.70729"
          y2="15.9989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset="0.4087" stopColor="#FFBD00" />
          <stop offset="0.7754" stopColor="#FFA500" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2625_63837"
          x1="19.8613"
          y1="17.8351"
          x2="1.65454"
          y2="36.0419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2625_63837"
          x1="5.83678"
          y1="0.139638"
          x2="13.9666"
          y2="8.26948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset="0.0685" stopColor="#2DA771" />
          <stop offset="0.4762" stopColor="#15CF74" />
          <stop offset="0.8009" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  );
}
