/* eslint-disable no-else-return */
/* eslint-disable react/require-default-props */
import { Avatar, Button, useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "react-redux";
import {
  getRelationshipButtonId,
  INVITATION_PAGE_QUERY,
  listRelationshipMapping,
  SEARCH_FRIEND_PAGE_QUERY
} from "../utils/constant";
import { MenuActionFriend, MenuListFriend } from "./MenuActionFriend";
import {
  handleAcceptFriend,
  handleDeclineFriendRequest,
  handleDeleteInvitation,
  handleDeleteFollow,
  handleRemoveFriend,
  handleInviteFriend,
  handleFollowFriend
} from "../../common/utils/commonAPI";
import IconMenuRow from "../../../../public/assets/icons/IconMenuRow";

type TFriendCard = {
  cardInfo: any;
  isInvitation?: boolean;
  isSearchFriend?: boolean;
  isListFriend?: boolean;
  isFollowing?: boolean;
  isFriend?: boolean;
};
export default function FriendCard({
  cardInfo,
  isFollowing,
  isFriend,
  isInvitation,
  isSearchFriend,
  isListFriend
}: TFriendCard) {
  const router = useRouter();
  const matches768 = useMediaQuery("(min-width:768px)");
  const store = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClickShowAccountPreview = () => {
    if (matches768) {
      if (router?.query?.f !== cardInfo?.member_hid) {
        router.push({
          pathname: "ban-be",
          query: router?.query?.p
            ? { ...router.query, f: cardInfo?.member_hid }
            : {
                p: isInvitation ? INVITATION_PAGE_QUERY : SEARCH_FRIEND_PAGE_QUERY,
                f: cardInfo?.member_hid
              }
        });
      }
    } else {
      router.push(`/tai-khoan/${cardInfo?.member_hid}`);
    }
  };
  const relationship = getRelationshipButtonId(cardInfo);
  return (
    <div className="py-2">
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center">
          <Avatar
            src={cardInfo?.avatar}
            alt={cardInfo?.display_name}
            sx={{ width: 56, height: 56 }}
            onClick={() => handleClickShowAccountPreview()}
          />
          <div className="px-2 flex-col items-center">
            <button type="button" onClick={() => handleClickShowAccountPreview()} className="flex text-left">
              <span className="line-clamp-1 text-[15px] flex font-semibold break-all">{cardInfo?.display_name}</span>
            </button>

            <span className="text-[10px] text-gray400 font-normal flex">
              <FormattedMessage
                id="friend.friendCard.gradeLevel"
                values={{ grade: cardInfo?.grade, level: cardInfo?.level }}
              />
            </span>
            <div className={`${isListFriend ? "mt-0" : "mt-2"}`}>
              {isInvitation && (
                <div className="flex sm:gap-3 md:gap-2 lg:gap-3">
                  <Button
                    type="button"
                    className="bg-green-gradient button-relationship-friend sm:w-[105px] md:w-[85px] lg:w-[105px]"
                    onClick={() => handleAcceptFriend(store, cardInfo?.invitation_id, cardInfo?.member_hid)}
                    sx={{
                      textTransform: "none"
                    }}
                  >
                    <span>
                      <FormattedMessage id="friend.relationship.confirm" />
                    </span>
                  </Button>
                  <Button
                    className="button-relationship-friend bg-gray1 sm:w-[105px] md:w-[85px] lg:w-[105px]"
                    onClick={() => handleDeclineFriendRequest(store, cardInfo?.invitation_id, cardInfo?.member_hid)}
                    sx={{
                      textTransform: "none"
                    }}
                  >
                    <FormattedMessage id="friend.relationship.delete" />
                  </Button>
                </div>
              )}
              {isSearchFriend && (
                <div>
                  <Button
                    className={`flex gap-2 button-relationship-friend ${
                      listRelationshipMapping?.find((e) => e.id === relationship)?.className
                    }`}
                    onClick={(event) => {
                      if (relationship === "friend.relationship.add") {
                        handleInviteFriend(store, cardInfo?.member_hid);
                      } else {
                        handleOpenMenu(event);
                      }
                    }}
                    sx={{
                      textTransform: "none"
                    }}
                  >
                    {listRelationshipMapping?.find((e) => e.id === relationship)?.icon}
                    <span>
                      <FormattedMessage id={relationship} />
                    </span>
                  </Button>
                  <MenuActionFriend
                    anchorEl={anchorEl}
                    invitationId={cardInfo?.invitation_id}
                    memberHid={cardInfo?.member_hid}
                    displayName={cardInfo?.display_name}
                    handleCloseMenu={handleCloseMenu}
                    relationship={relationship}
                    handleAcceptFriend={handleAcceptFriend}
                    handleDeclineFriendRequest={handleDeclineFriendRequest}
                    handleDeleteInvitation={handleDeleteInvitation}
                    handleDeleteFollow={handleDeleteFollow}
                    handleRemoveFriend={handleRemoveFriend}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-1">
          {isListFriend && (
            <>
              <button type="button" onClick={(e) => handleOpenMenu(e)}>
                <IconMenuRow />
              </button>
              <MenuListFriend
                anchorEl={anchorEl}
                memberHid={cardInfo?.member_hid}
                displayName={cardInfo?.display_name}
                handleCloseMenu={handleCloseMenu}
                isFollowing={isFollowing}
                isFriend={isFriend}
                handleDeleteFollow={handleDeleteFollow}
                handleFollowFriend={handleFollowFriend}
                handleRemoveFriend={handleRemoveFriend}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
