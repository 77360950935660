import React from "react";

export function IconCrown({ width = 20, height = 20 }) {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9.5"
        fill="url(#paint0_linear_4407_73513)"
        stroke="white"
      />
      <path
        d="M12.3516 13.4897H7.65156C7.44156 13.4897 7.20656 13.3247 7.13656 13.1247L5.06656 7.33469C4.77156 6.50469 5.11656 6.24969 5.82656 6.75969L7.77656 8.15469C8.10156 8.37969 8.47156 8.26469 8.61156 7.89969L9.49156 5.55469C9.77156 4.80469 10.2366 4.80469 10.5166 5.55469L11.3966 7.89969C11.5366 8.26469 11.9066 8.37969 12.2266 8.15469L14.0566 6.84969C14.8366 6.28969 15.2116 6.57469 14.8916 7.47969L12.8716 13.1347C12.7966 13.3247 12.5616 13.4897 12.3516 13.4897Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 15H12.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 11H11.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4407_73513"
          x1="10"
          y1="0"
          x2="10"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDF3F" />
          <stop offset="1" stopColor="#F7871D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function IconCrownGray() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" fill="#E5E5E5" stroke="white" />
      <path
        d="M12.3516 13.4897H7.65156C7.44156 13.4897 7.20656 13.3247 7.13656 13.1247L5.06656 7.33469C4.77156 6.50469 5.11656 6.24969 5.82656 6.75969L7.77656 8.15469C8.10156 8.37969 8.47156 8.26469 8.61156 7.89969L9.49156 5.55469C9.77156 4.80469 10.2366 4.80469 10.5166 5.55469L11.3966 7.89969C11.5366 8.26469 11.9066 8.37969 12.2266 8.15469L14.0566 6.84969C14.8366 6.28969 15.2116 6.57469 14.8916 7.47969L12.8716 13.1347C12.7966 13.3247 12.5616 13.4897 12.3516 13.4897Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 15H12.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 11H11.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
