import React from "react";

export default function KeyUnlock() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" fill="white" stroke="#38B000" />
      <path
        d="M8.00608 8.67188C7.90294 8.67188 7.80402 8.71285 7.73109 8.78578C7.65816 8.85871 7.61719 8.95762 7.61719 9.06076V9.83854C7.61719 9.94168 7.65816 10.0406 7.73109 10.1135C7.80402 10.1865 7.90294 10.2274 8.00608 10.2274C8.10922 10.2274 8.20814 10.1865 8.28107 10.1135C8.354 10.0406 8.39497 9.94168 8.39497 9.83854V9.06076C8.39497 8.95762 8.354 8.85871 8.28107 8.78578C8.20814 8.71285 8.10922 8.67188 8.00608 8.67188Z"
        fill="#38B000"
      />
      <path
        d="M10 6.44765H6V6.0588C5.99988 5.62772 6.14713 5.20883 6.41861 4.86798C6.69008 4.52712 7.07039 4.28364 7.49973 4.17581C7.92907 4.06799 8.38311 4.10193 8.79044 4.2723C9.19778 4.44267 9.53532 4.73981 9.75 5.11701C9.7755 5.16166 9.8098 5.201 9.85094 5.23277C9.89208 5.26454 9.93926 5.28812 9.98977 5.30216C10.0403 5.31621 10.0932 5.32045 10.1454 5.31463C10.1976 5.30881 10.2481 5.29306 10.294 5.26827C10.3399 5.24348 10.3804 5.21013 10.4131 5.17014C10.4458 5.13014 10.47 5.08429 10.4845 5.03518C10.4989 4.98607 10.5033 4.93468 10.4973 4.88393C10.4913 4.83319 10.4751 4.78409 10.4496 4.73943C10.1489 4.21149 9.67629 3.79566 9.10601 3.55729C8.53574 3.31892 7.90015 3.27154 7.29915 3.42259C6.69815 3.57363 6.16583 3.91455 5.78585 4.39174C5.40587 4.86893 5.19979 5.45535 5.2 6.0588V6.61253C4.84374 6.76368 4.54052 7.01247 4.3274 7.32848C4.11428 7.6445 4.00051 8.01404 4 8.39191V10.725C4.00064 11.2405 4.21155 11.7346 4.58649 12.0991C4.96142 12.4636 5.46976 12.6687 6 12.6693H10C10.5302 12.6687 11.0386 12.4636 11.4135 12.0991C11.7884 11.7346 11.9994 11.2405 12 10.725V8.39191C11.9994 7.87645 11.7884 7.38228 11.4135 7.0178C11.0386 6.65331 10.5302 6.44827 10 6.44765ZM11.2 10.725C11.2 11.0344 11.0736 11.3311 10.8485 11.5499C10.6235 11.7687 10.3183 11.8916 10 11.8916H6C5.68174 11.8916 5.37652 11.7687 5.15147 11.5499C4.92643 11.3311 4.8 11.0344 4.8 10.725V8.39191C4.8 8.08252 4.92643 7.7858 5.15147 7.56703C5.37652 7.34826 5.68174 7.22536 6 7.22536H10C10.3183 7.22536 10.6235 7.34826 10.8485 7.56703C11.0736 7.7858 11.2 8.08252 11.2 8.39191V10.725Z"
        fill="#38B000"
      />
    </svg>
  );
}
