/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
import Dialog from "@mui/material/Dialog";
import Box from "@mui/system/Box";
import * as React from "react";
import { useRouter } from "next/router";
import LoginSocial from "../../auth/components/LoginSocial";
import { LIST_NON_BLOCK_URL, LIST_ROUTER_BACK_TO_PREVIOUS_PAGE } from "../../../utils/constants/constants";
import { getRoutePath } from "../../../utils/helpers/helpers";
import XCircle from "../../../../public/assets/icons/XCircle";

interface TProps {
  open: boolean;
  required?: boolean;
  onSuccess: Function;
  onClose: Function;
}
function LoginDialog({ open, required = false, onClose, onSuccess }: TProps) {
  const router = useRouter();
  const path = getRoutePath(router, router.query);
  const handleClose = () => {
    if (!LIST_NON_BLOCK_URL.some((v) => v === path)) {
      router.push("/");
    }
    if (LIST_ROUTER_BACK_TO_PREVIOUS_PAGE.some((route) => router.asPath.includes(route))) {
      router.back();
    }
    onClose(false);
  };

  return (
    <Dialog onClose={() => !required && handleClose()} open={open}>
      {!required && (
        <button
          type="button"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 7,
            top: 9,
            background: "white"
          }}
        >
          <XCircle />
        </button>
      )}
      <Box
        className="sm:w-[80vw] sm:h-[65vh] md:w-[560px] md:h-[394px] sm:px-2 sm:py-8 md:px-4 md:py-10 sm:justify-center md:justify-between"
        display="flex"
        flexWrap="wrap"
        style={{ padding: "42px 16px" }}
      >
        <LoginSocial onSuccess={() => onSuccess()} />
      </Box>
    </Dialog>
  );
}

export default LoginDialog;
