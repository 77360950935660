import React from "react";

export default function IconDiamond({
  width,
  height,
  className,
  fill
}: Partial<any>) {
  return (
    <svg
      width={width || "18"}
      height={height || "14"}
      viewBox="0 0 18 14"
      fill={fill || "none"}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.33915L8.46108 13.8002H9.37855L17.8396 5.33915V4.42168L13.6091 0.191139L13.1504 0H4.68927L4.23054 0.191139L0 4.42168V5.33915ZM8.91982 12.424L1.3762 4.88041L4.95687 1.27426H12.8828L16.4634 4.88041L8.91982 12.424ZM8.91982 2.574H12.3476L14.654 4.88041L8.91982 10.4872V2.574Z"
        fill={fill || "gray"}
      />
    </svg>
  );
}

export function IconDiamond10({ width, height,  className, fill }: any) {
  return (
    <svg
      width={width || "14"}
      height={height || "12"}
      viewBox="0 0 14 12"
      fill={fill || "none"}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 3.98199L5.73 9.5H6.27L11.25 3.98199V3.38366L8.76 0.624654L8.49 0.5H3.51L3.24 0.624654L0.75 3.38366V3.98199ZM6 8.60249L1.56 3.68283L3.6675 1.33102H8.3325L10.44 3.68283L6 8.60249ZM6 2.17867H8.0175L9.375 3.68283L6 7.33933V2.17867Z"
        fill={fill || "#38B000"}
      />
    </svg>
  );
}
