/* eslint-disable react/require-default-props */
import React from "react";

export default function IconClock({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width || "11"}
      height={height || "10"}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7996 3.84655H7.85302C7.80237 3.84655 7.76094 3.88798 7.76094 3.93863V4.49111C7.76094 4.54175 7.80237 4.58319 7.85302 4.58319H10.7996C10.8502 4.58319 10.8917 4.54175 10.8917 4.49111V3.93863C10.8917 3.88798 10.8502 3.84655 10.7996 3.84655ZM9.25723 5.41191H7.85302C7.80237 5.41191 7.76094 5.45334 7.76094 5.50399V6.05646C7.76094 6.10711 7.80237 6.14854 7.85302 6.14854H9.25723C9.30788 6.14854 9.34931 6.10711 9.34931 6.05646V5.50399C9.34931 5.45334 9.30788 5.41191 9.25723 5.41191ZM5.41636 2.81641H4.91797C4.84661 2.81641 4.78906 2.87396 4.78906 2.94532V5.79979C4.78906 5.84123 4.80863 5.87921 4.84201 5.90338L6.55584 7.15337C6.61339 7.1948 6.69396 7.18329 6.7354 7.12574L7.03121 6.72174V6.72059C7.07264 6.66304 7.05998 6.58247 7.00243 6.54103L5.54412 5.48672V2.94532C5.54527 2.87396 5.48657 2.81641 5.41636 2.81641Z"
        fill="#FBB02D"
      />
      <path
        d="M9.18452 6.86307H8.51924C8.45478 6.86307 8.39378 6.89644 8.35925 6.95169C8.21307 7.18304 8.04273 7.39713 7.84706 7.5928C7.50981 7.93004 7.11732 8.19477 6.6811 8.37893C6.22875 8.56999 5.74879 8.66668 5.25386 8.66668C4.75778 8.66668 4.27781 8.56999 3.82662 8.37893C3.3904 8.19477 2.99791 7.93004 2.66066 7.5928C2.32342 7.25555 2.05869 6.86306 1.87453 6.42684C1.68347 5.97565 1.58678 5.49568 1.58678 4.9996C1.58678 4.50352 1.68347 4.02471 1.87453 3.57236C2.05869 3.13614 2.32342 2.74365 2.66066 2.4064C2.99791 2.06916 3.3904 1.80443 3.82662 1.62027C4.27781 1.42921 4.75893 1.33252 5.25386 1.33252C5.74994 1.33252 6.22991 1.42921 6.6811 1.62027C7.11732 1.80443 7.50981 2.06916 7.84706 2.4064C8.04273 2.60207 8.21307 2.81616 8.35925 3.04751C8.39378 3.10276 8.45478 3.13614 8.51924 3.13614H9.18452C9.26394 3.13614 9.31458 3.05326 9.2789 2.98305C8.52845 1.49021 7.00683 0.519918 5.30796 0.500351C2.82065 0.469274 0.754612 2.50539 0.750008 4.99039C0.745404 7.48 2.7631 9.5 5.25271 9.5C6.97345 9.5 8.52039 8.52626 9.2789 7.01615C9.31458 6.94594 9.26278 6.86307 9.18452 6.86307Z"
        fill="#FBB02D"
      />
    </svg>
  );
}
