import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import DefaultLeftSide from "./DefaultLeftSide";
import { TMember } from "../../../common/utils/type";
import HumbuggerIcon from "../../../../../public/assets/icons/HumbuggerIcon";

type Anchor = "top" | "left" | "bottom" | "right";
type TypeDrawerLeftSide = {
  member: TMember;
  // isNewMember: boolean;
  handleOpenModalImage: () => void;
};
function DrawerLeftSide({
  member,
  // isNewMember,
  handleOpenModalImage
}: TypeDrawerLeftSide) {
  const [state, setState] = React.useState({
    left: false
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 260 }}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      className="bg-grayBackground h-full text-black px-2"
    >
      <List className="py-2">
        <DefaultLeftSide
          // isNewMember={isNewMember}
          handleOpenModalImage={handleOpenModalImage}
          mainContentHeight={0}
        />
      </List>
    </Box>
  );

  return (
    <div>
      <button
        type="button"
        onClick={toggleDrawer("left", true)}
        className="block"
      >
        {!member ? (
          <PersonIcon color="disabled" />
        ) : (
          <Avatar
            alt={member?.name}
            src={member?.avatar}
            sx={{ width: 32, height: 32 }}
          />
        )}
        <span
          className={
            member
              ? "absolute bottom-0 right-0"
              : "absolute bottom-[7px] right-[1px]"
          }
        >
          <HumbuggerIcon />
        </span>
      </button>
      <Drawer
        anchor="left"
        open={state.left}
        onClose={toggleDrawer("left", false)}
        className="max-h-max"
      >
        {list("left")}
      </Drawer>
    </div>
  );
}
export default React.memo(DrawerLeftSide)