/* eslint-disable @typescript-eslint/no-unused-vars */
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import styled from "@mui/styles/styled";
import Box from "@mui/system/Box";
import { getAuth, signOut } from "firebase/auth";
import moment from "moment";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useRouter } from "next/router";
import { setCookie } from "cookies-next";
import { commonActions } from "../../common/redux/actions";
import { signOutApi } from "../../auth/utils/authAPI";
import IconCopy from "../../../../public/assets/icons/IconCopy";
import IconDevice from "../../../../public/assets/icons/IconDevice";
import IconNoti from "../../../../public/assets/icons/IconNoti";
import IconTrash from "../../../../public/assets/icons/IconTrash";
import SignOut2 from "../../../../public/assets/icons/SignOut2";
import AlertDialog from "../../common/dialog/AlertDialog";
import { genSignature, getNonce, isEmpty } from "../../../utils/helpers/helpers";
import { accountActions } from "../redux/actions";
import { accountServices } from "../redux/services";
import XCircle from "../../../../public/assets/icons/XCircle";
import { toastSuccess } from "../../common/components/toastify";
import { TCommonState } from "../../common/utils/type";
import { friendActions } from "../../friends/redux/actions";

const CustomDialog = styled(Dialog)(({ theme }: any) => ({
  "& .MuiPaper-root": {
    width: 560,
    padding: "8px",
    borderRadius: "6px"
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    aligItems: "center"
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BlueSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#1A73E8",
    // '&:hover': {
    //   backgroundColor: '#1A73E8',
    // },
    marginRight: "0"
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#8CBDFF"
  }
}));

export default function SetupDialog({ setOpen, open, setOpenAlertDialog }: any) {
  const intl = useIntl();
  const router = useRouter();
  const store = useStore();
  const dataUserInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const isNotLogin = isEmpty(dataUserInfos?.member);
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(true);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);

  // TODO get notification flag to set checked
  const handleChange = () => {
    setChecked(!checked);
    const dataConfigPre = {
      flag: checked ? 0 : 1,
      timestamp: moment().unix(),
      nonce: getNonce()
    };
    const dataConfig = {
      ...dataConfigPre,
      signature: genSignature(dataConfigPre)
    };
    dispatch(accountActions.handleUpdateConfigNotifications(dataConfig));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const dataPostPre = {
        timestamp: moment().unix(),
        nonce: getNonce(),
        uid: dataUserInfos?.member?.pub_id
      };
      const dataPost = {
        ...dataPostPre,
        signature: genSignature(dataPostPre)
      };
      const response = await accountServices.deleteAccount(dataPost);
      handleClose();
      if (response?.data) {
        dispatch(commonActions.addUserInfos({ ...dataUserInfos, member: null }));
        dispatch(commonActions.addFireBaseInfos({}));
        dispatch(commonActions.handleSaveAccessToken(""));
        dispatch(commonActions.handleSaveIdToken(""));
        dispatch(commonActions.handleSaveProviderId(""));
        if (typeof window !== "undefined") {
          window.location.href = "/";
        }
      }
    } catch (error) {
      setOpenAlertDialog(error);
    }
  };
  const logout = () => {
    // dispatch(commonActions.handleSaveAccessToken(token));
    dispatch(friendActions.handleCleanListFriend());
    dispatch(commonActions.handleLogOut());
  };
  const actionsSignOut = async () => {
    const paramsSignatureSignOut = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      uid: dataUserInfos?.member?.pub_id
    };
    const params = {
      ...paramsSignatureSignOut,
      signature: genSignature(paramsSignatureSignOut)
    };
    const auth = getAuth();
    try {
      const response = await signOutApi(store, params);
      if (response?.data) {
        signOut(auth)
          .then(() => {
            logout();
            setCookie("accessToken", JSON.stringify(response?.data?.data?.token?.access_token));
            setCookie("userInfo", JSON.stringify(response?.data?.data));
            dispatch(commonActions.handleOpenModalLogin({ isOpen: false }));
            dispatch(commonActions.addUserInfos(response?.data?.data));
            router.push("/");
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            dispatch(commonActions.handleOpenModalLogin({ isOpen: false }));
            logout();
            router.push("/");
            // An error happened.
          });
      }
    } catch (err) {
      dispatch({
        type: "CLEAR_TOKEN"
      });
    }
    handleClose();
    dispatch(accountActions.handleCleanAccountState());
  };

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #E5E5E5",
          padding: "16px 0",
          margin: "0 24px"
        }}
        className="text-center text-black"
      >
        <span className="text-center w-full font-semibold text-[18px]">
          <FormattedMessage id="Profile.generalSetting" />
        </span>
        <IconButton className="absolute right-[1px] top-[1px]" aria-label="close" onClick={handleClose} sx={{}}>
          <XCircle />
        </IconButton>
      </DialogTitle>
      <DialogContent className="blackQuiz" style={{ padding: "0 24px" }}>
        <DialogContentText className="center-col" style={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: 56,
              borderBottom: "1px solid #E5E5E5"
            }}
          >
            <Box display="flex" alignItems="center" className="blackQuiz">
              <div style={{ width: 24 }}>
                <IconNoti />
              </div>

              <Typography variant="body1" style={{ marginLeft: 8, color: "#212121", fontSize: "15px" }}>
                <FormattedMessage id="Setting.notification" />
              </Typography>
            </Box>
            <div>
              <BlueSwitch
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                checked={checked}
                color="secondary"
                className="w-[49px]"
              />
            </div>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: 56,
              borderBottom: "1px solid #E5E5E5"
            }}
          >
            <Box display="flex" alignItems="center" my={2}>
              <div
                style={{
                  width: 24
                }}
              >
                <IconDevice />
              </div>

              <Typography variant="body1" style={{ marginLeft: 8, color: "#212121", fontSize: "15px" }}>
                <FormattedMessage id="Setting.deviceCode" />
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" style={{ color: "#212121", fontSize: "13px", fontWeight: "500" }}>
                {dataUserInfos.device?.pub_id}
              </Typography>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(dataUserInfos?.device?.pub_id);
                  toastSuccess(intl.formatMessage({ id: "coppie-success" }));
                }}
                style={{ padding: 0, marginLeft: "5px" }}
              >
                <IconCopy />
              </IconButton>
            </Box>
          </Box>
          {!isNotLogin && (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 56,
                width: "100%",
                borderBottom: "1px solid #E5E5E5"
              }}
              onClick={() => {
                setLogoutDialog(true);
              }}
            >
              <Box display="flex" alignItems="center">
                <SignOut2 />
                <Typography variant="body1" style={{ marginLeft: 8, color: "#212121", fontSize: "15px" }}>
                  <FormattedMessage id="Profile.logout" />
                </Typography>
              </Box>
            </Box>
          )}
          {!isNotLogin && (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 56,
                width: "100%"
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                onClick={() => {
                  setDeleteDialog(true);
                }}
              >
                <div style={{ width: 24 }}>
                  <IconTrash />
                </div>

                <Typography variant="body1" style={{ marginLeft: 8, color: "#212121", fontSize: "15px" }}>
                  <FormattedMessage id="IDS_Delete_Account" />
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <AlertDialog
        open={logoutDialog}
        setOpen={setLogoutDialog}
        title="Xác nhận"
        contentKey="Setting.logoutDesc"
        triggerFunc={() => {
          actionsSignOut();
        }}
        buttonOkKey=""
      />
      <AlertDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        title="Thông báo"
        contentKey="Setting.deleteAccountDesc"
        triggerFunc={() => {
          handleSubmit();
        }}
        buttonOkKey=""
      />
    </CustomDialog>
  );
}
