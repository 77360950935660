/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-filename-extension */
import { Box } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import Link from "next/link";
import { eventTracking } from "../../../firebase/firebaseConfig";

function Lessons({ lessonsList }: { lessonsList: any }) {
  const router = useRouter();
  return (
    <Box className="box-cover-left md:w-[250px] lg:w-[298px]">
      <Box
        style={{
          display: "flex"
        }}
        className="title-sub"
        id="left_side_lesson_title"
      >
        <FormattedMessage id="Lesson_learned" />
      </Box>
      <Box style={{ marginTop: 12 }} display="flex" flexDirection="column">
        {lessonsList?.slice(0, 9).map((ele: any, idx: number) => (
          <Link
            type="button"
            key={`${idx}`}
            style={{ textDecoration: "unset", color: "black" }}
            href={ele.direct_link}
            passHref
            onClick={() =>
              eventTracking(
                "select_home_last_lesson",
                { id: ele?.subject_id, title: ele?.title, grade_slug: ele?.grade_slug },
                () => router.push(ele?.direct_link)
              )
            }
          >
            <a href={ele.direct_link} target="_blank" rel="noreferrer" className="py-1">
              <span
                style={{
                  marginBottom: idx === lessonsList?.length - 1 ? 0 : 10
                }}
                key={`${idx}`}
                className="hover:underline cursor-pointer text-[15px] text-black"
                id="left_side_lesson_item"
              >
                {ele.title}
              </span>
            </a>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default React.memo(Lessons);
