import React from "react";

export default function IcCalendar({ className, fill }: any) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.13913 8.50219H19.0001M6.75001 5H17.2501C17.7142 5 18.1593 5.18449 18.4875 5.51288C18.8157 5.84128 19.0001 6.28668 19.0001 6.75109V17.2489C19.0001 17.7133 18.8157 18.1587 18.4875 18.4871C18.1593 18.8155 17.7142 19 17.2501 19H6.75001C6.28588 19 5.84076 18.8155 5.51257 18.4871C5.18438 18.1587 5 17.7133 5 17.2489V6.75109C5 6.28668 5.18438 5.84128 5.51257 5.51288C5.84076 5.18449 6.28588 5 6.75001 5V5Z"
        stroke={fill || "#666666"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.77651 14.3293V13.3846H9.54651C10.131 13.3846 10.5283 13.0387 10.5283 12.5336C10.5283 12.0721 10.1651 11.7219 9.56314 11.7219C8.93926 11.7219 8.55513 12.0424 8.51226 12.594H7.31875C7.36338 11.4566 8.23838 10.6914 9.62701 10.6914C10.9946 10.6914 11.755 11.4479 11.7506 12.3506C11.7463 13.0983 11.2764 13.5903 10.614 13.7523V13.8337C11.4724 13.9572 11.9939 14.5044 11.9939 15.3301C11.9939 16.4114 10.9771 17.1775 9.58414 17.1775C8.19113 17.1775 7.24263 16.4149 7.1875 15.2478H8.42213C8.46063 15.7696 8.90513 16.1032 9.56751 16.1032C10.2211 16.1032 10.6788 15.7477 10.6788 15.2136C10.6788 14.6664 10.2509 14.3293 9.55876 14.3293H8.77651ZM14.639 17.019V12.1063H14.5629L13.05 13.1499V11.9487L14.6434 10.8499H15.9297V17.019H14.639Z"
        fill={fill || "#666666"}
      />
    </svg>
  );
}
