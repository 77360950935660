import React from "react";

const IconMenuRow = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.0625 12C17.0625 12.5178 17.4822 12.9375 18 12.9375C18.5178 12.9375 18.9375 12.5178 18.9375 12C18.9375 11.4822 18.5178 11.0625 18 11.0625C17.4822 11.0625 17.0625 11.4822 17.0625 12Z"
				fill="#666666"
			/>
			<path
				d="M11.0625 12C11.0625 12.5178 11.4822 12.9375 12 12.9375C12.5178 12.9375 12.9375 12.5178 12.9375 12C12.9375 11.4822 12.5178 11.0625 12 11.0625C11.4822 11.0625 11.0625 11.4822 11.0625 12Z"
				fill="#666666"
			/>
			<path
				d="M5.0625 12C5.0625 12.5178 5.48223 12.9375 6 12.9375C6.51777 12.9375 6.9375 12.5178 6.9375 12C6.9375 11.4822 6.51777 11.0625 6 11.0625C5.48223 11.0625 5.0625 11.4822 5.0625 12Z"
				fill="#666666"
			/>
		</svg>
	);
};

export default IconMenuRow;
