import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { isEmpty } from "lodash";
import { useTheme } from "@mui/material";
import { TTheme } from "../../../../utils/configs/setupTheme";
import { CheckBoxChecked, CheckBoxNone } from "../../../../../public/assets/icons/CheckBox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100
    }
  }
};

export default function MultipleSelectCheckBox({
  items,
  option,
  handleChange,
  styleSelect,
  extraItemText,
  label,
  size,
  formStyle
}: any) {
  const theme: TTheme = useTheme();
  return (
    <FormControl sx={{ ...formStyle }} size={size || "medium"}>
      <Select
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={option}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) =>
          !isEmpty(selected) ? (
            <span>
              {extraItemText} {selected.join(", ")}
            </span>
          ) : (
            <span>{label}</span>
          )
        }
        MenuProps={MenuProps}
        sx={styleSelect}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item} sx={{ display: "flex", padding: 0 }}>
            <Checkbox
              checked={option.indexOf(item) > -1}
              sx={{
                // color: theme.palette.primary.main,
                "&.Mui-checked": {
                  color: theme.palette.primary.main
                }
              }}
              icon={<CheckBoxNone />}
              checkedIcon={<CheckBoxChecked />}
            />
            {extraItemText}
            <ListItemText primary={item} sx={{ marginLeft: 0.5 }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
