/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { setCookie } from "cookies-next";
import moment from "moment";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { PUBLIC_LAYOUT, ROUTES } from "../../../../utils/configs/router";
import { TTheme } from "../../../../utils/configs/setupTheme";
import { DebuggerMessage, WIDTH_LAYOUT, WIDTH_LAYOUT_TABLET } from "../../../../utils/constants/constants";
import { genSignature, getNonce } from "../../../../utils/helpers/helpers";
import { TAccountState } from "../../../account/utils/types";
import { arenaRoomActions } from "../../../arena/redux/actions";
import { TArenaState } from "../../../arena/utils/types";
import Breadcrumb from "../../../common/components/BreadCrumb";
import { CXCircularProgress } from "../../../common/components/CircularProgress";
import GoogleAd from "../../../common/components/GoogleAd";
import ModalConfirm from "../../../common/components/ModalConfirm";
import ScrollButton from "../../../common/components/ScrollButton";
import SurveyComponents from "../../../common/components/SurveyComponents";
import AlertDialog from "../../../common/dialog/AlertDialog";
import ClassesDialog from "../../../common/dialog/ClassesDialog";
import CongratulationAlertDialog from "../../../common/dialog/CongratulationAlertDialog";
import DailyReportDialog from "../../../common/dialog/DailyReportDialog";
import LoginDialog from "../../../common/dialog/LoginDialog";
import UserInfoDialog from "../../../common/dialog/UserInfoDialog";
import { commonActions } from "../../../common/redux/actions";
import {
  LOCATION_PERMISSION_ACCEPTED,
  LOCATION_PERMISSION_DENIED,
  LOCATION_PERMISSION_UNDEFINE
} from "../../../common/utils/constant";
import { TCommonState } from "../../../common/utils/type";
import { IQuizState } from "../../../topics/utils/type";
import ModalChangeAvatar from "../components/ModalChangeAvatar";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import DefaultLeftSide from "./DefaultLeftSide";
import DefaultRightSide from "./DefaultRightSide";
import ResponsiveHeader from "./ResponsiveHeader";
// import BottomFloatingGoogleAds from "../components/BottomFloatingGoogleAds";
import { eventTracking } from "../../../../firebase/firebaseConfig";
// import { LIST_PAGE_HIDE_BOTTOM_FLOATING_GOOGLE_ADS } from "../constants";
import { accountActions } from "../../../account/redux/actions";
import TeacherAccountInfoDialog from "../../../common/dialog/TeacherAccountInfoDialog";
import TeacherBenefitsDialog from "../../../common/dialog/TeacherBenefitsDialog";
import TeacherRegisterFormDialog from "../../../common/dialog/TeacherRegisterFormDialog";
import { questionAndAnswerAction } from "../../../questionAndAnswer/redux/actions";
import { IQuestionAndAnswer } from "../../../questionAndAnswer/utils/types";
import {
  CODE_GROUP_ADD_MEMBER_STATUS_CLOSED,
  CODE_NOT_ACCEPTABLE_REGISTERED_ACCOUNT_ASSOCIATE,
  CODE_NOT_ACCEPTABLE_REGISTERED_PHONE_NUMBER,
  CODE_NOT_FOUND_GROUP,
  PATH_NAME
} from "../constants";

function DefaultLayout({ children }: any) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useRouter();
  const dataUserInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const mainContentRef = useRef<HTMLDivElement>(null);
  const theme: TTheme = useTheme();
  const [openUserInfoDialog, setOpenUserInfoDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isHomePage = location?.pathname === "/";
  const maxWidth1100 = useMediaQuery("(max-width:1099px)");
  const maxWidth768 = useMediaQuery("(max-width:767px)");
  const { notify: notifyAccount } = useSelector((state: { account: TAccountState }) => state.account);
  const { notify: notifyArena } = useSelector((state: { arenaRoom: TArenaState }) => state.arenaRoom);
  const {
    notify,
    openModalConfirmLogin,
    congratulation,
    modalLogin,
    locationPermission,
    openModalConfirmPremium,
    openModalChangeImage,
    showDirect,
    isNewMember,
    isEmptyName,
    grade,
    routerHistory,
    isUpdateInfo,
    isShowAdsDialogFirstTime,
    openDialogAdsInHouse,
    isOpenTeacherAccountInfoDialog,
    isOpenTeacherBenefitsDialog,
    isOpenTeacherAccountFormDialog,
    openClassesDialog
  } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const progressQuiz = useSelector((state: { quizCore: IQuizState }) => state.quizCore.progress);
  const progressArenaQuiz = useSelector((state: { arenaRoom: TArenaState }) => state.arenaRoom.progress);
  const adWebBannerTopHeader = useSelector(
    (state: { commonNew: TCommonState }) => state.commonNew.ad_web_banner_top_header
  );
  const { listSubjectAndGrade } = useSelector(
    (state: { questionAndAnswer: IQuestionAndAnswer }) => state.questionAndAnswer
  );

  // const [isOpenBottomFloatingGoogleAds, setIsOpenBottomFloatingGoogleAds] = useState<boolean>(true);

  const stateDialogBeforeDailyReport = [
    isEmptyName,
    isOpenTeacherAccountFormDialog,
    isNewMember,
    congratulation?.isOpenCongratulation,
    locationPermission === LOCATION_PERMISSION_UNDEFINE,
    openDialogAdsInHouse && isShowAdsDialogFirstTime && location.asPath === "/"
  ];

  const errorCodeShowNotifyAccount = [
    CODE_NOT_ACCEPTABLE_REGISTERED_ACCOUNT_ASSOCIATE,
    CODE_NOT_ACCEPTABLE_REGISTERED_PHONE_NUMBER,
    CODE_GROUP_ADD_MEMBER_STATUS_CLOSED,
    CODE_NOT_FOUND_GROUP
  ];

  const handleCloseClassesDialog = () => {
    dispatch(commonActions.setOpenClassesDialog(false));
  };
  const handleUpdateLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((locale) => {
        if (locale) {
          const dataUpdatePre = {
            timestamp: moment().unix(),
            nonce: getNonce(),
            long: locale.coords.longitude,
            lat: locale.coords.latitude
          };
          const dataUpdate = {
            ...dataUpdatePre,
            signature: genSignature(dataUpdatePre)
          };
          dispatch(commonActions.handleUpdateLocation(dataUpdate));
        }
      });
    }
  };
  const isQuizPage = location?.pathname.includes("/bai-tap/") || location?.pathname.includes("/de-thi");
  const isArenaQuizPage = location?.pathname === ROUTES.thionline.phongdau.thidau.path;
  const isRotation = location?.pathname.includes("/vong-quay-may-man");
  const isFriendPage = location?.pathname?.includes("/ban-be");
  const isAccountManagementPage = location?.pathname === "/tai-khoan";
  const isInviteLink = location?.pathname?.includes("/tu-vao");
  const isHideLeftSide =
    (!!progressQuiz && isQuizPage && !isArenaQuizPage) ||
    (isQuizPage && maxWidth1100) ||
    (!!progressArenaQuiz && isArenaQuizPage) ||
    isFriendPage ||
    location.pathname === "/vong-quay-may-man" ||
    isAccountManagementPage ||
    isInviteLink;

  const isHiddenLeftRightSide = useMemo(
    () => PUBLIC_LAYOUT.find((element: { path: string }) => location?.pathname?.includes(element?.path)),
    [location]
  );

  const isHideBreadcrumb = isInviteLink;

  const handleOpenModalImage = useCallback(() => {
    dispatch(commonActions.handleOpenModalChangeAvatar(true));
  }, []);
  const getUserInfo = () => {
    dispatch(commonActions.handleGetUserInfo());
  };

  const handleCloseCongratulation = () => {
    getUserInfo();
    dispatch(commonActions.handleSaveCongratulation({}));
  };

  React.useEffect(() => {
    if (
      location?.route !== ROUTES.taikhoan.edit.path &&
      (notifyAccount.type === "UPDATE_INFO_ACCOUNT_SUCCESS" || notifyAccount.type === "GIFT_DIAMOND_SUCCESS")
    ) {
      getUserInfo();
    }
  }, [notifyAccount]);
  React.useEffect(() => {
    if (isNewMember) {
      dispatch(commonActions.setOpenClassesDialog(true));
      dispatch(commonActions.handleSetIsNewMember(false));
    }
  }, [isNewMember]);
  React.useEffect(() => {
    if (isEmptyName) {
      setOpenUserInfoDialog(true);
      dispatch(commonActions.handleSetIsEmptyName(false));
    }
  }, [isEmptyName]);

  useEffect(() => {
    if (dataUserInfos.member?.pub_id) {
      const timeoutId = setTimeout(
        () =>
          dispatch(
            accountActions.handleGetTeacherInfo(undefined, () => {
              if (listSubjectAndGrade.length === 0) dispatch(questionAndAnswerAction.handleGetCreationInfo());
            })
          ),
        300
      );
      return () => clearTimeout(timeoutId);
    }
  }, [dataUserInfos.member?.pub_id, grade]);

  React.useEffect(() => {
    // get timeserver
    dispatch(commonActions.getTimeServer());
    // set first time load page
    dispatch(commonActions.handleSetRouterHistory([location.pathname]));
  }, []);
  React.useEffect(() => {
    // update lai userInfo
    if (isUpdateInfo && !!dataUserInfos?.member?.pub_id) {
      dispatch(commonActions.handleGetUserInfo());
    }
  }, [isUpdateInfo]);
  React.useEffect(() => {
    // set router history
    // eslint-disable-next-line no-unused-vars
    const handleRouteChange = (url) => {
      if (routerHistory.length > 1 && location?.pathname === routerHistory[(routerHistory?.length || 0) - 1]) {
      } else {
        dispatch(commonActions.handleSetRouterHistory([...routerHistory, location?.pathname]));
        DebuggerMessage(`ROUTER HISTORY: ${JSON.stringify([...routerHistory, location?.pathname])}`);
      }
    };
    location.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      location.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [location.events]);

  React.useEffect(() => {
    const showLoading = (url) => {
      if (url === "/tai-khoan") setIsLoading(true);
    };
    const hideLoading = () => {
      setIsLoading(false);
    };
    location.events.on("routeChangeStart", showLoading);
    location.events.on("routeChangeComplete", hideLoading);

    return () => {
      location.events.off("routeChangeStart", showLoading);
      location.events.off("routeChangeComplete", hideLoading);
    };
  }, []);

  useEffect(() => {
    if (dataUserInfos?.member?.pub_id && locationPermission === LOCATION_PERMISSION_UNDEFINE) {
      eventTracking("view_dl_location");
    }
  }, [dataUserInfos?.member?.pub_id, locationPermission]);

  return (
    <div style={{ display: "flex" }} className="text-black">
      <CssBaseline />
      <Box
        className="overflow-x-hidden h-max"
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: theme.palette.primary.background,
          width: "100vw",
          marginTop: !maxWidth768 ? "56px" : showDirect ? "122px" : "82px"
        }}
        display="flex"
        flexDirection="column"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {adWebBannerTopHeader === "true" && (
            <Box display="flex" justifyContent="center" className="sm:w-screen md:w-[672px] h-[96px]">
              <GoogleAd
                style={{
                  display: "inline-block",
                  height: 96,
                  width: 672
                }}
                slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_TITLE}
              />
            </Box>
          )}
          {!maxWidth768 ? (
            <div className="w-screen">
              <DefaultHeader dataUserInfos={dataUserInfos} userClass={grade} />
            </div>
          ) : (
            <div className="bg-white w-screen md:hidden">
              <ResponsiveHeader
                isHomePage={isHomePage}
                // isNewMember={isNewMember}
                userInfo={dataUserInfos}
                handleOpenModalImage={handleOpenModalImage}
              />
            </div>
          )}
          <div
            className={`sm:mt-0 sm:pt-[5px] sm:bg-grayBackground md:bg-inherit md:py-0 sm:pr-0 h-auto relative ${
              isHomePage ? "md:mt-5" : isAccountManagementPage ? "md:mt-9" : isRotation ? "mt-0" : "md:mt-10"
            }`}
            style={{
              display: "flex",
              width: "100vw",
              maxWidth: 2200,
              justifyContent: "center"
            }}
          >
            {isLoading ? (
              <CXCircularProgress height="80vh" />
            ) : (
              <>
                {!maxWidth768 && !isHideLeftSide && (
                  <Box
                    width={!maxWidth1100 ? WIDTH_LAYOUT : WIDTH_LAYOUT_TABLET}
                    visibility={isHiddenLeftRightSide && "hidden"}
                    className={`${!maxWidth1100 ? "ml-[40px]" : "ml-[24px]"}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      top: 20
                    }}
                  >
                    {!isHideBreadcrumb && (
                      <Box className="absolute top-[-30px] whitespace-nowrap">
                        <Breadcrumb />
                      </Box>
                    )}
                    <DefaultLeftSide
                      handleOpenModalImage={handleOpenModalImage}
                      mainContentHeight={mainContentRef?.current?.offsetHeight}
                    />
                  </Box>
                )}
                <Box
                  className={
                    isRotation
                      ? " w-screen h-screen overflow-hidden box-border"
                      : "sm:px-0 sm:pt-4 md:pt-0 md:pr-6 md:pl-8 lg:px-12 sm:w-screen md:w-full h-auto flex flex-col md:gap-4 md:pb-6"
                  }
                  minWidth={310}
                  ref={mainContentRef}
                >
                  {children}
                </Box>
                {!maxWidth1100 && !isQuizPage && !isAccountManagementPage && (
                  <Box width={WIDTH_LAYOUT} visibility={isHiddenLeftRightSide && "hidden"} className="flex mr-[40px]">
                    <DefaultRightSide mainContentHeight={mainContentRef?.current?.offsetHeight} />
                  </Box>
                )}
              </>
            )}
          </div>
        </div>
        <DefaultFooter />
      </Box>
      <ScrollButton />
      {/* {!LIST_PAGE_HIDE_BOTTOM_FLOATING_GOOGLE_ADS.includes(location.pathname) && isOpenBottomFloatingGoogleAds && (
        <BottomFloatingGoogleAds onCloseAd={() => setIsOpenBottomFloatingGoogleAds(false)} />
      )} */}
      {!openUserInfoDialog &&
        !isOpenTeacherAccountFormDialog &&
        !openClassesDialog &&
        congratulation?.isOpenCongratulation && (
          <CongratulationAlertDialog
            data={congratulation?.congratulation}
            handleCloseDialog={handleCloseCongratulation}
            openDialog={congratulation.isOpenCongratulation}
            triggerFunc={() => {}}
          />
        )}
      {!openUserInfoDialog && !isOpenTeacherAccountFormDialog && openClassesDialog && (
        <ClassesDialog open={openClassesDialog} onClose={handleCloseClassesDialog} isNewMember={isNewMember} />
      )}
      {openModalChangeImage && (
        <ModalChangeAvatar
          open={openModalChangeImage}
          handleClose={() => dispatch(commonActions.handleOpenModalChangeAvatar(false))}
        />
      )}
      {openUserInfoDialog && (
        <UserInfoDialog
          open={openUserInfoDialog}
          onClose={setOpenUserInfoDialog}
          getDataUser={getUserInfo}
          setError={() => {}}
        />
      )}
      {isOpenTeacherBenefitsDialog && (
        <TeacherBenefitsDialog
          open={isOpenTeacherBenefitsDialog}
          onClose={() => dispatch(commonActions.setIsOpenTeacherBenefitsDialog(false))}
          onClickRegister={() => {
            if (listSubjectAndGrade.length === 0) dispatch(questionAndAnswerAction.handleGetCreationInfo());
            dispatch(commonActions.setIsOpenTeacherAccountFormDialog(true));
          }}
        />
      )}
      {isOpenTeacherAccountFormDialog && !openUserInfoDialog && (
        <TeacherRegisterFormDialog
          open={isOpenTeacherAccountFormDialog}
          onClose={() => {
            dispatch(commonActions.setIsOpenTeacherAccountFormDialog(false));
          }}
        />
      )}
      {isOpenTeacherAccountInfoDialog && (
        <TeacherAccountInfoDialog
          open={isOpenTeacherAccountInfoDialog}
          onClose={() => dispatch(commonActions.setIsOpenTeacherAccountInfoDialog(false))}
        />
      )}
      {/* {notify?.isOpenDialog && (
        <AlertDialog
          open={notify.isOpenDialog}
          setOpen={() => {
            dispatch(commonActions.deleteNotify());
          }}
          title={intl.formatMessage({ id: "Setting.notification" })}
          content={`${notify?.message}-(${notify?.errorCode})`}
          triggerFunc={() => {
            dispatch(commonActions.deleteNotify());
            dispatch(commonActions.handleSaveIdToken(""));
            dispatch(commonActions.addUserInfos({}));
            location.push("/");
          }}
          contentKey="common.note"
          buttonOkKey={undefined}
          isHideCancel
          notifyError
        />
      )} */}
      {!openUserInfoDialog &&
        !isOpenTeacherAccountFormDialog &&
        !openClassesDialog &&
        !congratulation.isOpenCongratulation &&
        !notifyAccount.isOpenDialog &&
        !notifyArena.isOpenDialog &&
        dataUserInfos?.member?.pub_id &&
        locationPermission === LOCATION_PERMISSION_UNDEFINE && (
          <AlertDialog
            open={locationPermission === LOCATION_PERMISSION_UNDEFINE}
            setOpen={() => {}}
            title={intl.formatMessage({ id: "Setting.notification" })}
            content={intl.formatMessage({ id: "Profile.location.description" })}
            triggerFunc={() => {
              handleUpdateLocation();
              dispatch(commonActions.handleSaveLocationPermission(LOCATION_PERMISSION_ACCEPTED));
              eventTracking("access_location");
            }}
            cancelFunc={() => {
              dispatch(commonActions.handleSaveLocationPermission(LOCATION_PERMISSION_DENIED));
            }}
            contentKey="common.note"
            buttonOkKey="common.next"
          />
        )}
      {notify?.isOpenDialog && (
        <AlertDialog
          open={notify?.isOpenDialog}
          setOpen={() => {}}
          title={intl.formatMessage({ id: "Setting.notification" })}
          content={`${notify?.message}${notify?.errorCode ? `(${notify.errorCode})` : ""}`}
          triggerFunc={() => {
            if (location?.asPath?.includes("/de-thi/") && notify?.errorCode === "ENATSM") {
              // gặp lỗi complete mission khi vào quiz không đủ diamond --> back về màn trc đó
              location.back();
            }
            if (notify?.errorCode === "ETKINI") {
              dispatch(commonActions.handleCleanInvalidToken());
              setCookie("accessToken", "");
              setCookie("userInfo", "{}");
              // location.reload();
            }
            dispatch(commonActions.deleteNotify());
          }}
          contentKey="common.note"
          buttonOkKey="common.ok"
          isHideCancel
          notifyError
        />
      )}
      {notifyAccount?.isOpenDialog &&
        !(openDialogAdsInHouse && isShowAdsDialogFirstTime && location.asPath === "/") &&
        errorCodeShowNotifyAccount.includes(notifyAccount.errorCode as string) && (
          <AlertDialog
            open={notifyAccount?.isOpenDialog}
            setOpen={() => {}}
            title={intl.formatMessage({ id: "Setting.notification" })}
            content={`${notifyAccount?.message}(${notifyAccount?.errorCode})`}
            triggerFunc={() => {
              if (notifyAccount.errorCode === CODE_NOT_ACCEPTABLE_REGISTERED_ACCOUNT_ASSOCIATE) {
                dispatch(commonActions.setIsOpenTeacherAccountFormDialog(false));
                dispatch(accountActions.handleGetTeacherInfo());
              }
              dispatch(accountActions.handleClearNotify());
            }}
            contentKey="common.note"
            buttonOkKey="common.ok"
            isHideCancel
            notifyError
          />
        )}
      {notifyArena?.isOpenDialog &&
        !(openDialogAdsInHouse && isShowAdsDialogFirstTime && location.asPath === "/") &&
        location.pathname !== PATH_NAME.arenaQuizPage && (
          <AlertDialog
            open={notifyArena?.isOpenDialog}
            setOpen={() => {}}
            title={intl.formatMessage({ id: "Setting.notification" })}
            content={`${notifyArena?.message}(${notifyArena?.errorCode})`}
            triggerFunc={() => {
              dispatch(arenaRoomActions.handleDeleteNotify());
            }}
            contentKey="common.note"
            buttonOkKey="common.ok"
            isHideCancel
            notifyError
          />
        )}
      {openModalConfirmLogin && (
        <AlertDialog
          open={openModalConfirmLogin}
          setOpen={(value) => {
            dispatch(commonActions.handleOpenModalConfirmLogin(value));
          }}
          title={<FormattedMessage id="Setting.notification" />}
          content={<FormattedMessage id="common.content.modal.confirm.login" />}
          triggerFunc={() => {
            dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
          }}
          buttonOkKey="common.ok"
          isHideCancel={false}
          cancelFunc={() => {
            if (location?.pathname?.includes("/thi-online")) {
              location?.push(`/[grade]/thi-online`, {
                query: {
                  grade,
                  tab: location?.query?.tab,
                  category: location?.query?.category
                }
              });
              dispatch(arenaRoomActions.handleChangeTab(location?.query?.category || "waiting-room"));
            }
          }}
        />
      )}
      {dataUserInfos?.member && (
        <SurveyComponents routerPath={location?.pathname} teacherDialogStatus={[isOpenTeacherAccountFormDialog]} />
      )}
      {dataUserInfos?.member && stateDialogBeforeDailyReport.every((e) => !e) && <DailyReportDialog />}
      {modalLogin.isOpen && (
        <LoginDialog
          open={modalLogin.isOpen}
          required={modalLogin.isRequired}
          onSuccess={() => dispatch(commonActions.handleOpenModalLogin({ isOpen: false }))}
          onClose={() => dispatch(commonActions.handleOpenModalLogin({ isOpen: false }))}
        />
      )}
      {openModalConfirmPremium && (
        <ModalConfirm
          title="common.bottombar.notification"
          content="common.content.modal.confirm.premium"
          onConfirm={undefined}
          onCancel={undefined}
          textConfirm="common.ok"
          handleClose={undefined}
          open={openModalConfirmPremium}
          styles={undefined}
        />
      )}
    </div>
  );
}

export default DefaultLayout;
