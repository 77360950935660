/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import {
  Typography,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  useMediaQuery,
  DialogContent,
  DialogTitle,
  useTheme
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/system/Box";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import moment from "moment";
import { setCookie } from "cookies-next";
import { commonActions } from "../redux/actions";
import { TCommonState, TGradeResponse } from "../utils/type";
import { LIST_CLASSES, SPECIAL_CLASSES } from "../../Default/layout/constants";
import { arenaRoomActions } from "../../arena/redux/actions";
// import { questionAndAnswerAction } from "../../questionAndAnswer/redux/actions";
import { eventTracking } from "../../../firebase/firebaseConfig";
import XCircle from "../../../../public/assets/icons/XCircle";
import MultipleSelectCheckBox from "../components/Elements/MultipleSelectCheckBox";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import { accountActions } from "../../account/redux/actions";
import { BLOCK_SUBJECTS } from "../utils/constant";
import { TTheme } from "../../../utils/configs/setupTheme";
import { IQuestionAndAnswer } from "../../questionAndAnswer/utils/types";
import { questionAndAnswerAction } from "../../questionAndAnswer/redux/actions";

const WIDTH_SELECT_BLOCK_SUBJECT = 150;
const HEIGHT_SELECT_BLOCK_SUBJECT = 40;
const LIST_GRADE_VALUE_TO_RENDER_BLOCK_SUBJECT = [1, 2, 3, 118]; // lop 10, 11, 12, onthi10
const LIST_GRADE_VALUE_TO_RENDER_EXAM_BLOCK_SUBJECT = [1, 2, 117]; // lop 11, 12, OnThiTHPT

const SelectLearningBlock = ({ learningBlockSubject, handleChangeLearningBlockSubject }) => {
  const theme: TTheme = useTheme();

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={learningBlockSubject}
        onChange={handleChangeLearningBlockSubject}
        displayEmpty
        sx={{
          boxShadow: "0px 2px 6px 0px rgba(16, 24, 40, 0.2)",
          height: HEIGHT_SELECT_BLOCK_SUBJECT,
          width: WIDTH_SELECT_BLOCK_SUBJECT,
          borderRadius: 2,
          ".MuiOutlinedInput-notchedOutline": { border: 0 }
        }}
        renderValue={(selected) =>
          isEmpty(selected) ? (
            <span>
              <FormattedMessage id="common.chooseBlockLabel" />
            </span>
          ) : (
            <span>
              <FormattedMessage id="common.block" /> {selected}
            </span>
          )
        }
        MenuProps={{
          sx: {
            "&& .Mui-selected": {
              backgroundColor: theme.palette.primary.light
            },
            "&& .Mui-selected[aria-selected=true]": {
              backgroundColor: theme.palette.primary.light
            }
          }
        }}
      >
        {BLOCK_SUBJECTS.map((item) => (
          <MenuItem
            key={item}
            value={item}
            className={item === learningBlockSubject ? "" : "hover:bg-green/10 duration-200"}
          >
            <FormattedMessage id="common.block" /> {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function ClassesDialog(props: { onClose: any; open: boolean; isNewMember?: boolean }) {
  const { onClose, open, isNewMember } = props;
  const gradeLists = useSelector((state: { commonNew: TCommonState }) => state.commonNew.listGrades);
  const { grade, userInfos } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const { listPostType, timeIdChoose, listSubjectChoose, listGradeChoose } = useSelector(
    (state: { questionAndAnswer: IQuestionAndAnswer }) => state.questionAndAnswer
  );

  const [learningBlockSubject, setLearningBlockSubject] = React.useState<string>(
    userInfos?.member?.learning_block_subject || ""
  );
  const [examBlockSubject, setExamBlockSubject] = React.useState<string[]>(
    userInfos?.member?.exam_block_subject?.split(",") || []
  );
  const [openBlockSubject, setOpenBlockSubject] = React.useState<number>(0);
  const location = useRouter() as any;
  const isMobile = useMediaQuery("(max-width:375px)");
  const dispatch = useDispatch();
  const handleClose = () => {
    onClose();
  };
  const checkOpenBlockSubject = (value) => {
    return (
      LIST_GRADE_VALUE_TO_RENDER_BLOCK_SUBJECT?.includes(value) ||
      LIST_GRADE_VALUE_TO_RENDER_EXAM_BLOCK_SUBJECT.includes(value)
    );
  };
  const handleChangeExamBlockSubject = (event: SelectChangeEvent<typeof examBlockSubject>) => {
    const {
      target: { value }
    } = event;
    if (value?.length > 0) {
      setExamBlockSubject(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
      eventTracking("select_grade_exam_block", {
        grade_id: gradeLists.find((item) => item.id === openBlockSubject)?.slug,
        name_block: typeof value === "string" ? value.split(",") : value
      });
    }
  };
  const handleChangeLearningBlockSubject = (event: SelectChangeEvent) => {
    setLearningBlockSubject(event.target.value);
    eventTracking("select_grade_learning_block", {
      grade_id: gradeLists.find((item) => item.id === openBlockSubject)?.slug,
      name_block: event.target.value
    });
  };

  const handleUpdateProfile = (gradeVal) => {
    const dataUpdateProfilePre: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade_slug: gradeVal
    };
    if (!isEmpty(examBlockSubject)) {
      dataUpdateProfilePre.exam_block_subject = examBlockSubject.toString();
    }
    if (!isEmpty(learningBlockSubject)) {
      dataUpdateProfilePre.learning_block_subject = learningBlockSubject;
    }
    const dataUpdateProfile = {
      ...dataUpdateProfilePre,
      signature: genSignature(dataUpdateProfilePre)
    };
    dispatch(
      accountActions.handleUpdateInfoAccount(dataUpdateProfile, () => dispatch(accountActions.handleClearNotify()))
    );
  };

  const handleChooseGrade = (value: any) => {
    dispatch(commonActions.handleChooseGrade(value));
    dispatch(arenaRoomActions?.handleChooseSubjectFilter([]));
    dispatch(arenaRoomActions?.handleChooseSubject(0));
    setCookie("grade", JSON.stringify(value));

    const gradeId = LIST_CLASSES.find((e) => e.slug === value)?.value;
    if (
      location?.query.topicId ||
      location?.query.subjectsId ||
      (location?.asPath?.includes("/thi-online") && location?.asPath?.includes("/de-thi"))
    ) {
      // practice, lesson, exam, subject -> home
      location.push("/");
    } else if (!!location?.query.roomHid && location?.asPath?.includes("/thi-online")) {
      // detail, analysis room -> list room
      location.push(`/${value}/thi-online?tab=phong-thi`);
    } else if (location.pathname === "/hoi-dap/[postId]") {
      // detail post -> list post
      location.push("/hoi-dap");
    } else if (location?.asPath?.includes("/hoi-dap")) {
      // list post -> if no filter: reload and set default grade filter
      if (
        listPostType?.length === 0 &&
        listSubjectChoose?.length === 0 &&
        timeIdChoose === 0 &&
        listGradeChoose?.length === 1
      ) {
        if (!location?.query?.is_filter) {
          if (listGradeChoose[0] !== gradeId) {
            dispatch(questionAndAnswerAction.handleChooseGrade([gradeId]));
          }
          if (!location?.query?.gr || (location?.query?.gr as string) !== String(gradeId)) {
            location.push({
              pathname: location.pathname,
              query: {
                gr: gradeId
              }
            });
          }
        }
      }
    } else if (
      location?.asPath === "/" ||
      location?.asPath?.includes("/thong-ke") ||
      (location?.asPath?.includes("/thi-online") && location.query?.tab === "xep-hang")
    ) {
      // pass
    } else {
      location.push(`${location?.asPath?.replace(grade, value)}`);
      // location.reload();
    }
    onClose(false);
    // update grade len server
    if (userInfos?.member) {
      handleUpdateProfile(value);
    }
  };

  React.useEffect(() => {
    if (open && gradeLists?.length === 0) {
      dispatch(commonActions.handleGetGrade());
    }
  }, [open, gradeLists]);

  React.useEffect(() => {
    if (userInfos?.member?.learning_block_subject !== learningBlockSubject) {
      setLearningBlockSubject(userInfos?.member?.learning_block_subject || "");
    }
    if (userInfos?.member?.exam_block_subject !== examBlockSubject.toString()) {
      setExamBlockSubject(userInfos?.member?.exam_block_subject?.split(",") || []);
    }
  }, [userInfos?.member]);

  React.useEffect(() => {
    const gradeOfUser = gradeLists.find((e: TGradeResponse) => e.slug === grade)?.id;
    if (gradeOfUser && gradeOfUser !== openBlockSubject) setOpenBlockSubject(gradeOfUser);
  }, [grade, gradeLists]);

  return (
    <Dialog
      onClose={isNewMember ? () => {} : (_, reason) => reason !== "backdropClick" && handleClose()}
      open={open}
      fullScreen={isMobile}
      className="overflow-x-hidden"
    >
      <DialogTitle>
        <div className="flex justify-center pt-4 text-black1 text-[19px] font-[500]">
          <FormattedMessage id="GradeSelection.title" />
        </div>
        {isNewMember ? null : (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 4,
              top: 4
            }}
          >
            <XCircle />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className="overflow-x-hidden">
        <Box
          className={`${
            isMobile ? "flex flex-wrap" : "grid md:grid-cols-4 sm:grid-cols-3 sm:w-[310px]"
          } text-black md:w-[560px] md:pt-3 gap-5 md:pl-10 sm:pl-5`}
        >
          {gradeLists
            ?.filter((gr) => LIST_CLASSES.find((cl) => cl.value === gr.id))
            ?.map((ele, idx) => {
              return (
                <Box
                  className={`${
                    openBlockSubject === ele.id && "border rounded-[16px] border-green"
                  } items-center w-[70px] cursor-pointer`}
                  key={`${idx}`}
                  // mb="48px"
                  justifyContent="center"
                  display="flex"
                  flexDirection="column"
                  onClick={() => {
                    eventTracking("select_grade", { slug: ele?.slug }, () => {
                      if (checkOpenBlockSubject(ele?.id) && !isEmpty(userInfos?.member)) {
                        setOpenBlockSubject(ele.id);
                      } else {
                        handleChooseGrade(ele.slug);
                      }
                    });
                  }}
                >
                  {LIST_CLASSES.find((e) => e.slug === ele?.slug)?.icon}
                  <Typography style={{ fontSize: 18 }} className="sm:mt-[8px] text-[15px] font-[400]">
                    {ele?.title}
                  </Typography>
                </Box>
              );
            })}
        </Box>
        <Box className="flex md:pl-10 sm:pl-5 sm:gap-[18px] md:gap-[64px] mt-4 sm:mb-4 md:mb-0">
          {gradeLists
            ?.filter((gr) => SPECIAL_CLASSES.find((cl) => cl.value === gr.id))
            ?.map((ele) => (
              <Box
                className={`sm:mb-0 md:mb-6 text-center ${
                  openBlockSubject === ele.id && "border rounded-[16px] border-green"
                } items-center w-[70px] cursor-pointer`}
                key={ele.id}
                // mb="48px"
                justifyContent="center"
                display="flex"
                flexDirection="column"
                onClick={() => {
                  eventTracking("select_grade", { slug: ele?.slug }, () => {
                    if (checkOpenBlockSubject(ele?.id) && !isEmpty(userInfos?.member)) {
                      setOpenBlockSubject(ele.id);
                    } else {
                      handleChooseGrade(ele.slug);
                    }
                  });
                }}
              >
                {SPECIAL_CLASSES?.find((e) => e.slug === ele?.slug)?.icon}
                <Typography style={{ fontSize: 18 }} className="sm:mt-[8px] text-[15px] font-[400]">
                  {ele?.title}
                </Typography>
              </Box>
            ))}
        </Box>
        {openBlockSubject > 0 && checkOpenBlockSubject(openBlockSubject) && (
          <Box className="border-t-2 border-gray pt-6 pb-4">
            {LIST_GRADE_VALUE_TO_RENDER_BLOCK_SUBJECT?.includes(openBlockSubject) && !isEmpty(userInfos?.member) && (
              <>
                <span className="text-black text-[19px] font-[500] flex justify-center">
                  <FormattedMessage id="common.learningBlockSubjectConfig" />
                </span>
                <div className="flex px-6 justify-between items-center">
                  <span>
                    <FormattedMessage id="common.learningBlockSubject" />
                  </span>
                  <SelectLearningBlock
                    learningBlockSubject={learningBlockSubject}
                    handleChangeLearningBlockSubject={handleChangeLearningBlockSubject}
                  />
                </div>
              </>
            )}
            {LIST_GRADE_VALUE_TO_RENDER_EXAM_BLOCK_SUBJECT.includes(openBlockSubject) && (
              <div className="flex px-6 justify-between items-center">
                <span>
                  <FormattedMessage id="common.examBlockSubject" />
                </span>
                <MultipleSelectCheckBox
                  items={BLOCK_SUBJECTS}
                  option={examBlockSubject}
                  formStyle={{ m: 1 }}
                  handleChange={handleChangeExamBlockSubject}
                  extraItemText={<FormattedMessage id="common.block" />}
                  label={<FormattedMessage id="common.chooseBlockLabel" />}
                  styleSelect={{
                    boxShadow: "0px 2px 6px 0px rgba(16, 24, 40, 0.2)",
                    height: HEIGHT_SELECT_BLOCK_SUBJECT,
                    width: WIDTH_SELECT_BLOCK_SUBJECT,
                    borderRadius: 2,
                    ".MuiOutlinedInput-notchedOutline": { border: 0 }
                  }}
                />
              </div>
            )}
            <div className="flex justify-center mt-4">
              <Button
                sx={{
                  width: "149px",
                  height: "44px",
                  textTransform: "none"
                }}
                className="rounded-full flex justify-center bg-green-gradient items-center"
                onClick={() => {
                  // handleUpdateProfile();
                  eventTracking("select_grade_save_button", { grade_id: grade });
                  handleChooseGrade(gradeLists?.find((e) => e.id === openBlockSubject)?.slug);
                }}
              >
                <span className="text-white text-[13px] font-[500]">
                  <FormattedMessage id="common.save" />
                </span>
              </Button>
            </div>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(ClassesDialog);
