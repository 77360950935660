import React from "react";

export default function IconNoData() {
  return (
    <svg
      width="142"
      height="120"
      viewBox="0 0 142 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        opacity="0.6"
        x1="82.1289"
        y1="110.283"
        x2="115.151"
        y2="110.283"
        stroke="#696784"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        opacity="0.6"
        x1="2"
        y1="117.939"
        x2="110.08"
        y2="117.939"
        stroke="#120E40"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        opacity="0.6"
        x1="127.262"
        y1="22.498"
        x2="139.998"
        y2="22.498"
        stroke="#120E40"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <ellipse
        opacity="0.6"
        cx="116.901"
        cy="63.0331"
        rx="2.28217"
        ry="2.29673"
        fill="#F7871D"
      />
      <ellipse
        opacity="0.6"
        cx="30.1728"
        cy="2.29673"
        rx="2.28217"
        ry="2.29673"
        fill="#006000"
      />
      <ellipse
        opacity="0.6"
        cx="100.165"
        cy="2.29673"
        rx="2.28217"
        ry="2.29673"
        fill="#7800B0"
      />
      <ellipse
        opacity="0.6"
        cx="4.81538"
        cy="80.3866"
        rx="2.28217"
        ry="2.29673"
        fill="#7800B0"
      />
      <path
        d="M53.7214 60.2726C35.237 58.405 9.74698 109.444 11.7849 117.896H72.9544C72.9544 117.896 75.8472 108.86 79.2021 100.178C82.8667 90.691 86.9563 81.3002 85.3574 76.7125C82.2983 67.9107 76.4433 64.1372 76.4433 64.1372L53.7214 60.2726Z"
        fill="#66BE8F"
      />
      <path
        d="M77.5469 90.2969C77.5469 90.2969 80.4721 101.719 85.5948 108.06C88.9698 112.26 92.1176 112.232 99.345 112.232C110.272 112.232 84.0464 80.7129 84.0464 80.7129L77.5469 90.2969Z"
        fill="#FFF3E8"
      />
      <path
        d="M80.8926 96.5816L86.0524 84.5293L97.2389 111.21C95.518 110.638 93.8542 109.905 92.2691 109.021"
        fill="#797979"
      />
      <path
        d="M60.8203 77.1664L67.3616 76.6484L64.7794 90.3385L60.8203 77.1664Z"
        fill="#797979"
      />
      <path
        d="M36.5417 70.2891C31.7713 80.9276 40.6723 97.5479 40.6723 97.5479L44.6407 95.3549C44.9277 96.3478 45.3097 97.3104 45.7811 98.2292C44.7751 99.2183 40.2643 101.374 40.2643 101.374C38.5027 99.167 35.2066 92.1913 34.5204 83.7692C34.1559 79.1792 34.8475 74.5664 36.5417 70.2891Z"
        fill="#797979"
      />
      <path
        d="M73.6113 65.5945C73.6113 65.5945 78.1777 64.386 79.3552 64.9926C80.5328 65.5992 88.6781 78.776 88.6781 78.776L80.8944 96.5815L79.142 88.4253L77.487 91.085L73.6113 65.5945Z"
        fill="#797979"
      />
      <path
        d="M44.6406 95.3537L63.9261 84.4492L70.1846 106.692C70.1846 106.692 72.9939 118.824 63.6479 119.953C52.9667 121.245 44.6406 95.3537 44.6406 95.3537Z"
        fill="#FFF3E8"
      />
      <path
        d="M87.4678 50.0015C90.1937 46.9173 90.2215 46.7353 93.7773 48.4057C94.9131 48.947 94.978 53.6037 92.9567 56.9679C91.4176 59.5295 86.7539 65.1194 86.0909 65.3667C85.7293 65.488 86.93 68.073 88.9096 71.9784C95.2747 84.53 105.553 110.384 101.742 111.831C87.2546 117.318 79.4523 68.5583 78.6039 66.6685C78.1774 65.7353 80.3888 61.5732 82.7067 57.6491C85.0247 53.725 87.4678 50.0015 87.4678 50.0015Z"
        fill="#FFF3E8"
      />
      <path
        d="M83.4062 66.9189L88.9137 71.9535C86.9342 68.0481 85.7335 65.4631 86.0951 65.3418L83.4062 66.9189Z"
        fill="#797979"
      />
      <path
        d="M78.6039 66.6426C78.1774 65.7094 80.3888 61.5473 82.7067 57.6232C85.0247 53.6991 87.4678 49.999 87.4678 49.999C87.4678 49.999 88.905 48.0532 89.6606 49.3224C90.4163 50.5915 89.5354 57.8145 88.0241 59.5876C86.5128 61.3607 78.6039 66.638 78.6039 66.638"
        fill="#797979"
      />
      <path
        d="M69.5975 72.4922L53.7148 65.6191L67.3584 76.6496L69.5975 72.4922Z"
        fill="#797979"
      />
      <path
        d="M78.7949 37.0376C83.4962 35.7699 86.0852 30.1515 84.5775 24.4887C83.0697 18.8258 78.0362 15.2629 73.3349 16.5307C68.6335 17.7984 66.0445 23.4168 67.5523 29.0797C69.06 34.7425 74.0935 38.3054 78.7949 37.0376Z"
        fill="#676681"
      />
      <path
        d="M67.003 27.7873C72.6293 26.2701 76.306 21.7188 75.2151 17.6216C74.1243 13.5245 68.6789 11.433 63.0526 12.9502C57.4263 14.4673 53.7496 19.0187 54.8404 23.1158C55.9313 27.213 61.3767 29.3045 67.003 27.7873Z"
        fill="#676681"
      />
      <path
        d="M54.6784 35.1066C60.3048 33.5895 63.9138 28.7839 62.7394 24.3731C61.565 19.9623 56.052 17.6166 50.4256 19.1338C44.7993 20.6509 41.1903 25.4565 42.3647 29.8673C43.5391 34.2781 49.0521 36.6238 54.6784 35.1066Z"
        fill="#676681"
      />
      <path
        d="M40.8342 34.5645C39.5426 46.2792 42.7955 52.6383 50.6144 55.2542L55.0696 46.6426L46.8711 31.0284L40.8342 34.5645Z"
        fill="#8F6666"
      />
      <path
        d="M59.5781 44.8203L56.8512 66.809L72.3409 81.8466L78.743 55.45L59.5781 44.8203Z"
        fill="#F2D9C3"
      />
      <path
        d="M48.6348 50.2207L53.3263 58.4609L56.2562 52.1804L48.6348 50.2207Z"
        fill="#676681"
      />
      <path
        d="M74.1818 73.3721C74.1818 73.3721 61.98 71.1557 61.623 57.293L74.3672 61.4924L74.1818 73.3721Z"
        fill="#F2D9C3"
      />
      <path
        d="M72.8193 21.7101C80.3918 27.2038 83.6068 36.2686 83.6068 36.2686L77.6888 38.9175L69.0242 25.284L72.8193 21.7101Z"
        fill="#676681"
      />
      <path
        d="M47.4704 37.3702L51.262 44.2023L52.9931 47.315L57.6189 55.6757C58.8566 57.8902 60.6352 59.7501 62.7865 61.0796C64.9378 62.409 67.3905 63.164 69.9125 63.273L77.8869 63.6249C79.5012 63.6937 81.1103 63.3974 82.5958 62.7578C84.0813 62.1183 85.4054 61.1517 86.4709 59.9293C87.5363 58.7068 88.316 57.2595 88.7525 55.6938C89.189 54.1281 89.2713 52.4838 88.9932 50.8818C88.1318 45.8439 86.6042 40.1065 83.1056 34.5189C81.1049 31.3606 78.388 28.7252 75.1783 26.8295C71.9685 24.9338 68.3576 23.832 64.6424 23.6146L64.6155 23.6218L63.343 23.5834L63.2048 23.5868C62.5774 23.5918 61.9392 23.6286 61.3116 23.6867C59.7846 23.8236 58.2752 24.1157 56.8066 24.5586L52.0838 25.9819C50.9612 26.3208 49.9284 26.9084 49.061 27.7015C48.1936 28.4947 47.5138 29.4733 47.0713 30.5656C46.6288 31.6578 46.435 32.8359 46.5041 34.0134C46.5731 35.1909 46.9033 36.3379 47.4704 37.3702Z"
        fill="#FFF3E8"
      />
      <path
        d="M85.6252 45.8751C89.048 44.9521 90.9985 41.1085 89.9819 37.2901C88.9652 33.4717 85.3664 31.1246 81.9436 32.0475C78.5208 32.9705 76.5702 36.8142 77.5869 40.6325C78.6035 44.4509 82.2024 46.7981 85.6252 45.8751Z"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M81.059 37.824C81.5219 39.0759 81.2728 40.3217 80.4962 40.6132C79.7195 40.9048 78.7157 40.1272 78.254 38.8798C77.7923 37.6324 78.0414 36.3866 78.818 36.0951C79.5947 35.8035 80.5973 36.5766 81.059 37.824Z"
        fill="#797979"
      />
      <path
        d="M76.1087 39.7921C78.7324 43.2292 81.5811 43.3112 83.4198 42.8444C83.9898 42.6985 84.5934 42.7726 85.1119 43.052C85.6304 43.3315 86.0262 43.7961 86.2216 44.3544C86.4856 45.0992 86.5293 45.9051 86.3474 46.6744C86.1655 47.4437 85.7657 48.1433 85.1966 48.6885L81.0726 52.6313C80.491 53.0182 79.8356 53.2786 79.1483 53.3957C78.461 53.5129 77.7569 53.4844 77.0812 53.3119C76.4055 53.1394 75.773 52.8268 75.2242 52.3942C74.6754 51.9615 74.2224 51.4182 73.8943 50.7991L69.189 41.9237L76.1087 39.7921Z"
        fill="#FFF3E8"
      />
      <path
        d="M82.688 51.5289L82.2472 51.0632L84.9712 48.4593C85.4953 47.9566 85.8637 47.3119 86.0318 46.6031C86.1999 45.8943 86.1606 45.1517 85.9186 44.4649C85.7462 43.9876 85.4035 43.592 84.9571 43.3551C84.5108 43.1183 83.9928 43.0572 83.5041 43.1837C81.5075 43.6883 78.5623 43.5502 75.8605 40.0182L76.3656 39.6259C78.8604 42.8853 81.5414 43.0222 83.3511 42.5729C83.9975 42.4085 84.6817 42.493 85.2695 42.8099C85.8573 43.1268 86.3063 43.6532 86.5285 44.2859C86.8128 45.0901 86.8591 45.9599 86.6618 46.79C86.4645 47.6201 86.0321 48.3747 85.417 48.9622L82.688 51.5289Z"
        fill="#F1BCAD"
      />
      <path
        d="M70.8454 42.1199C71.3128 43.3706 71.0593 44.6177 70.2826 44.9092C69.506 45.2008 68.5022 44.4232 68.0405 43.1758C67.5788 41.9284 67.8278 40.6826 68.6033 40.3865C69.3788 40.0905 70.3837 40.8726 70.8454 42.1199Z"
        fill="#797979"
      />
      <path
        d="M65.3417 39.7204C61.8182 42.4578 60.184 40.7006 62.5655 38.1986C65.1272 35.5079 69.7211 36.3222 65.3417 39.7204Z"
        fill="#8F6666"
      />
      <path
        d="M76.2635 34.7739C73.0349 35.1035 73.3681 32.9123 75.9525 32.1816C78.7249 31.4002 80.2769 34.3631 76.2635 34.7739Z"
        fill="#8F6666"
      />
      <path
        d="M79.9573 54.9138L72.5723 54.3398C72.5723 54.3398 73.4021 58.6326 75.5439 59.0992C77.8758 59.6031 79.9573 54.9138 79.9573 54.9138Z"
        fill="white"
      />
      <path
        d="M70.7971 53.2872C74.2198 52.3642 76.1704 48.5206 75.1538 44.7022C74.1371 40.8839 70.5382 38.5367 67.1155 39.4597C63.6927 40.3826 61.7421 44.2263 62.7588 48.0446C63.7754 51.863 67.3743 54.2102 70.7971 53.2872Z"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M62.4262 46.7474L54.9688 46.6426"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M55.2159 25.0268C61.3339 32.9175 57.5235 46.9973 42.5099 50.0942C32.9159 39.117 30.1579 27.731 55.2159 25.0268Z"
        fill="#676681"
      />
      <path
        d="M56.7324 46.7909C35.7384 31.951 35.3947 64.6214 58.4567 56.62L56.7324 46.7909Z"
        fill="#FFF3E8"
      />
      <path
        d="M52.1213 34.4739L56.989 51.6384L55.8083 52.9905L45.9898 37.8712L52.1213 34.4739Z"
        fill="#676681"
      />
      <path
        d="M51.2954 28.0789C64.4076 29.5572 74.1878 22.7753 74.1878 22.7753C74.1878 22.7753 67.7044 14.2875 51.2954 28.0789Z"
        fill="#676681"
      />
      <path
        d="M36.8136 34.0816C35.1972 28.0106 29.3148 32.7223 34.932 35.415C26.6216 36.487 35.3589 44.8597 38.114 40.3537"
        fill="#676681"
      />
      <path
        d="M52.6696 62.8931C46.4482 59.8555 39.4155 63.8683 36.5366 70.2887C31.7662 80.9272 40.6672 97.5522 40.6672 97.5522L44.6355 95.3546L65.8681 85.5559C65.8681 85.5559 62.1964 67.5544 52.6696 62.8931Z"
        fill="#66BE8F"
      />
      <path
        d="M64.9826 83.5176C62.5394 94.3054 59.1228 101.855 60.2493 110.202C60.4115 111.397 60.787 118.429 58.2048 113.123C52.2245 100.782 64.9826 83.5176 64.9826 83.5176Z"
        fill="#797979"
      />
      <path
        d="M53.7173 65.6169C51.3298 63.4519 52.3543 57.386 56.4478 58.7532C60.5413 60.1203 65.3117 60.125 66.3316 61.6694C67.3515 63.2139 67.5277 66.1301 67.5277 66.1301C67.5277 66.1301 74.8571 68.1879 74.8571 70.4182C74.8571 72.6486 77.2214 97.2665 76.8042 106.696C76.4472 114.843 76.2571 119.976 62.9845 119.976C55.1034 119.976 61.5242 98.8669 64.9965 83.5204C66.4568 77.0719 67.6946 72.4759 67.6946 72.4759C67.6946 72.4759 56.6054 68.2345 53.7173 65.6169Z"
        fill="#FFF3E8"
      />
      <path
        d="M67.5294 66.1302C67.5294 66.1302 67.3579 63.214 66.3334 61.6695C65.3088 60.1251 61.4842 60.3957 57.3953 59.0239C57.3953 59.0239 55.1052 56.7516 53.3574 59.6118"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.3191 48.1869C90.2964 47.9302 89.5547 54.6493 89.5547 54.6493C89.8189 57.2436 91.4693 55.9884 91.8727 55.3119"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.0972 65.3418C83.4547 67.8568 81.248 66.3776 81.248 66.3776"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5293 66.6895C58.9201 67.254 61.0711 67.7206 62.0076 67.0627"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.6771 64.4514C60.6771 64.4514 60.4592 60.6019 62.4248 60.252"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.577 65.3203C66.577 65.3203 64.7829 64.2704 66.336 61.6621"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.429 62.3619C55.429 62.3619 55.0906 59.413 57.2834 59.0957"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.6621 49.1836C45.6621 49.1836 49.2888 49.5196 50.9369 51.5098C52.5851 53.4999 50.6274 53.8443 50.6274 53.8443"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.6788 51.8316C47.6788 51.8316 49.6674 50.9185 50.9525 51.5091"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8125 92.7499C40.8125 92.7499 53.501 84.4771 64.2239 82.1348"
        stroke="#797979"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
