/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { uniqBy } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxChecked, CheckBoxNone } from "../../../../public/assets/icons/CheckBox";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { TTheme } from "../../../utils/configs/setupTheme";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import { accountActions } from "../../account/redux/actions";
// import { data as cities } from "../../account/utils/data";
import { IQuestionAndAnswer } from "../../questionAndAnswer/utils/types";
import Placeholder from "../components/Elements/Placeholder";
import {
  // MAXIMUM_TEACHER_ACHIEVED_ACHIEVEMENT_LENGTH,
  // MAXIMUM_TEACHER_CERTIFICATE_LENGTH,
  // MAXIMUM_TEACHER_EXPERIENCE_LENGTH,
  MAXIMUM_TEACHER_NAME_LENGTH,
  MAXIMUM_TEACHER_SCHOOL_LENGTH,
  emailRegex,
  phoneRegex,
  removeNullAttributeFromObject
} from "../utils/constant";
import { TTeacherInfo } from "../utils/type";

type TeacherRegisterFormDialogProps = {
  open: boolean;
  onClose: () => void;
};

const TeacherRegisterFormDialog = ({ open, onClose }: TeacherRegisterFormDialogProps) => {
  const theme: TTheme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const router = useRouter();

  const { listSubjectAndGrade } = useSelector(
    (state: { questionAndAnswer: IQuestionAndAnswer }) => state.questionAndAnswer
  );

  const [teacherInfo, setTeacherInfo] = useState<TTeacherInfo>({
    phone: "",
    gradeIds: [],
    subjectId: 0
  });
  const [errorText, setErrorText] = React.useState<any>({});
  const [listIdDisable, setListIdDisable] = useState<{ grades: number[]; subjects: number[] }>({
    grades: [],
    subjects: []
  });

  const { listGrade, listSubject, listSubjectUniqByID } = useMemo(() => {
    const listSubjectAll = listSubjectAndGrade.filter((item) => item.parent_id);
    return {
      listGrade: listSubjectAndGrade.filter((item) => !item.parent_id),
      listSubject: listSubjectAll,
      listSubjectUniqByID: uniqBy(listSubjectAll, (e) => e.id)
    };
  }, [listSubjectAndGrade]);

  const handleClose = () => {
    setTeacherInfo({ phone: "", gradeIds: [], subjectId: 0 });
    onClose();
  };

  const validateFullName = (value: string, isShowErrorMessage = true): boolean => {
    let errorMessage;
    if (value.trim().length > MAXIMUM_TEACHER_NAME_LENGTH) {
      errorMessage = intl.formatMessage(
        { id: "Dialog.TeacherRegisterForm.fieldError.max" },
        { nums: MAXIMUM_TEACHER_NAME_LENGTH }
      );
    }

    if (errorMessage) {
      if (isShowErrorMessage) {
        setErrorText((prevState) => ({
          ...prevState,
          fullName: errorMessage
        }));
      }
      return false;
    }
    return true;
  };

  const validatePhone = (value: string, isShowErrorMessage = true): boolean => {
    let errorMessage;
    if (value.trim() === "") {
      errorMessage = intl.formatMessage({ id: "Dialog.TeacherRegisterForm.fieldError.required" });
    } else if (!phoneRegex.test(value.trim())) {
      errorMessage = intl.formatMessage({ id: "Dialog.TeacherRegisterForm.phoneField.error.invalid" });
    }

    if (errorMessage) {
      if (isShowErrorMessage) {
        setErrorText((prevState) => ({
          ...prevState,
          phone: errorMessage
        }));
      }
      return false;
    }
    return true;
  };

  const validateEmail = (value: string, isShowErrorMessage = true): boolean => {
    let errorMessage;
    if (value.trim() !== "" && !emailRegex.test(value.trim())) {
      errorMessage = intl.formatMessage({ id: "Dialog.TeacherRegisterForm.emailField.error.invalid" });
    }

    if (errorMessage) {
      if (isShowErrorMessage) {
        setErrorText((prevState) => ({
          ...prevState,
          email: errorMessage
        }));
      }
      return false;
    }
    return true;
  };

  const validateSchool = (value: string, isShowErrorMessage = true): boolean => {
    let errorMessage;
    if (value.trim().length > MAXIMUM_TEACHER_SCHOOL_LENGTH) {
      errorMessage = intl.formatMessage(
        { id: "Dialog.TeacherRegisterForm.fieldError.max" },
        { nums: MAXIMUM_TEACHER_SCHOOL_LENGTH }
      );
    }

    if (errorMessage) {
      if (isShowErrorMessage) {
        setErrorText((prevState) => ({
          ...prevState,
          school: errorMessage
        }));
      }
      return false;
    }
    return true;
  };

  // const validateExperience = (value: string, isShowErrorMessage = true): boolean => {
  //   let errorMessage;
  //   if (value.trim().length > MAXIMUM_TEACHER_EXPERIENCE_LENGTH) {
  //     errorMessage = intl.formatMessage(
  //       { id: "Dialog.TeacherRegisterForm.fieldError.max" },
  //       { nums: MAXIMUM_TEACHER_EXPERIENCE_LENGTH }
  //     );
  //   }

  //   if (errorMessage) {
  //     if (isShowErrorMessage) {
  //       setErrorText((prevState) => ({
  //         ...prevState,
  //         experience: errorMessage
  //       }));
  //     }
  //     return false;
  //   }
  //   return true;
  // };

  // const validateCertificate = (value: string, isShowErrorMessage = true): boolean => {
  //   let errorMessage;
  //   if (value.trim().length > MAXIMUM_TEACHER_CERTIFICATE_LENGTH) {
  //     errorMessage = intl.formatMessage(
  //       { id: "Dialog.TeacherRegisterForm.fieldError.max" },
  //       { nums: MAXIMUM_TEACHER_CERTIFICATE_LENGTH }
  //     );
  //   }

  //   if (errorMessage) {
  //     if (isShowErrorMessage) {
  //       setErrorText((prevState) => ({
  //         ...prevState,
  //         certificate: errorMessage
  //       }));
  //     }
  //     return false;
  //   }
  //   return true;
  // };

  // const validateAchievedAchievements = (value: string, isShowErrorMessage = true): boolean => {
  //   let errorMessage;
  //   if (value.trim().length > MAXIMUM_TEACHER_ACHIEVED_ACHIEVEMENT_LENGTH) {
  //     errorMessage = intl.formatMessage(
  //       { id: "Dialog.TeacherRegisterForm.fieldError.max" },
  //       { nums: MAXIMUM_TEACHER_ACHIEVED_ACHIEVEMENT_LENGTH }
  //     );
  //   }

  //   if (errorMessage) {
  //     if (isShowErrorMessage) {
  //       setErrorText((prevState) => ({
  //         ...prevState,
  //         achievedAchievements: errorMessage
  //       }));
  //     }
  //     return false;
  //   }
  //   return true;
  // };

  const validateGradeIds = (value: any, isShowErrorMessage = true): boolean => {
    let errorMessage;
    if (!(value as number[]).length) {
      errorMessage = intl.formatMessage({ id: "Dialog.TeacherRegisterForm.fieldError.required" });
    }

    if (errorMessage) {
      if (isShowErrorMessage) {
        setErrorText((prevState) => ({
          ...prevState,
          gradeIds: errorMessage
        }));
      }
      return false;
    }
    return true;
  };

  const validateSubjectId = (value: any, isShowErrorMessage = true): boolean => {
    let errorMessage;
    if (!value) {
      errorMessage = intl.formatMessage({ id: "Dialog.TeacherRegisterForm.fieldError.required" });
    }

    if (errorMessage) {
      if (isShowErrorMessage) {
        setErrorText((prevState) => ({
          ...prevState,
          subjectId: errorMessage
        }));
      }
      return false;
    }
    return true;
  };

  const validateBeforeSubmit = (teacher: TTeacherInfo, isShowErrorMessage = true) => {
    const isValidName = validateFullName(teacher.fullName || "", isShowErrorMessage);
    const isValidPhone = validatePhone(teacher.phone, isShowErrorMessage);
    const isValidEmail = validateEmail(teacher.email || "", isShowErrorMessage);
    const isValidSchool = validateSchool(teacher.school || "", isShowErrorMessage);
    // const isValidExperience = validateExperience(teacher.experience || "", isShowErrorMessage);
    // const isValidCertificate = validateCertificate(teacher.certificate || "", isShowErrorMessage);
    // const isValidAchievedAchievements = validateAchievedAchievements(
    //   teacher.achievedAchievements || "",
    //   isShowErrorMessage
    // );
    const isValidClasses = validateGradeIds(teacher.gradeIds, isShowErrorMessage);
    const isValidSubject = validateSubjectId(teacher.subjectId, isShowErrorMessage);

    return (
      isValidName &&
      isValidPhone &&
      isValidEmail &&
      isValidSchool &&
      // isValidExperience &&
      // isValidCertificate &&
      // isValidAchievedAchievements &&
      isValidClasses &&
      isValidSubject
    );
  };

  const isDisableButtonSubmit = useMemo(() => {
    return !validateBeforeSubmit(teacherInfo, false);
  }, [teacherInfo, errorText]);

  const handleChange = (field: string) => {
    return (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number | number[] | string>
    ) => {
      const { value } = event.target;

      setErrorText((prevState) => ({
        ...prevState,
        [field]: undefined
      }));

      switch (field) {
        case "gradeIds":
          if ((value as []).length > 3) return;
          setListIdDisable((prevState) => {
            const listSubjectIdAvailable = listSubjectUniqByID.reduce((rs, subject) => {
              const isOk = (value as []).every(
                (item) => listSubject.findIndex((sj) => sj.id === subject.id && sj.parent_id === item) > -1
              );
              if (isOk) return [...rs, subject.id];
              return rs;
            }, [] as number[]);

            const listSubjectIdDisable = listSubjectUniqByID
              .filter((e) => !listSubjectIdAvailable.includes(e.id))
              .map((e) => e.id);

            return {
              ...prevState,
              subjects: listSubjectIdDisable
            };
          });
          break;
        case "subjectId":
          setListIdDisable((prevState) => {
            const listGradeIdAvailable = listSubject
              .filter((subject) => subject.id === value)
              .map((e) => e.parent_id as number);

            const listGradeIdDisable = listGrade
              .filter((grade) => !listGradeIdAvailable.includes(grade.id))
              .map((e) => e.id);

            return {
              ...prevState,
              grades: listGradeIdDisable
            };
          });
          break;
        case "fullName":
          validateFullName(value as string);
          break;
        case "school":
          validateSchool(value as string);
          break;
        // case "experience":
        //   validateExperience(value as string);
        //   break;
        // case "certificate":
        //   validateCertificate(value as string);
        //   break;
        // case "achievedAchievements":
        //   validateAchievedAchievements(value as string);
        // break;
        default:
          break;
      }

      setTeacherInfo((prevState) => ({
        ...prevState,
        [field]: value
      }));
    };
  };

  const handleSubmit = () => {
    if (validateBeforeSubmit(teacherInfo)) {
      const paramsGenSignature = removeNullAttributeFromObject({
        full_name: teacherInfo.fullName && teacherInfo.fullName !== "" ? teacherInfo.fullName.trim() : undefined,
        phone: teacherInfo.phone.trim(),
        email: teacherInfo.email && teacherInfo.email !== "" ? teacherInfo.email.trim() : undefined,
        school: teacherInfo.school && teacherInfo.school !== "" ? teacherInfo.school.trim() : undefined,
        // city: teacherInfo.city && teacherInfo.city !== "" ? teacherInfo.city : undefined,
        // experience: teacherInfo.experience && teacherInfo.experience !== "" ? teacherInfo.experience.trim() : undefined,
        grades_subjects_id: JSON.stringify(
          teacherInfo.gradeIds.map((gradeId) => [gradeId, teacherInfo.subjectId])
        ).replace(/,/g, ", "),
        // certificate:
        //   teacherInfo.certificate && teacherInfo.certificate !== "" ? teacherInfo.certificate.trim() : undefined,
        // achieved_achievements:
        //   teacherInfo.achievedAchievements && teacherInfo.achievedAchievements !== ""
        //     ? teacherInfo.achievedAchievements.trim()
        //     : undefined,
        nonce: getNonce(),
        timestamp: moment().unix()
      });

      const params = {
        ...paramsGenSignature,
        signature: genSignature(paramsGenSignature, undefined, undefined, ["grades_subjects_id"])
      };

      dispatch(
        accountActions.handleCreateTeacherInfo(params, () => {
          onClose();
          dispatch(accountActions.handleGetTeacherInfo());
        })
      );

      eventTracking("action_submit_register", {
        from: router.asPath === "/" ? "home" : router.asPath === "/tai-khoan" ? "general account" : "link"
      });
    }
  };

  useEffect(() => {
    eventTracking("screen_dl_register_teacher", {
      from: router.asPath === "/" ? "home" : router.asPath === "/tai-khoan" ? "general account" : "link"
    });
  }, []);

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          width: { sm: 560, xs: 350 },
          borderRadius: 4
        }
      }}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
      }}
    >
      <DialogTitle className="px-4 py-6">
        <Typography className="text-[19px] font-semibold text-black1 text-center">
          <FormattedMessage id="Dialog.TeacherRegisterForm.title" />
        </Typography>
      </DialogTitle>

      <DialogContent className="flex flex-col gap-y-6 px-4">
        <Box>
          <InputLabel className="text-gray400 text-[14px] mb-2">
            <FormattedMessage id="Dialog.TeacherRegisterForm.fullNameField.label" />
          </InputLabel>
          <TextField
            placeholder={intl.formatMessage({ id: "Dialog.TeacherRegisterForm.fullNameField.placeholder" })}
            InputProps={{
              style: {
                height: 40,
                fontSize: 16
              }
            }}
            value={teacherInfo.fullName}
            onChange={handleChange("fullName")}
            error={errorText?.fullName && errorText?.fullName !== ""}
            helperText={errorText?.fullName === "" ? undefined : errorText?.fullName}
            FormHelperTextProps={{ className: "mx-0" }}
            className="w-full placeholder:text-[16px]"
          />
        </Box>
        <Box>
          <InputLabel className="text-gray400 text-[14px] mb-2">
            <FormattedMessage id="Dialog.TeacherRegisterForm.phoneField.label" />
            <span className="text-red">*</span>
          </InputLabel>
          <TextField
            required
            placeholder={intl.formatMessage({ id: "Dialog.TeacherRegisterForm.phoneField.placeholder" })}
            InputProps={{
              style: {
                height: 40,
                fontSize: 16
              }
            }}
            value={teacherInfo.phone}
            onChange={handleChange("phone")}
            onBlur={(event) => validatePhone(event.target.value)}
            error={errorText?.phone && errorText?.phone !== ""}
            helperText={errorText?.phone === "" ? undefined : errorText?.phone}
            FormHelperTextProps={{ className: "mx-0" }}
            className="w-full placeholder:text-[16px]"
          />
        </Box>
        <Box>
          <InputLabel className="text-gray400 text-[14px] mb-2">
            <FormattedMessage id="Dialog.TeacherRegisterForm.emailField.label" />
          </InputLabel>
          <TextField
            placeholder={intl.formatMessage({ id: "Dialog.TeacherRegisterForm.emailField.placeholder" })}
            InputProps={{
              style: {
                height: 40,
                fontSize: 16
              }
            }}
            onChange={handleChange("email")}
            onBlur={(event) => validateEmail(event.target.value)}
            value={teacherInfo.email}
            error={errorText?.email && errorText?.email !== ""}
            helperText={errorText?.email === "" ? undefined : errorText?.email}
            FormHelperTextProps={{ className: "mx-0" }}
            className="w-full placeholder:text-[16px]"
          />
        </Box>
        <Box>
          <InputLabel className="text-gray400 text-[14px] mb-2">
            <FormattedMessage id="Dialog.TeacherRegisterForm.schoolField.label" />
          </InputLabel>
          <TextField
            placeholder={intl.formatMessage({ id: "Dialog.TeacherRegisterForm.schoolField.placeholder" })}
            InputProps={{
              style: {
                height: 40,
                fontSize: 16
              }
            }}
            value={teacherInfo.school}
            onChange={handleChange("school")}
            onBlur={(event) => validateSchool(event.target.value)}
            error={errorText?.school && errorText?.school !== ""}
            helperText={errorText?.school === "" ? undefined : errorText?.school}
            FormHelperTextProps={{ className: "mx-0" }}
            className="w-full placeholder:text-[16px]"
          />
        </Box>
        {/* <Box>
          <InputLabel className="text-gray400 text-[14px] mb-2">
            <FormattedMessage id="Dialog.TeacherRegisterForm.cityField.label" />
          </InputLabel>
          <Select
            size="small"
            value={teacherInfo.city}
            onChange={handleChange("city")}
            displayEmpty
            className="w-full"
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.primary.light
                },
                "&& .Mui-selected[aria-selected=true]": {
                  backgroundColor: theme.palette.primary.light
                },
                "&& .MuiPaper-root": {
                  maxHeight: 300
                }
              }
            }}
            renderValue={(value) =>
              value || (
                <Placeholder>
                  <FormattedMessage id="Dialog.TeacherRegisterForm.cityField.placeholder" />
                </Placeholder>
              )
            }
          >
            {cities.map((city) => (
              <MenuItem key={city.id} value={city.name}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel className="text-gray400 text-[14px] mb-2">
            <FormattedMessage id="Dialog.TeacherRegisterForm.experienceField.label" />
          </InputLabel>
          <TextField
            placeholder={intl.formatMessage({ id: "Dialog.TeacherRegisterForm.experienceField.placeholder" })}
            InputProps={{
              style: {
                height: 40,
                fontSize: 16
              }
            }}
            value={teacherInfo.experience}
            onChange={handleChange("experience")}
            onBlur={(event) => validateExperience(event.target.value)}
            error={errorText?.experience && errorText?.experience !== ""}
            helperText={errorText?.experience === "" ? undefined : errorText?.experience}
            FormHelperTextProps={{ className: "mx-0" }}
            className="w-full placeholder:text-[16px]"
          />
        </Box>
        <Box>
          <InputLabel className="text-gray400 text-[14px] mb-2">
            <FormattedMessage id="Dialog.TeacherRegisterForm.certificateField.label" />
          </InputLabel>
          <TextField
            placeholder={intl.formatMessage({ id: "Dialog.TeacherRegisterForm.certificateField.placeholder" })}
            InputProps={{
              style: {
                height: 40,
                fontSize: 16
              }
            }}
            value={teacherInfo.certificate}
            onChange={handleChange("certificate")}
            onBlur={(event) => validateCertificate(event.target.value)}
            error={errorText?.certificate && errorText?.certificate !== ""}
            helperText={errorText?.certificate === "" ? undefined : errorText?.certificate}
            FormHelperTextProps={{ className: "mx-0" }}
            className="w-full placeholder:text-[16px]"
          />
        </Box>
        <Box>
          <InputLabel className="text-gray400 text-[14px] mb-2">
            <FormattedMessage id="Dialog.TeacherRegisterForm.achievedAchievementsField.label" />
          </InputLabel>
          <TextField
            placeholder={intl.formatMessage({ id: "Dialog.TeacherRegisterForm.achievedAchievementsField.placeholder" })}
            InputProps={{
              style: {
                height: 40,
                fontSize: 16
              }
            }}
            value={teacherInfo.achievedAchievements}
            onChange={handleChange("achievedAchievements")}
            onBlur={(event) => validateAchievedAchievements(event.target.value)}
            error={errorText?.achievedAchievements && errorText?.achievedAchievements !== ""}
            helperText={errorText?.achievedAchievements === "" ? undefined : errorText?.achievedAchievements}
            FormHelperTextProps={{ className: "mx-0" }}
            className="w-full placeholder:text-[16px]"
          />
        </Box> */}
        <Box>
          <Box className="flex justify-between items-center">
            <InputLabel className="text-gray400 text-[14px] mb-2">
              <FormattedMessage id="Dialog.TeacherRegisterForm.gradeIdsField.label" />
              <span className="text-red">*</span>
            </InputLabel>
            <Select
              size="small"
              value={teacherInfo.gradeIds}
              onChange={handleChange("gradeIds")}
              onBlur={(event) => validateGradeIds(event.target.value)}
              error={errorText?.gradeIds && errorText?.gradeIds !== ""}
              displayEmpty
              multiple
              required
              className="w-3/5"
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: theme.palette.primary.light
                  },
                  "&& .Mui-selected[aria-selected=true]": {
                    backgroundColor: theme.palette.primary.light
                  }
                }
              }}
              renderValue={(selected) => {
                if (!selected.length)
                  return (
                    <Placeholder>
                      <FormattedMessage id="Dialog.TeacherRegisterForm.gradeIdsField.placeholder" />
                    </Placeholder>
                  );
                return listGrade
                  .filter((grade) => selected.includes(grade.id))
                  .map((grade) => grade.title)
                  .join(", ");
              }}
            >
              {listGrade.map((grade) => (
                <MenuItem key={grade.id} value={grade.id} disabled={listIdDisable.grades.includes(grade.id)}>
                  <Checkbox
                    checked={teacherInfo.gradeIds.includes(grade.id)}
                    sx={{
                      "&.Mui-checked": {
                        color: theme.palette.primary.main
                      }
                    }}
                    icon={<CheckBoxNone />}
                    checkedIcon={<CheckBoxChecked />}
                  />
                  {grade.title}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="w-3/5 float-right">
            <FormHelperText className="text-red1">
              {errorText?.gradeIds === "" ? undefined : errorText?.gradeIds}
            </FormHelperText>
          </Box>
        </Box>
        <Box>
          <Box className="flex justify-between items-center">
            <InputLabel className="text-gray400 text-[14px] mb-2">
              <FormattedMessage id="Dialog.TeacherRegisterForm.subjectIdField.label" />
              <span className="text-red">*</span>
            </InputLabel>
            <Select
              size="small"
              value={teacherInfo.subjectId}
              onChange={handleChange("subjectId")}
              onBlur={(event) => validateSubjectId(event.target.value)}
              error={errorText?.subjectId && errorText?.subjectId !== ""}
              displayEmpty
              required
              className="w-3/5"
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: theme.palette.primary.light
                  },
                  "&& .Mui-selected[aria-selected=true]": {
                    backgroundColor: theme.palette.primary.light
                  }
                }
              }}
              renderValue={(selected) => {
                if (!selected)
                  return (
                    <Placeholder>
                      <FormattedMessage id="Dialog.TeacherRegisterForm.subjectIdField.placeholder" />
                    </Placeholder>
                  );
                return listSubjectUniqByID.find((subject) => subject.id === selected)?.title;
              }}
            >
              {listSubjectUniqByID.map((subject) => (
                <MenuItem key={subject.id} value={subject.id} disabled={listIdDisable.subjects.includes(subject.id)}>
                  {subject.title}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="w-3/5 float-right">
            <FormHelperText className="text-red1">
              {errorText?.subjectId === "" ? undefined : errorText?.subjectId}
            </FormHelperText>
          </Box>
        </Box>

        <hr className="text-gray" />

        <Typography className="text-[12px] text-black1">
          <span className="text-red">*</span> <FormattedMessage id="Dialog.TeacherRegisterForm.requiredInfomation" />
        </Typography>
      </DialogContent>

      <DialogActions className="flex justify-around py-6 px-4 gap-x-4">
        <Button onClick={handleClose} className="flex-1">
          <Typography className="normal-case text-green text-[14px] font-semibold">
            <FormattedMessage id="Dialog.TeacherRegisterForm.action.cancel" />
          </Typography>
        </Button>
        <Button
          className={`rounded-[100px] flex-1 h-[44px] m-0 ${isDisableButtonSubmit ? "bg-gray" : "bg-green-gradient"}`}
          disabled={isDisableButtonSubmit}
          onClick={handleSubmit}
        >
          <Typography className="normal-case text-white text-[14px] font-semibold">
            <FormattedMessage id="Dialog.TeacherRegisterForm.action.register" />
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeacherRegisterFormDialog;
