import React from "react";
import Modal from "@mui/material/Modal";
import { Box, AppBar, Divider } from "@mui/material";
import XCircle from "../../../../public/assets/icons/XCircle";
import ArrowLeft from "../../../../public/assets/icons/ArrowLeft";

const modalStyle = (minHeight) => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "background.paper",
    border: "1px solid #ccc",
    borderRadius: 1,
    maxHeight: "90vh",
    minHeight: minHeight || "80vh",
    overflowY: "auto"
  };
};

export default function CXModal({
  children,
  title,
  open,
  handleClose,
  subtitle,
  showArrowLeft = false,
  handleBack = () => {},
  minHeight = "80vh",
  isShowDivider = true,
  padding = 80,
  className,
  isHeader = true,
  paddingNone = false
}: any) {
  return (
    <div>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiModal-root": {
            outline: "none"
          }
        }}
      >
        <Box
          sx={modalStyle(minHeight)}
          className={`sm:w-[90vw] md:max-w-[640px] scrollbar-hide text-black overflow-y-auto overflow-x-hidden ${className}`}
        >
          {isHeader && (
            <AppBar
              position="sticky"
              elevation={0}
              style={{
                display: "flex",
                height: subtitle ? 110 : padding,
                backgroundColor: "white",
                width: "100%",
                paddingTop: 24
              }}
            >
              <div className="flex flex-col px-2">
                <div className="flex items-center mb-[20px]">
                  {showArrowLeft && (
                    <button type="button" className="w-[30px]" onClick={handleBack}>
                      <ArrowLeft />
                    </button>
                  )}
                  <span
                    id="createTitle"
                    className={`font-semibold text-lg text-center text-black ${
                      showArrowLeft ? "w-[70%] md:ml-[61px] sm:ml-5" : "w-full"
                    }`}
                  >
                    {title}
                  </span>
                </div>
                {subtitle}
              </div>

              <button
                type="button"
                onClick={handleClose}
                style={{
                  position: "absolute",
                  right: 7,
                  top: 9,
                  background: "white"
                }}
              >
                <XCircle />
              </button>
              {isShowDivider && <Divider />}
            </AppBar>
          )}
          {paddingNone ? (
            <Box>{children}</Box>
          ) : (
            <Box className={`md:px-6 sm:px-0 ${isShowDivider && "md:py-4 sm:py-2"}`}>{children}</Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
