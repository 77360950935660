import React from "react";

export default function Star() {
  return (
    <svg width="16" height="16" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86447 1.25501L6.74447 3.01501C6.86447 3.26001 7.18447 3.49501 7.45447 3.54001L9.04947 3.80501C10.0695 3.97501 10.3095 4.71501 9.57447 5.44501L8.33447 6.68501C8.12447 6.89501 8.00947 7.30001 8.07447 7.59001L8.42947 9.12501C8.70947 10.34 8.06447 10.81 6.98947 10.175L5.49447 9.29001C5.22447 9.13001 4.77947 9.13001 4.50447 9.29001L3.00947 10.175C1.93947 10.81 1.28947 10.335 1.56947 9.12501L1.92447 7.59001C1.98947 7.30001 1.87447 6.89501 1.66447 6.68501L0.424466 5.44501C-0.305534 4.71501 -0.0705336 3.97501 0.949466 3.80501L2.54447 3.54001C2.80947 3.49501 3.12947 3.26001 3.24947 3.01501L4.12947 1.25501C4.60947 0.300007 5.38947 0.300007 5.86447 1.25501Z"
        fill="#FAD80C"
      />
    </svg>
  );
}
