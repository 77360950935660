import React from "react";

const IconComment = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3307 1.66406H6.66406C3.33073 1.66406 1.66406 3.33073 1.66406 6.66406V17.4974C1.66406 17.9557 2.03906 18.3307 2.4974 18.3307H13.3307C16.6641 18.3307 18.3307 16.6641 18.3307 13.3307V6.66406C18.3307 3.33073 16.6641 1.66406 13.3307 1.66406Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83594 7.91406H14.1693"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83594 12.0781H11.6693"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconComment;
