import { useEffect, useRef, useState } from 'react';

function useDynamicSVGImport(name: string, options?: any) {
  const ImportedIconRef = useRef();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();
  const [image, setImage] = useState<any>();
  const { onCompleted, onError } = options;
  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        setImage(name);

        if (onCompleted) {
          onCompleted(name, ImportedIconRef.current);
        }
      } catch (err: any) {
        if (onError) {
          onError(err);
        }
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name, onCompleted, onError]);

  return { error, loading, SvgIcon: image };
}

export default useDynamicSVGImport;
