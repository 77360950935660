/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import Image from "next/image";
import React, { useRef, useState } from "react";
import { CloseCircle } from "../../../../public/assets/icons/CloseIcon";
import IconUploadImage from "../../../../public/assets/icons/IconUploadImage";
import { MAX_IMAGE } from "../../questionAndAnswer/utils/constants";

type TProps = {
  maxLength: number;
  onChangeText: (e) => void;
  content: string;
  placeholder?: string;
  height?: number;
  resizeNone?: boolean;
  className?: string;
  hasInputImage?: boolean;
  imagesUpload?: {
    images?:
      | {
          file: any;
          previewUrl: string;
        }[]
      | undefined;
    error: string;
  };
  onChangeImage?: (e) => void;
  onDeleteImage?: (imageUrl: string) => void;
};

export default function CXTextArena({
  maxLength,
  onChangeText,
  content,
  placeholder,
  height,
  resizeNone,
  className,
  hasInputImage,
  imagesUpload,
  onChangeImage,
  onDeleteImage
}: TProps) {
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  return (
    <div className="flex flex-col gap-1 sm:px-2 md:px-0">
      <div
        className={`border p-[10px] ${
          isTextareaFocused ? "border-green" : "border-grayPlaceholder"
        } mt-4 rounded-md overflow-hidden`}
      >
        <textarea
          className={`w-full text-[15px] outline-none ${resizeNone && "resize-none"} ${className}`}
          placeholder={placeholder}
          onChange={(e) => onChangeText(e)}
          maxLength={maxLength}
          value={content}
          style={{
            height: height || 210
          }}
          onFocus={() => setIsTextareaFocused(true)}
          onBlur={() => setIsTextareaFocused(false)}
        />

        {hasInputImage && (
          <>
            <div className="flex gap-[10px]">
              {!!imagesUpload?.images &&
                imagesUpload?.images.map((image) => (
                  <div key={image.previewUrl} className="relative w-1/3 aspect-square rounded-[6px] overflow-hidden">
                    <Image alt="" src={image?.previewUrl} layout="fill" objectFit="cover" />
                    <button
                      type="button"
                      className="absolute right-1 top-1"
                      onClick={() => onDeleteImage?.(image?.previewUrl)}
                    >
                      <CloseCircle />
                    </button>
                  </div>
                ))}
            </div>
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              ref={hiddenFileInput}
              onChange={onChangeImage}
              style={{ display: "none" }}
              multiple
            />
          </>
        )}
      </div>
      <div
        className={`flex flex-row flex-nowrap mt-1 items-center ${hasInputImage ? "justify-between" : "justify-end"}`}
      >
        {hasInputImage && (
          <button
            type="button"
            className="disabled:opacity-50 disabled:cursor-default"
            onClick={() => hiddenFileInput.current?.click()}
            disabled={(imagesUpload?.images?.length || 0) >= MAX_IMAGE}
          >
            <IconUploadImage />
          </button>
        )}
        <span className="text-gray1 text-xs">
          {content?.length}/{maxLength}
        </span>
      </div>
      {imagesUpload?.error && <span className="flex text-[12px] text-red justify-end pb-2">{imagesUpload?.error}</span>}
    </div>
  );
}
