import { Box, Typography } from "@mui/material";
import Link from "next/link";
import React from "react";
import { useIntl } from "react-intl";
import { appName } from "../../../utils/constants/constants";

const TeacherBenefits = () => {
  const intl = useIntl();

  const fixedBenefits = [
    {
      type: "normal",
      title: intl.formatMessage({ id: "TeacherBenefits.teacherAccount.normal.title" }),
      detail: [
        intl.formatMessage({ id: "TeacherBenefits.teacherAccount.normal.details.1" }),
        intl.formatMessage({ id: "TeacherBenefits.teacherAccount.normal.details.2" }),
        intl.formatMessage({ id: "TeacherBenefits.teacherAccount.normal.details.3" })
      ]
    },
    {
      type: "vip",
      title: intl.formatMessage({ id: "TeacherBenefits.teacherAccount.vip.title" }),
      detail: [
        intl.formatMessage({ id: "TeacherBenefits.teacherAccount.vip.details.1" }),
        intl.formatMessage({ id: "TeacherBenefits.teacherAccount.vip.details.2" }),
        intl.formatMessage({ id: "TeacherBenefits.teacherAccount.vip.details.3" })
      ],
      further: intl.formatMessage(
        { id: "TeacherBenefits.teacherAccount.vip.further" },
        {
          us: (
            <Link
              href={intl.formatMessage({
                id: "link.facebook"
              })}
            >
              <a
                href={intl.formatMessage({
                  id: "link.facebook"
                })}
                target="_blank"
                rel="noreferrer"
              >
                <Typography className="inline-block underline text-[12px] text-green">{appName}</Typography>
              </a>
            </Link>
          )
        }
      )
    }
  ];

  return (
    <Box className="flex flex-col gap-y-6">
      {fixedBenefits.map((teacherAccountType) => (
        <Box key={teacherAccountType.type}>
          <Typography className="font-semibold text-[14px]">{teacherAccountType.title}</Typography>
          <Box>
            {teacherAccountType.detail.map((benefit) => (
              <Typography key={benefit} className="text-[14px]">
                + {benefit}
              </Typography>
            ))}
          </Box>
          {teacherAccountType?.further && (
            <Typography className="italic text-[12px]">{teacherAccountType.further}</Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default TeacherBenefits;
