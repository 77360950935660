import React from "react";

const IconPinned = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.58374 10.4114L1.8125 14.1827M7.79553 4.42845L6.75494 5.46904C6.67005 5.55392 6.62761 5.59636 6.57926 5.63009C6.53634 5.66002 6.49004 5.68479 6.44133 5.7039C6.38645 5.72543 6.32759 5.7372 6.20988 5.76074L3.7669 6.24934C3.13204 6.37631 2.8146 6.4398 2.66609 6.60717C2.53672 6.75297 2.47764 6.9481 2.5044 7.14119C2.53513 7.36282 2.76404 7.59173 3.22185 8.04954L7.94567 12.7734C8.40348 13.2312 8.63239 13.4601 8.85402 13.4908C9.04711 13.5176 9.24224 13.4585 9.38804 13.3291C9.55541 13.1806 9.6189 12.8632 9.74587 12.2283L10.2345 9.78533C10.258 9.66762 10.2698 9.60876 10.2913 9.55388C10.3104 9.50517 10.3352 9.45887 10.3651 9.41595C10.3988 9.3676 10.4413 9.32516 10.5262 9.24027L11.5668 8.19968C11.621 8.14541 11.6482 8.11828 11.678 8.09458C11.7045 8.07354 11.7326 8.05454 11.7619 8.03775C11.795 8.01886 11.8303 8.00374 11.9008 7.97351L13.5637 7.26083C14.0489 7.05292 14.2914 6.94896 14.4016 6.78097C14.498 6.63407 14.5324 6.45507 14.4975 6.28289C14.4576 6.08599 14.271 5.89938 13.8978 5.52617L10.469 2.09741C10.0958 1.72419 9.90922 1.53758 9.71232 1.49767C9.54014 1.46276 9.36114 1.49724 9.21424 1.59359C9.04625 1.70377 8.94229 1.94634 8.73438 2.43148L8.0217 4.09438C7.99147 4.16492 7.97635 4.2002 7.95746 4.23327C7.94067 4.26265 7.92167 4.29071 7.90063 4.31721C7.87693 4.34704 7.8498 4.37418 7.79553 4.42845Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPinned;
