import React from "react";
import Image from "next/image";

export default function NextImage({ className, src, classImage = "", onClick, objectFit, ...props }: any) {
  return (
    <div
      style={{
        position: "relative"
      }}
      className={className}
    >
      <Image
        {...props}
        src={src}
        layout="fill"
        objectFit={objectFit ?? "cover"}
        className={classImage} // Scale your image down to fit into the container
        onClick={onClick}
      />
    </div>
  );
}
