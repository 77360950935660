/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/require-default-props */
import Dialog, { DialogProps } from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import * as React from "react";
import GoogleAd from "../components/GoogleAd";

const Transition = React.forwardRef((props: any, ref: any) => {
  return (
    <Slide children={props.children} direction="up" ref={ref} {...props} />
  );
});

type TProp = {
  open: boolean;
  setOpen: Function;
  triggerFunc: Function;
  maxWidth?: DialogProps["maxWidth"];
};
const GoogleAdDialog = ({ open, setOpen, triggerFunc, maxWidth }: TProp) => {
  let myTimeout: NodeJS.Timeout | number = 0;
  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    myTimeout = setTimeout(() => {
      if (open) {
        setOpen(false);
        triggerFunc();
      }
    }, 5000);
    return () => clearTimeout(myTimeout);
  }, [open]);

  return (
    <div>
      <Dialog
        maxWidth={maxWidth || "lg"}
        open={open}
        TransitionComponent={Transition}
      >
        <GoogleAd
          style={{
            display: "block",
            height: typeof window !== 'undefined' ? document.documentElement.clientHeight * 0.65 : 0,
            width: typeof window !== 'undefined' ? document.documentElement.clientWidth * 0.65 : 0,
          }}
          slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_ARTICLE}
        />
      </Dialog>
    </div>
  );
};

export default React.memo(GoogleAdDialog);
