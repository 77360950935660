/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import Image from "next/image";
import moment from "moment";
import React from "react";
import { NEUTRAL_100 } from "../../../utils/configs/theme/colors";
import { TTheme } from "../../../utils/configs/setupTheme";
import { appName } from "../../../utils/constants/constants";

function NotificationCard(props: { data: any }) {
  const { data } = props;
  const theme: TTheme = useTheme();
  return (
    <div
      // onClick={() => {
      //   window.location.href = data.direct_link;
      // }}
      style={{
        display: "-webkit-box",
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        overflow: "hidden"
      }}
      className={`pt-3 flex items-center cursor-pointer hover:bg-[${NEUTRAL_100}] mb-4`}
    >
      <div className="flex flex-col text-justify w-full">
        <div className="flex flex-row">
          <div className="shrink w-[35px] h-[35px]">
            <Image
              width={35}
              height={35}
              src="/assets/images/notification_logo.webp"
              alt={appName}
              className="object-fill"
            />
          </div>
          <span className="mb-3 ml-3 whitespace-pre-wrap w-full">{data?.message_title}</span>
        </div>
        <span>{data?.message_body}</span>
        <Box display="flex" justifyContent="space-between" color={theme.palette.primary.gray3} mt={1 / 2}>
          <Typography variant="body2" style={{}}>
            {moment.unix(data?.created_at).format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="body2" style={{}}>
            {moment.unix(data?.created_at).format("hh:mm")}
          </Typography>
        </Box>
      </div>
    </div>
  );
}

export default NotificationCard;
