import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { CloseIcon } from "../../../../public/assets/icons/CloseIcon";
import { TTheme } from "../../../utils/configs/setupTheme";
import TeacherBenefits from "../components/TeacherBenefits";
import { eventTracking } from "../../../firebase/firebaseConfig";

type TeacherBenefitsDialogProps = {
  open: boolean;
  onClose: () => void;
  onClickRegister: () => void;
};

const TeacherBenefitsDialog = ({ open, onClose, onClickRegister }: TeacherBenefitsDialogProps) => {
  const theme: TTheme = useTheme();

  const handleClickRegister = () => {
    onClose();
    onClickRegister();
    eventTracking("action_select_register");
  };

  useEffect(() => {
    eventTracking("screen_dl_teacher_benefit");
  }, []);

  return (
    <Dialog
      open={open}
      scroll="paper"
      sx={{
        "& .MuiPaper-root": {
          width: { sm: 560, xs: 350 },
          borderRadius: 4
        }
      }}
    >
      <DialogTitle className="p-4 flex justify-center">
        <Typography className="text-[19px] text-black1 font-semibold text-center max-w-[240px]">
          <FormattedMessage id="Dialog.TeacherBenefits.title" />
        </Typography>
        <IconButton className="absolute right-1 top-1" onClick={onClose}>
          <CloseIcon stroke={theme.palette.primary.gray4} />
        </IconButton>
      </DialogTitle>
      <DialogContent className="px-4 pb-6">
        <TeacherBenefits />
        <Button className="bg-green-gradient rounded-[100px] h-[44px] w-full mt-4" onClick={handleClickRegister}>
          <Typography className="normal-case text-white text-[14px]">
            <FormattedMessage id="Dialog.TeacherBenefits.register" />
          </Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default TeacherBenefitsDialog;
