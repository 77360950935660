
import React from "react";

export default function Play({ width, height, className, fill}: any) {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.42735 14.2593L13.9266 5.26073C13.973 5.18494 13.9982 5.09817 13.9999 5.00936C14.0016 4.92055 13.9795 4.8329 13.9361 4.75542C13.8926 4.67795 13.8293 4.61345 13.7527 4.56856C13.6761 4.52366 13.5888 4.5 13.5 4.5L2.50142 4.5C2.41259 4.5 2.32537 4.52366 2.24872 4.56856C2.17208 4.61345 2.10877 4.67795 2.06532 4.75542C2.02187 4.8329 1.99985 4.92055 2.00151 5.00936C2.00317 5.09817 2.02846 5.18494 2.07478 5.26073L7.57407 14.2593C7.61874 14.3324 7.68143 14.3927 7.75613 14.4346C7.83084 14.4765 7.91506 14.4985 8.00071 14.4985C8.08636 14.4985 8.17058 14.4765 8.24529 14.4346C8.31999 14.3927 8.38268 14.3324 8.42735 14.2593Z"
        fill={fill || "white"}
      />
    </svg>
  );
}
