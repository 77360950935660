import React from "react";

export default function XCircleBlack() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="5" fill="black" />
      <path
        d="M7.9974 13.3327C10.9429 13.3327 13.3307 10.9449 13.3307 7.99935C13.3307 5.05383 10.9429 2.66602 7.9974 2.66602C5.05188 2.66602 2.66406 5.05383 2.66406 7.99935C2.66406 10.9449 5.05188 13.3327 7.9974 13.3327Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 6L6 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 10L6 6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export const XCircleBlack2 = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" fill="#475467" />
      <path
        d="M9.17188 14.8319L14.8319 9.17188"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8319 14.8319L9.17188 9.17188"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
