/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/styles/useTheme";
import { setCookie } from "cookies-next";
import { getAuth, signOut } from "firebase/auth";
import isEqual from "lodash/isEqual";
import moment from "moment";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector, useStore } from "react-redux";
import ArrowDown from "../../../../../public/assets/icons/ArrowDown";
import IconAnonymous from "../../../../../public/assets/icons/IconAnonymous";
import IconNotification from "../../../../../public/assets/icons/IconNotification";
import LargeLogo from "../../../../../public/assets/icons/LargeLogo";
import Play from "../../../../../public/assets/icons/Play";
import SettingsIcon from "../../../../../public/assets/icons/SettingsIcon";
import SignOut from "../../../../../public/assets/icons/SignOut";
import TeacherAccountIcon from "../../../../../public/assets/icons/TeacherAccountIcon";
import ThreeLayerIcon from "../../../../../public/assets/icons/ThreeLayerIcon";
import TransHistory from "../../../../../public/assets/icons/TransHistory";
import UserCircleGear from "../../../../../public/assets/icons/UserCircleGear";
import UserCircleMenu from "../../../../../public/assets/icons/UserCircleMenu";
import { eventTracking } from "../../../../firebase/firebaseConfig";
import { PUBLIC_LAYOUT, ROUTES } from "../../../../utils/configs/router";
import { TTheme } from "../../../../utils/configs/setupTheme";
import { WHITE } from "../../../../utils/configs/theme/colors";
import { LIST_BLOCK_URL, LIST_NON_BLOCK_URL } from "../../../../utils/constants/constants";
import { genSignature, getNonce, isEmpty } from "../../../../utils/helpers/helpers";
import SetupDialog from "../../../account/components/SetupDialog";
import { accountActions } from "../../../account/redux/actions";
import { TAccountState } from "../../../account/utils/types";
import { arenaRoomActions } from "../../../arena/redux/actions";
import { signOutApi } from "../../../auth/utils/authAPI";
import NotificationDropdown from "../../../common/Notification/NotificationDropDown";
import CXNotify from "../../../common/components/CXNotify";
import { ColorButtonOutlinedGrade } from "../../../common/components/Elements";
import AlertDialog from "../../../common/dialog/AlertDialog";
import ClassesDialog from "../../../common/dialog/ClassesDialog";
import { commonActions } from "../../../common/redux/actions";
import { LIST_PATH_NAME_NON_BLOCK, getQueryFilterListPost } from "../../../common/utils/constant";
import { TCommonState } from "../../../common/utils/type";
import { friendActions } from "../../../friends/redux/actions";
import { IQuestionAndAnswer } from "../../../questionAndAnswer/utils/types";
import MenuSuggest from "../components/MenuSuggest";
import { LIST_CLASSES, LIST_MENU, SPECIAL_CLASSES } from "../constants";

// declare const window: any;
const HOME_PAGE = "/";

const MenuInfo = ({ anchor, id, transformOrigin, open, onClose, className, children }) => {
  return (
    <Menu
      anchorEl={anchor}
      id={id}
      keepMounted
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      className={className}
      style={{
        top: "-3px",
        left: "18px"
      }}
    >
      {children}
    </Menu>
  );
};

const MenuItemInfo = ({ onClick, children }) => {
  return (
    <MenuItem onClick={onClick} className="hover:bg-green/10 duration-200">
      {children}
    </MenuItem>
  );
};

function DefaultHeader({ dataUserInfos, userClass }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const theme: TTheme = useTheme();
  const store = useStore();
  const isNotLogin = isEmpty(dataUserInfos?.member);
  const matches1285 = useMediaQuery("(min-width:1285px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [openClassesDialog, setOpenClassesDialog] = React.useState(false);
  const [isFocus, setFocus] = React.useState(false);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [nextUrl, setNextUrl] = React.useState("");
  const [openSetupDialog, setOpenSetupDialog] = React.useState(false);

  const { accessToken, action, notify, directUrl, dataFireBaseInfos, teacherInfo } = useSelector(
    (state: { commonNew: TCommonState; account: TAccountState; questionAndAnswer: IQuestionAndAnswer }) => ({
      accessToken: state.commonNew.accessToken,
      action: state.commonNew.action,
      notify: state.commonNew.notify,
      directUrl: state.commonNew.directUrl,
      dataFireBaseInfos: state.commonNew.fireBaseInfos,
      teacherInfo: state.account.teacherInfo
    })
  );

  const queryFilterListPost = getQueryFilterListPost(store);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event: any) => {
    event.preventDefault();
    if (isEmpty(dataUserInfos?.member)) {
      dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
      eventTracking("select_home_login");
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const logout = () => {
    // dispatch(commonActions.handleSaveAccessToken(token));
    dispatch(friendActions.handleCleanListFriend());
    dispatch(commonActions.handleLogOut());
  };

  const actionsSignOut = async () => {
    const paramsSignatureSignOut = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      uid: dataUserInfos?.member?.pub_id
    };
    const params = {
      ...paramsSignatureSignOut,
      signature: genSignature(paramsSignatureSignOut)
    };
    const auth = getAuth();
    try {
      const response = await signOutApi(store, params);
      if (response?.data) {
        signOut(auth)
          .then(() => {
            logout();
            setCookie("accessToken", JSON.stringify(response?.data?.data?.token?.access_token));
            setCookie("userInfo", JSON.stringify(response?.data?.data));
            dispatch(commonActions.handleOpenModalLogin({ isOpen: false }));
            dispatch(commonActions.addUserInfos(response?.data?.data));
            handleMenuClose();
            router.push("/");
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            dispatch(commonActions.handleOpenModalLogin({ isOpen: false }));
            handleMenuClose();
            logout();
            router.push("/");
            // An error happened.
          });
      }
    } catch (err) {
      dispatch({
        type: "CLEAR_TOKEN"
      });
    }
    dispatch(accountActions.handleCleanAccountState());
  };

  const menuId = "primary-search-account-menu";

  const checkPageActive = (page: any) => {
    if (page.key === "common.bottombar.arena") {
      return router?.pathname.includes("/thi-online");
    } else if (page.key === "Question_and_Answer") {
      return router?.pathname?.includes("/hoi-dap");
    } else if (page.key === "common.bottombar.course") {
      return router?.pathname?.includes("/khoa-hoc");
    }
    return router?.pathname === page.link;
  };
  const isDontShowLoginWithPage =
    LIST_PATH_NAME_NON_BLOCK.includes(router.pathname) ||
    router?.pathname.includes("/hoi-dap") ||
    router?.pathname.includes("/thi-online");

  React.useEffect(() => {
    const isHideLoginDialog = PUBLIC_LAYOUT.find((element: { path: string }) =>
      router?.asPath?.includes(element?.path)
    );
    if (isEmpty(accessToken) && action?.type !== "LOGIN_SUCCESS" && !isHideLoginDialog && !isDontShowLoginWithPage) {
      dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
    }
  }, [router, accessToken]);

  useEffect(() => {
    if (action?.type === "LOGIN_SUCCESS") {
      if (nextUrl) {
        router.push(nextUrl);
      } else if (dataUserInfos?.member && router?.pathname !== "/" && !LIST_NON_BLOCK_URL.includes(router?.pathname)) {
        router.replace(router.asPath);
      } else if (router?.pathname === ROUTES.subjects.path && isEmpty(directUrl)) {
        router?.push("/");
      }
    }
  }, [action, dataUserInfos]);

  useEffect(() => {
    if (!isEmpty(dataFireBaseInfos)) {
      dispatch(commonActions.handleOpenModalLogin({ isOpen: false }));
      if (!!nextUrl && nextUrl !== HOME_PAGE) {
        router.push(nextUrl);
      }
    }
  }, [dataFireBaseInfos]);
  React.useEffect(() => {
    if (notify?.type === "LOGOUT_SUCCESS") {
      dispatch(commonActions.handleOpenModalLogin({ isOpen: false }));
    }
  }, [notify?.type]);
  return (
    <AppBar position="fixed" style={{ boxShadow: "unset" }} className="bg-white">
      <Box
        style={{ height: 56, boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)" }}
        alignItems="center"
        className="md:flex md:px-6 lg:pl-[32px] lg:pr-[40px] md:justify-between bg-white lg:justify-between w-full md:gap-[30px] xl:gap-4 2xl:gap-[52px]"
      >
        <Box className="flex items-center ml-[1px]">
          <Button onClick={() => router.push("/")} id="header_logo">
            <LargeLogo />
          </Button>
          <Box className="" id="header_search">
            <MenuSuggest />
          </Box>
        </Box>
        <Box width="100%" alignItems="center" className="md:flex justify-between items-center shrink ml-[-10px]">
          {LIST_MENU.map((page, index) => (
            <button
              type="button"
              key={index}
              id={page.id}
              onClick={() => {
                if (isNotLogin && LIST_BLOCK_URL.some((v) => page.link.includes(v))) {
                  if (page.key === "common.bottombar.arena") {
                    setNextUrl(`/${userClass}/thi-online?tab=phong-thi&category=waiting-room`);
                  } else {
                    dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
                    setNextUrl(page.link);
                  }
                } else if (index !== 4) {
                  if (page.key === "common.bottombar.arena") {
                    router.push(`/${userClass}/thi-online?tab=phong-thi&category=waiting-room`);
                  } else if (page.key === "Question_and_Answer" && queryFilterListPost?.is_filter !== 0) {
                    router.push({
                      pathname: "/hoi-dap",
                      query: { ...queryFilterListPost, tab: "thao-luan", category: "all" }
                    });
                  } else {
                    router.push(page.link);
                  }
                } else {
                  router.push(page.link);
                }
                dispatch(arenaRoomActions.handleClearPage());

                if (page.key === "common.bottombar.diamond") {
                  eventTracking("action_select_icon_dia_top_header");
                }
              }}
              style={{
                color: theme.palette.primary.gray400,
                fontWeight: "400"
              }}
              className={`text-[15px] my-2 px-4 py-1 rounded-md hover:bg-green/10 duration-200 xl:min-w-[142px] flex items-center justify-center ${
                checkPageActive(page) && "text-green font-[600]"
              }`}
            >
              {/* eslint-disable-next-line  */}
              {matches1285 ? (
                <>
                  <span style={{ marginRight: "5px" }}>{checkPageActive(page) ? page?.iconActive : page?.icon}</span>
                  <FormattedMessage id={page?.key} />
                </>
              ) : checkPageActive(page) ? (
                page?.iconActive
              ) : (
                page?.icon
              )}
            </button>
          ))}
        </Box>
        <Box className="flex flex-row items-center">
          <ColorButtonOutlinedGrade
            disableFocusRipple
            disableElevation
            variant="contained"
            onClick={() => {
              setOpenClassesDialog(true);
              // check de hide dialog survey
              dispatch(commonActions.handleHideDialogSurvey(true));
            }}
            className="h-[32px] w-[80px] font-[700] px-[6px]"
            id="header_grade"
          >
            <span className="text-[13px] lg:w-[100%] uppercase" id="header_grade_item">
              {LIST_CLASSES.concat(SPECIAL_CLASSES).find((ele) => ele.slug === userClass)?.name}
            </span>
            <ArrowDown width={16} height={16} />
          </ColorButtonOutlinedGrade>
          <div className="flex items-center">
            <Box
              style={{
                color: WHITE,
                width: 20,
                marginLeft: "20px"
                // marginRight: "8px"
              }}
              onClick={() => {
                if (!isEmpty(dataUserInfos?.member)) {
                  setFocus(!isFocus);
                } else {
                  dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
                }
              }}
              mx={1}
              id="header_notification"
            >
              <Badge
                // badgeContent={<Typography variant="caption" style={{ color: WHITE }}></Typography>}
                color="secondary"
              >
                {/* <NotificationsIcon /> */}
                <IconNotification className="cursor-pointer" />
                {isFocus && <NotificationDropdown setFocus={setFocus} isFocus={isFocus} />}
              </Badge>
            </Box>

            <IconButton
              disableFocusRipple
              disableRipple
              size="large"
              edge="end"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              style={{ color: "white" }}
              className="xl:hidden md:block"
            >
              <Avatar alt={dataUserInfos?.member?.name} src={dataUserInfos?.member?.avatar} />
            </IconButton>

            {matches1285 && (
              <IconButton
                disableFocusRipple
                disableRipple
                size="large"
                edge="end"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                style={{ color: "white" }}
                id={dataUserInfos?.member ? "header_user" : "header_anonymous"}
              >
                {/* <Avatar
                  alt={dataUserInfos.member?.name}
                  src={dataUserInfos.member?.avatar}
                /> */}

                {dataUserInfos?.member ? (
                  <Avatar
                    alt={dataUserInfos?.member?.name}
                    src={dataUserInfos?.member?.avatar}
                    id="header_user_avata"
                  />
                ) : (
                  <IconAnonymous width="40" height="40" id="header_user_avata_anonymous" />
                )}
                <span
                  style={{
                    color: theme.palette.primary.black1,
                    fontSize: "13px"
                  }}
                  className={`${
                    dataUserInfos?.member ? "truncate max-w-[130px] text-left mr-[11px] pl-2" : "pl-2 w-[75px]"
                  }`}
                  id={
                    dataUserInfos?.member?.display_name || dataUserInfos?.member?.name
                      ? "header_user_name"
                      : "header_login"
                  }
                >
                  {dataUserInfos?.member?.display_name
                    ? dataUserInfos?.member?.display_name
                    : dataUserInfos?.member?.name
                    ? dataUserInfos?.member?.name
                    : "Đăng nhập"}
                </span>
                {!isEmpty(dataUserInfos?.member?.display_name) && <Play fill={theme.palette.primary.gray400} />}
              </IconButton>
            )}
          </div>
        </Box>
      </Box>
      {isMenuOpen && (
        <MenuInfo
          anchor={anchorEl}
          id={menuId}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          className="text-black w-full"
        >
          <Link href={ROUTES.taikhoan.edit.path}>
            <MenuItemInfo
              onClick={() => {
                router.push(`${ROUTES.taikhoan.edit.path}`);
              }}
            >
              <Box display="flex" alignItems="center" className="text-black">
                <UserCircleMenu />
                <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                  <FormattedMessage id="User_info" />
                </Typography>
              </Box>
            </MenuItemInfo>
          </Link>
          <MenuItemInfo
            onClick={() => {
              router.push(`${ROUTES.taikhoan.path}`);
            }}
          >
            <Box display="flex" alignItems="center" className="text-black">
              <UserCircleGear />
              <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                <FormattedMessage id="Account_management" />
              </Typography>
            </Box>
          </MenuItemInfo>
          <MenuItemInfo
            onClick={() => {
              eventTracking("action_click_button_teacher");
              if (teacherInfo) dispatch(commonActions.setIsOpenTeacherAccountInfoDialog(true));
              else
                dispatch(
                  accountActions.handleGetTeacherInfo(
                    () => dispatch(commonActions.setIsOpenTeacherAccountInfoDialog(true)),
                    () => dispatch(commonActions.setIsOpenTeacherBenefitsDialog(true))
                  )
                );
              handleMenuClose();
            }}
          >
            <Box display="flex" alignItems="center" className="text-black">
              <TeacherAccountIcon />
              <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                <FormattedMessage id="TeacherAccount" />
              </Typography>
            </Box>
          </MenuItemInfo>
          <MenuItemInfo
            onClick={() => {
              router.push(`${ROUTES.taikhoan.diamondhistory.path}`);
            }}
          >
            <Box display="flex" alignItems="center" className="text-black">
              <TransHistory />
              <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                <FormattedMessage id="Trans_history" />
              </Typography>
            </Box>
          </MenuItemInfo>
          <MenuItemInfo
            onClick={() => {
              router.push(`${ROUTES.taikhoan.myGroups.path}`);
            }}
          >
            <Box display="flex" alignItems="center" className="text-black">
              <ThreeLayerIcon />
              <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                <FormattedMessage id="My_classes" />
              </Typography>
            </Box>
          </MenuItemInfo>
          <MenuItemInfo
            onClick={() => {
              setOpenSetupDialog(true);
              handleMenuClose();
            }}
          >
            <Box display="flex" alignItems="center" className="text-black">
              <SettingsIcon />
              <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                <FormattedMessage id="Setting.title" />
              </Typography>
            </Box>
          </MenuItemInfo>
          {!isNotLogin && (
            <MenuItemInfo
              onClick={() => {
                setLogoutDialog(true);
              }}
            >
              <Box display="flex" alignItems="center" className="text-black">
                <SignOut />
                <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                  <FormattedMessage id="Profile.logout" />
                </Typography>
              </Box>
            </MenuItemInfo>
          )}
        </MenuInfo>
      )}
      {openClassesDialog && (
        <ClassesDialog
          open={openClassesDialog}
          onClose={() => {
            setOpenClassesDialog(false);
            dispatch(commonActions.handleHideDialogSurvey(false));
          }}
        />
      )}
      {/* {modalLogin.isOpen && (
        <LoginDialog
          open={modalLogin.isOpen}
          required={modalLogin.isRequired}
          onClose={() => dispatch(commonActions.handleOpenModalLogin({isOpen: false}))}
          onSuccess={() => dispatch(commonActions.handleOpenModalLogin({isOpen: false}))}
        />
      )} */}
      {logoutDialog && (
        <AlertDialog
          open={logoutDialog}
          setOpen={setLogoutDialog}
          title="Xác nhận"
          contentKey="Setting.logoutDesc"
          triggerFunc={() => {
            actionsSignOut();
          }}
          isDisabledHandleClose={undefined}
          buttonOkKey={undefined}
        />
      )}
      {notify?.isOpenNotify && (
        <CXNotify
          open={notify?.isOpenNotify}
          onClose={() => dispatch(commonActions.deleteNotify())}
          message={notify?.message || ""}
        />
      )}
      {openSetupDialog && (
        <SetupDialog
          open={openSetupDialog}
          setOpen={setOpenSetupDialog}
          setOpenAlertDialog={() => dispatch(accountActions.handleClearNotify())}
        />
      )}
    </AppBar>
  );
}
const checkEqualProps = (prevProps, nextProps) => {
  return (
    isEqual(prevProps?.dataUserInfos, nextProps?.dataUserInfos) && isEqual(prevProps?.userClass, nextProps?.userClass)
  );
};
export default React.memo(DefaultHeader, checkEqualProps);
