/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-danger */
import React from "react";
import ModalImage from "react-modal-image";
import { useRouter } from "next/router";
import lodash from "lodash";
import { ASK_POST_CONTENT_TYPE_HTML, ASK_POST_CONTENT_TYPE_IMAGE, handleSubtractTimeCreated } from "../utils/constants";
import { TPackages, TPost } from "../utils/types";
import PostItemHeader from "./PostItemHeader.";
import PostItemFooter from "./PostItemFooter";
import ReadMore from "../../common/components/ReadMore";
import { RoomPreviewCard, RoomPreviewCardSmall } from "../../common/components/RoomPreviewCard";
import { eventTracking } from "../../../firebase/firebaseConfig";
import NextImage from "../../common/Image/NextImage";

type TProps = {
  postItem: TPost;
  packages: TPackages[];
  className?: string;
  handleVote: (postId: number, type: string) => void;
  handleSavePost: (postId: number) => void;
  page: string;
  isPinned?: boolean;
};
const PostItem = (props: TProps) => {
  const { postItem, packages, className, handleVote, handleSavePost, page, isPinned = false } = props;
  const router = useRouter();
  const isHomePage = page === "homepage";
  const checkMultipleContentType =
    postItem?.embedded_reference || lodash.uniq(postItem?.post_content?.map((e) => e?.content_type))?.length > 1;

  return (
    <div className={`flex flex-col ${className} ${!isHomePage ? "sm:w-screen md:w-full" : "w-full"}`}>
      <PostItemHeader
        postItem={postItem}
        time={handleSubtractTimeCreated}
        packages={packages}
        page={page}
        isHomePage={isHomePage}
      />

      <div className="md:px-3 sm:px-2 w-full flex-col cursor-pointer sm:mb-2 sm2:mb-0">
        {!isHomePage && (
          <span
            className="font-[500] text-[13px] line-clamp-2 break-words sm2:pt-3 sm2:pb-2 sm:pt-2"
            onClick={() =>
              eventTracking(isPinned ? "select_ask_list_pin" : "select_ask_list_item", {}, () =>
                router.push({
                  pathname: `/hoi-dap/${postItem?.post_id}`
                  // query: { postId: postItem?.post_id }
                })
              )
            }
          >
            {postItem?.post_title}
          </span>
        )}
        {postItem?.embedded_reference ? (
          <div>
            {isHomePage ? (
              <div className="pt-2">
                <div className="text-[15px] mb-2 line-clamp-2">
                  {postItem?.post_content?.length > 0 &&
                    postItem?.post_content?.map((item) => (
                      <div key={item?.post_content_id}>
                        {item?.content_type === "html" && <ReadMore>{item?.content}</ReadMore>}
                      </div>
                    ))}
                </div>
                <RoomPreviewCardSmall
                  roomData={{
                    ...postItem.embedded_reference.refreshed_json.room,
                    subject_title: postItem.embedded_reference.refreshed_json?.subject?.title
                  }}
                  href={postItem.embedded_reference.refreshed_json.room?.direct_link_slug}
                  isHomePage={isHomePage}
                />
              </div>
            ) : (
              <RoomPreviewCard
                room={{
                  ...postItem?.embedded_reference?.refreshed_json?.room,
                  subject_type: postItem?.embedded_reference?.refreshed_json?.subject
                }}
                roomsData={{
                  room: postItem?.embedded_reference?.refreshed_json?.room,
                  subjects: [postItem?.embedded_reference?.refreshed_json.subject]
                }}
                contentText={postItem?.post_content?.find((e) => e.content_type === ASK_POST_CONTENT_TYPE_HTML)}
                contentImages={postItem?.post_content?.filter((e) => e.content_type === ASK_POST_CONTENT_TYPE_IMAGE)}
              />
            )}
          </div>
        ) : (
          <div
            className={`flex flex-col font-normal gap-y-2 ${
              isHomePage ? "mt-2 text-[15px]" : "text-[13px] text-gray400"
            }`}
          >
            {postItem?.post_content?.length > 0 &&
              postItem?.post_content?.map((item) => (
                <div
                  key={item?.post_content_id}
                  className={`${
                    isHomePage && (checkMultipleContentType ? "line-clamp-2" : "line-clamp-6")
                  } pointer-events-none`}
                  onClick={() =>
                    eventTracking(
                      isPinned ? "select_ask_list_pin" : "select_ask_list_item",
                      {},
                      () =>
                        !isHomePage &&
                        router.push({
                          pathname: `/hoi-dap/${postItem?.post_id}`
                          // query: { postId: postItem?.post_id }
                        })
                    )
                  }
                >
                  {item?.content_type === "html" && <ReadMore>{item?.content}</ReadMore>}
                </div>
              ))}
            <div
              className={`flex flex-row gap-4 ${isHomePage ? "items-start" : "items-center justify-center"} flex-wrap`}
            >
              {postItem?.post_content?.length > 0 &&
                postItem?.post_content?.map(
                  (item, index) =>
                    item?.content_type === "image" &&
                    (!isHomePage ? (
                      <ModalImage
                        key={index}
                        small={item?.content}
                        large={item?.content}
                        alt="thitot.vn"
                        hideDownload={false}
                        hideZoom
                        className="lg:w-[20vw] md:w-[35vw] sm2:w-[40vw] sm:w-[60vw] max-h-[400px] rounded-md aspect-[3/2] object-scale-down"
                      />
                    ) : (
                      <NextImage
                        key={index}
                        src={item?.content}
                        className="h-[100px] object-contain w-[40%] mt-[-10px]"
                        classImage="rounded-[16px]"
                      />
                    ))
                )}
            </div>
          </div>
        )}
      </div>
      {page !== "homepage" && (
        <div className="sm:w-screen md:w-full pt-1">
          <PostItemFooter postItem={postItem} handleVote={handleVote} handleSavePost={handleSavePost} />
        </div>
      )}
    </div>
  );
};
export default PostItem;
