import React from "react";

export const IconSaved = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0156 1.59375H5.98229C4.21562 1.59375 2.76562 3.04375 2.76562 4.81042V16.5521C2.76562 18.0521 3.84063 18.6854 5.15729 17.9604L9.22396 15.7021C9.65729 15.4604 10.3573 15.4604 10.7823 15.7021L14.849 17.9604C16.1656 18.6937 17.2406 18.0604 17.2406 16.5521V4.81042C17.2323 3.04375 15.7906 1.59375 14.0156 1.59375ZM13.0156 7.52708L9.68229 10.8604C9.55729 10.9854 9.39896 11.0437 9.24063 11.0437C9.08229 11.0437 8.92396 10.9854 8.79896 10.8604L7.54896 9.61042C7.30729 9.36875 7.30729 8.96875 7.54896 8.72708C7.79063 8.48542 8.19063 8.48542 8.43229 8.72708L9.24063 9.53542L12.1323 6.64375C12.374 6.40208 12.774 6.40208 13.0156 6.64375C13.2573 6.88542 13.2573 7.28542 13.0156 7.52708Z"
        fill="url(#paint0_linear_4439_90182)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4439_90182"
          x1="10.0031"
          y1="1.59375"
          x2="10.0031"
          y2="18.2658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9EF01A" />
          <stop offset="1" stopColor="#007200" />
        </linearGradient>
      </defs>
    </svg>
  );
};
const IconSave = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0807 8.875H7.91406"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.84375V11.0104"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0156 1.66406H5.98229C4.20729 1.66406 2.76562 3.11406 2.76562 4.88073V16.6224C2.76562 18.1224 3.84063 18.7557 5.15729 18.0307L9.22396 15.7724C9.65729 15.5307 10.3573 15.5307 10.7823 15.7724L14.849 18.0307C16.1656 18.7641 17.2406 18.1307 17.2406 16.6224V4.88073C17.2323 3.11406 15.7906 1.66406 14.0156 1.66406Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconSave;
