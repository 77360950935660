/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-empty-interface */
import Box from "@mui/material/Box";
import React from "react";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import isEqual from "lodash/isEqual";
import { isEmpty, LIST_BLOCK_URL } from "../../../../utils/constants/constants";
import ClassesDialog from "../../../common/dialog/ClassesDialog";
import DirectAppResponsive from "../../../common/components/DirectAppResponsive";
import DrawerLeftSide from "./DrawerLeftSide";
import { LIST_CLASSES, LIST_MENU, SPECIAL_CLASSES } from "../constants";
import { commonActions } from "../../../common/redux/actions";
import IconAnonymous from "../../../../../public/assets/icons/IconAnonymous";
import NotificationDropdown from "../../../common/Notification/NotificationDropDown";
import IconNotification from "../../../../../public/assets/icons/IconNotification";
import { ColorButtonOutlinedGrade } from "../../../common/components/Elements";
import ArrowDown from "../../../../../public/assets/icons/ArrowDown";
import Breadcrumb from "../../../common/components/BreadCrumb";
import { IconSearchSmall } from "../../../../../public/assets/icons/IconSearch";
import { LIST_PATH_NAME_NON_BLOCK } from "../../../common/utils/constant";
import { PUBLIC_LAYOUT } from "../../../../utils/configs/router";
import { eventTracking } from "../../../../firebase/firebaseConfig";

interface IResponsiveHeaderProps {
  // isNewMember: boolean;
  isHomePage: boolean;
  userInfo: any;
  handleOpenModalImage: () => void;
}

const ResponsiveHeader: React.FunctionComponent<IResponsiveHeaderProps> = (props) => {
  const { userInfo, handleOpenModalImage, isHomePage } = props;
  const [openClassesDialog, setOpenClassesDialog] = React.useState(false);
  const { idToken, action } = useSelector((state: { commonNew: any }) => state.commonNew);
  const [isFocus, setFocus] = React.useState(false);
  const userClass = useSelector((state: { commonNew: any }) => state.commonNew.grade);
  const router = useRouter();
  const scrollTrigger = useScrollTrigger({ threshold: 20 });
  const isNotLogin = isEmpty(userInfo?.member);
  const dispatch = useDispatch();
  const checkPageActive = (page: any) => {
    if (page.key === "common.bottombar.arena") {
      return router.asPath.includes("/thi-online");
    } else if (page.key === "Question_and_Answer") {
      return router?.pathname?.includes("/hoi-dap");
    } else if (page.key === "common.bottombar.course") {
      return router?.pathname?.includes("/khoa-hoc");
    }
    return router.asPath === page.link;
  };
  const isDontShowLoginWithPage =
    LIST_PATH_NAME_NON_BLOCK.includes(router.pathname) ||
    router?.pathname.includes("/hoi-dap") ||
    router?.pathname.includes("/thi-online");
  React.useEffect(() => {
    const isHideLoginDialog = PUBLIC_LAYOUT.find((element: { path: string }) =>
      router?.asPath?.includes(element?.path)
    );
    if (isEmpty(idToken) && action?.type !== "LOGIN_SUCCESS" && !isHideLoginDialog && !isDontShowLoginWithPage) {
      dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
    }
  }, [router, idToken]);
  return (
    <>
      <AppBar position="fixed" style={{ boxShadow: "unset" }} className="bg-white">
        {!scrollTrigger && (
          <div className="sm:flex sm:flex-row w-screen text-black bg-grayBackground">
            <DirectAppResponsive />
          </div>
        )}
        <div className="flex justify-between gap-4 bg-white py-2 items-center">
          <div className="flex">
            <button type="button" className="sm:mx-2 relative">
              {userInfo.member ? (
                <DrawerLeftSide
                  member={userInfo.member}
                  // isNewMember={isNewMember}
                  handleOpenModalImage={handleOpenModalImage}
                />
              ) : (
                <button
                  type="button"
                  onClick={() => dispatch(commonActions.handleOpenModalLogin({ isOpen: true }))}
                  className="sm:mt-2"
                >
                  <IconAnonymous width="32" height="32" />
                </button>
              )}
            </button>
            <button
              type="button"
              className="flex items-center justify-start gap-2 ml-2"
              onClick={() => router.push("/")}
            >
              <span className="text-green text-[18px] font-bold uppercase">
                <FormattedMessage id="Title.appName" />
              </span>
            </button>
          </div>
          <div className="flex items-center gap-x-2">
            <Box className="flex items-center justify-start">
              <ColorButtonOutlinedGrade
                disableFocusRipple
                disableElevation
                variant="contained"
                onClick={() => {
                  setOpenClassesDialog(true);
                }}
                className="font-[700] gap-x-2 flex h-8"
              >
                <span className="text-[13px] lg:w-[100%] uppercase">
                  {LIST_CLASSES.concat(SPECIAL_CLASSES).find((ele) => ele.slug === userClass)?.shortName}
                </span>
                <ArrowDown width={16} height={16} />
              </ColorButtonOutlinedGrade>
            </Box>
            {router?.pathname !== "/tim-kiem" && (
              <button
                onClick={() => router.push("/tim-kiem")}
                type="button"
                className="rounded-full  bg-gray p-2"
                disabled={router?.pathname === "/tim-kiem"}
              >
                <IconSearchSmall />
              </button>
            )}
            <Box className="flex items-center mr-[2px]">
              <Box
                className="text-black bg-gray rounded-full py-1 px-1.5"
                onClick={() => {
                  if (!isEmpty(userInfo?.member)) {
                    setFocus(!isFocus);
                  } else {
                    dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
                    eventTracking("select_home_login");
                  }
                }}
                mx={1}
              >
                <Badge
                  // badgeContent={<Typography variant="caption" style={{ color: WHITE }}></Typography>}
                  color="secondary"
                >
                  {/* <NotificationsIcon /> */}
                  <IconNotification />
                  {isFocus && <NotificationDropdown setFocus={setFocus} isFocus={isFocus} />}
                </Badge>
              </Box>
            </Box>
          </div>
        </div>
        <div
          className="px-1"
          style={{
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
            minHeight: "32px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <div className="flex flex-row flex-nowrap w-full items-center sm:justify-between md:justify-center bg-white  px-1 sm:px-[0]">
            {LIST_MENU.map((page, index) => (
              <button
                type="button"
                key={index}
                onClick={() => {
                  if (isNotLogin && LIST_BLOCK_URL.some((v) => page.link.includes(v))) {
                  } else if (index !== 4) {
                    if (page.key === "common.bottombar.arena") {
                      router.push(`/${userClass}/thi-online?tab=phong-thi`);
                    } else {
                      router.push(page.link);
                    }
                  } else {
                    // #gift
                    router.push(page.link);
                  }
                }}
                className={`${checkPageActive(page) ? "text-yellow text-center" : "text-center"} w-8 text-gray ${
                  router.asPath.includes("/thi-online") ? "py-0" : "py-1"
                }`}
              >
                {/* eslint-disable-next-line  */}
                <span className="mx-auto sm:flex sm:justify-center w-10">
                  {checkPageActive(page) ? page?.iconMobileActive : page?.iconMobile}
                </span>
              </button>
            ))}
            <button
              type="button"
              onClick={() =>
                userInfo.member
                  ? router.push(`/tai-khoan`)
                  : dispatch(commonActions.handleOpenModalLogin({ isOpen: true }))
              }
            >
              {router.asPath.includes("/tai-khoan") ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                    fill="#38B000"
                  />
                  <path
                    d="M12.0002 14.5C6.99016 14.5 2.91016 17.86 2.91016 22C2.91016 22.28 3.13016 22.5 3.41016 22.5H20.5902C20.8702 22.5 21.0902 22.28 21.0902 22C21.0902 17.86 17.0102 14.5 12.0002 14.5Z"
                    fill="#38B000"
                  />
                </svg>
              ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                    stroke="#666666"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22"
                    stroke="#666666"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        {!isHomePage && (
          <div className="truncate w-screen px-2 py-1">
            <Breadcrumb />
          </div>
        )}
      </AppBar>
      {openClassesDialog && <ClassesDialog open={openClassesDialog} onClose={() => setOpenClassesDialog(false)} />}
    </>
  );
};
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.userInfo, nextProps?.userInfo);
};
export default React.memo(ResponsiveHeader, checkEqualProps);
