/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import React from "react";
import { FormattedMessage } from "react-intl";
import { useRouter } from "next/router";
import { useSelector, useStore } from "react-redux";
import ActiveLink from "./ActiveLink";
import useBreadcrumb from "../../common/hooks/useBreadcrumb";
import { LIST_DISABLED_BREADCRUMB, getQueryFilterListPost } from "../utils/constant";
import { TCommonState } from "../utils/type";

interface Props {
  children: any;
}

const BreadcrumbItem: React.FC<Props> = ({ children, ...props }) => {
  return (
    <span className="text-sm h-[20px]" {...props}>
      {children}
    </span>
  );
};

const BreadcrumbSeparator: React.FC<Props> = ({ children, ...props }) => {
  return (
    <span className="mt-0.5 text-sm" {...props}>
      {children}
    </span>
  );
};

export const BreadcrumbItems = (props: any) => {
  let children: any = React.Children.toArray(props.children);

  children = children.map((child: string, index: number) => <BreadcrumbItem key={index}>{child}</BreadcrumbItem>);

  const lastIndex = children.length - 1;

  children = children.reduce((acc: any, child: string, index: number) => {
    const notLast = index < lastIndex;
    if (notLast) {
      acc.push(child, <BreadcrumbSeparator key={`breadcrumb_sep${index}`}>{props.separator}</BreadcrumbSeparator>);
    } else {
      acc.push(child);
    }
    return acc;
  }, []);

  return (
    <div className="appBreadcrumb flex items-center text-center w-full overflow-hidden text-gray1 gap-1 ">
      {children}
    </div>
  );
};

const Breadcrumb: React.FC<{ separator?: string }> = ({ separator = "/" }) => {
  const breadcrumbs = useBreadcrumb();
  const router = useRouter();
  const store = useStore();
  const { grade } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const queryFilterListPost = getQueryFilterListPost(store);

  const pathNameListPost = queryFilterListPost?.all
    ? "/hoi-dap?tab=thao-luan&all=true&is_filter=1"
    : `/hoi-dap?tab=thao-luan&gr=${queryFilterListPost?.gr}&category=all&fty=${queryFilterListPost?.fty}&fti=${queryFilterListPost?.fti}&gst=${queryFilterListPost?.gst}&is_filter=1`;

  return (
    <BreadcrumbItems separator={separator}>
      {router?.pathname !== "/" && (
        <ActiveLink href="/" activeClassName="font-semibold hover:underline" className="text-gray1">
          <a>
            <FormattedMessage id="breadcrumb-home" />
          </a>
        </ActiveLink>
      )}
      {breadcrumbs?.length > 0 &&
        breadcrumbs?.map((breadcrumb: any, index) => {
          return (
            <ActiveLink
              key={breadcrumb.href}
              disabled={LIST_DISABLED_BREADCRUMB.includes(breadcrumb.breadcrumb)}
              className="disabled:cursor-not-allowed hover:underline text-[#9E9E9E]"
              // className=""
              href={
                LIST_DISABLED_BREADCRUMB.includes(breadcrumb.breadcrumb)
                  ? breadcrumbs[index - 1].href
                  : breadcrumb?.href.includes("/thi-online")
                  ? `/${grade}/thi-online?tab=phong-thi`
                  : breadcrumb?.href.includes("/hoi-dap")
                  ? queryFilterListPost.is_filter !== 0
                    ? pathNameListPost
                    : "/hoi-dap?tab=thao-luan"
                  : breadcrumb.href
              }
              activeClassName=""
            >
              <a type="button" className="max-w-[200px] overflow-hidden text-ellipsis">
                <FormattedMessage id={breadcrumb.breadcrumb || "breadcrumb-home"} />
              </a>
            </ActiveLink>
          );
        })}
    </BreadcrumbItems>
  );
};

export default Breadcrumb;
