/* eslint-disable consistent-return */
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "../../../../public/assets/icons/CloseIcon";
import DoubleRightIcon from "../../../../public/assets/icons/DoubleRightIcon";
import { TTheme } from "../../../utils/configs/setupTheme";
import { isToday } from "../../../utils/helpers";
import DailyInfoBlock from "../components/DailyInfoBlock";
import GoogleAd from "../components/GoogleAd";
import LearningInfoBlock from "../components/LearningInfoBlock";
import PostsInfoBlock from "../components/PostsInfoBlock";
import RoomsInfoBlock from "../components/RoomsInfoBlock";
import SubjectsInfoBlock from "../components/SubjectsInfoBlock";
import { commonActions } from "../redux/actions";
import { GET_DAILY_REPORT_DATE_LOCAL_STORAGE_KEY, TIMESTAMP_OF_DAY } from "../utils/constant";
import { TCommonState } from "../utils/type";

const DailyReportDialog = () => {
  const dispatch = useDispatch();
  const theme: TTheme = useTheme();
  const intl = useIntl();
  const router = useRouter();

  const { grade, dailyReport } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);

  const lessonsList = useMemo(() => {
    return dailyReport?.lessons?.items?.filter((item) => item?.grade_slug === grade) || [];
  }, [dailyReport?.lessons, grade]);

  const examsList = useMemo(() => {
    return dailyReport?.exams?.items?.filter((item) => item?.grade_slug === grade) || [];
  }, [dailyReport?.exams, grade]);

  const practicesList = useMemo(() => {
    return dailyReport?.practices?.items?.filter((item) => item?.grade_slug === grade) || [];
  }, [dailyReport?.practices, grade]);

  const isShowAd =
    !dailyReport?.data_report?.statistic_rooms?.rooms.length &&
    !dailyReport?.data_report?.statistic_post?.length &&
    !(lessonsList.length || examsList.length);

  useEffect(() => {
    const getDailyReportDate = localStorage.getItem(GET_DAILY_REPORT_DATE_LOCAL_STORAGE_KEY);
    if (!getDailyReportDate || !isToday(new Date(getDailyReportDate))) {
      const getDailyReportTimeoutId = setTimeout(() => {
        dispatch(
          commonActions.getDailyReport(
            {
              grade_slug: grade,
              date: Math.round((Date.now() - TIMESTAMP_OF_DAY) / 1000)
            },
            () => {
              localStorage.setItem(GET_DAILY_REPORT_DATE_LOCAL_STORAGE_KEY, new Date().toString());
            }
          )
        );
      }, 3000);

      return () => clearTimeout(getDailyReportTimeoutId);
    }
  }, []);

  useEffect(() => {
    const routeChangeStart = () => {
      dispatch(commonActions.closeDailyReportDialog());
    };
    router.events.on("routeChangeStart", routeChangeStart);

    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
    };
  }, []);

  return (
    <Dialog
      open={!dailyReport?.is_displayed && !!dailyReport?.data_report?.title_report}
      scroll="paper"
      sx={{
        "& .MuiPaper-root": {
          width: { sm: 560, xs: 350 },
          borderRadius: 4
        }
      }}
    >
      <DialogTitle className="p-5">
        <Typography className="text-[18px] text-black2 font-medium text-center line-clamp-1">
          {dailyReport?.data_report?.title_report}
        </Typography>
        <IconButton className="absolute right-1 top-1" onClick={() => dispatch(commonActions.closeDailyReportDialog())}>
          <CloseIcon stroke={theme.palette.primary.gray4} />
        </IconButton>
      </DialogTitle>
      <DialogContent className="flex flex-col gap-6 sm:px-4 md:px-5">
        <Box className="border border-gray rounded-2xl p-4">
          <DailyInfoBlock
            title={intl.formatMessage({ id: "Account.statistic.account" })}
            expPoint={dailyReport?.data_report?.statistic_account?.exp_point_yesterday}
            diamondHistory={dailyReport?.data_report?.statistic_account?.diamond_history}
            starHistory={dailyReport?.data_report?.statistic_account?.star_history}
          />
        </Box>
        <Box className="border border-gray rounded-2xl p-4">
          <LearningInfoBlock statisticLearning={dailyReport?.data_report?.statistic_learning} />
        </Box>
        {!!dailyReport?.data_report?.statistic_rooms?.rooms.length && (
          <Box className="border border-gray rounded-2xl p-4 pt-0">
            <RoomsInfoBlock
              rooms={dailyReport?.data_report?.statistic_rooms?.rooms}
              subjects={dailyReport?.data_report?.statistic_rooms?.subjects}
            />
          </Box>
        )}
        {!!dailyReport?.data_report?.statistic_post?.length && (
          <Box className="border border-gray rounded-2xl p-4 pt-0">
            <PostsInfoBlock
              posts={dailyReport?.data_report?.statistic_post}
              subjects={dailyReport?.data_report?.subjects}
            />
          </Box>
        )}
        {!!(lessonsList.length || examsList.length) && (
          <Box className="border border-gray rounded-2xl p-4 pt-0">
            <SubjectsInfoBlock lessonsList={lessonsList} examsList={examsList} practicesList={practicesList} />
          </Box>
        )}

        {isShowAd && (
          <GoogleAd
            style={{
              display: "block",
              height: 236,
              width: 508
            }}
            slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_ARTICLE}
            adTypeTracking="ads_native_ads_view"
          />
        )}

        <Box className="flex justify-end">
          <Link href="/thong-ke">
            <a href="/thong-ke" className="flex">
              <Typography className="normal-case text-green text-[13px] font-medium mr-2">
                <FormattedMessage id="Dialog.DailyReport.ViewStatisticDetail" />
              </Typography>
              <DoubleRightIcon />
            </a>
          </Link>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DailyReportDialog;
