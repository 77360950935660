import React from "react";
import { makeStyles } from "@mui/styles";

const styles = makeStyles(() => ({
  placeholder: {
    color: "#8e8e8e",
    fontSize: 14
  }
}));

const Placeholder = ({ children }: any) => {
  const classes = styles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default Placeholder;
